/* eslint-disable no-param-reassign */
import axios from 'axios';
import _, { isEqual } from 'lodash';
import { push } from 'connected-react-router';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import RoutingService from '../services/maps/RoutingService';
import ShortestRouteService from '../services/maps/ShortestRouteService';
import { pickupWindow, getShoppingPickupWindow } from '../libraries/constants';
import toNearestFifteen from '../services/dateTimeFormat/toNearestFifteen';
import { gogetterSelection } from '../services/analytics/trackingHelpers';
import { massJobMapper } from '../services/job/massJobMapper';
import { getJobFee, getJobFeeForHelper } from '../api/createJob';
import { getMinMaxTime } from '../services/maps/getMinMaxTime';
import { DEFAULT_GOGETTER_REQUIREMENTS } from '../reducers/constants/createJob';
import { GOGETTER_SELECTION_ANYONE } from '../services/job/constants';

const getReducer = (reducerType, getState) => {
  if (reducerType === 'DISPATCH') {
    return getState().createJob;
  } else if (reducerType === 'FOOD_SHOPPING') {
    return getState().createFoodAndShopping;
  } else if (reducerType === 'PART_TIMER') {
    return getState().createPartTimer;
  } else if (reducerType === 'EDITJOB') {
    return getState().editJob;
  } else if (reducerType === 'PERSONAL_HELPER') {
    return getState().createPersonalHelper;
  } else if (reducerType === 'CHEQUE_DEPOSIT') {
    return getState().createChequeDeposit;
  }
};

const pushCurrentPage = (reducerType, dispatch, getState) => {
  if (reducerType === 'DISPATCH') {
    dispatch(push('/dispatch'));
  } else if (reducerType === 'FOOD_SHOPPING') {
    dispatch(push('/food_shopping'));
  } else if (reducerType === 'EDITJOB') {
    dispatch(push(`/jobs/${getState().editJob.job.id}/edit`));
  } else if (reducerType === 'PART_TIMER') {
    dispatch(push('/part_timer/gig'));
  }
};

export const setGogetterPreference = (reducerType, preference) => ({
  type: `${reducerType}_SET_GOGETTER_POOL`,
  preference
});

export const setStartState = (reducerType, startState) => ({
  type: `${reducerType}_SET_START_STATE`,
  start_state: startState
});

const onDoneEditTask = reducerType => ({
  type: `${reducerType}_DONE_EDIT_TASK`
});

export const doneEditTask = reducerType => {
  return (dispatch, getState) => {
    const isEditJobTypeDispatch = !!(
      reducerType === 'EDITJOB' &&
      getState().jobs &&
      getState().jobs.items &&
      getState().jobs.items[getState().editJob.job.id] &&
      getState().jobs.items[getState().editJob.job.id].dispatch_item
    );

    if (reducerType === 'DISPATCH' || isEditJobTypeDispatch) {
      const state = getReducer(reducerType, getState);
      if (
        !isEqual(state.originalTaskToEdit, state.job.tasks[state.modifyTask]) &&
        !!state.original_route &&
        !!state.original_tasks
      ) {
        dispatch(removeOriginalTaskAndRoute(reducerType));
      }

      dispatch(onDoneEditTask(reducerType));
    }

    // TODO: fung to clean it up when we are doing single page
    if (reducerType !== 'FOOD_SHOPPING') {
      pushCurrentPage(reducerType, dispatch, getState);
    }
  };
};

const onStartEditTask = (reducerType, taskOrder, modifyPage) => ({
  type: `${reducerType}_START_EDIT_TASK`,
  taskOrder,
  modifyPage
});

export const startEditTask = (reducerType, taskOrder, modifyPage) => {
  return (dispatch, getState) => {
    dispatch(onStartEditTask(reducerType, taskOrder, modifyPage));
    pushCurrentPage(reducerType, dispatch, getState);
  };
};

const onLocationDetails = reducerType => ({
  type: `${reducerType}_LOCATION_DETAILS`
});

export const locationDetails = reducerType => {
  return (dispatch, getState) => {
    dispatch(onLocationDetails(reducerType));
    pushCurrentPage(reducerType, dispatch, getState);
  };
};

export const locationSearch = reducerType => ({
  type: `${reducerType}_LOCATION_SEARCH`
});

export const editTask = (reducerType, order, updates) => ({
  type: `${reducerType}_EDIT_TASK`,
  order,
  updates
});

export const editLanguageProficiency = (reducerType, updates) => ({
  type: `${reducerType}_EDIT_LANGUAGE_PROFICIENCY`,
  updates
});

export const editAllLocations = (reducerType, updates) => ({
  type: `${reducerType}_EDIT_ALL_LOCATIONS`,
  updates
});

export const editImage = (reducerType, id, updates) => ({
  type: `${reducerType}_EDIT_IMAGE`,
  id,
  updates
});

const runAddDropoff = reducerType => ({
  type: `${reducerType}_ADD_DROPOFF`
});

const runAddTime = reducerType => ({
  type: `${reducerType}_ADD_TIME`
});

export const editBreakTime = (reducerType, updates) => ({
  type: `${reducerType}_EDIT_BREAK_TIME`,
  updates
});

export const editBriefingTime = (reducerType, updates) => ({
  type: `${reducerType}_EDIT_BRIEFING_TIME`,
  updates
});

export const selectBreakTime = (reducerType, isSelected) => ({
  type: `${reducerType}_SELECT_BREAK_TIME`,
  isSelected
});

export const selectBriefingTime = (reducerType, isSelected) => ({
  type: `${reducerType}_SELECT_BRIEFING_TIME`,
  isSelected
});

export const addTime = reducerType => {
  return (dispatch, getState) => {
    dispatch(runAddTime(reducerType));
    dispatch(
      editAllLocations(
        reducerType,
        getReducer(reducerType, getState).job.tasks[0]
      )
    );
    dispatch(getFee(reducerType));
  };
};

const removeTaskStart = (reducerType, order) => ({
  type: `${reducerType}_REMOVE_TASK`,
  order
});

const resetTaskOrder = reducerType => ({
  type: `${reducerType}_RESET_TASK_ORDER_COUNT`
});

const onChangePage = (reducerType, page) => ({
  type: `${reducerType}_CHANGE_PAGE`,
  page
});

export const changePage = (reducerType, page) => {
  return (dispatch, getState) => {
    dispatch(onChangePage(reducerType, page));
    pushCurrentPage(reducerType, dispatch, getState);
  };
};

export const onEditItem = (reducerType, item) => ({
  type: `${reducerType}_EDIT_ITEM`,
  item
});

export const editItem = (reducerType, item) => {
  return async (dispatch, getState) => {
    await dispatch(onEditItem(reducerType, item));

    if (reducerType === 'EDITJOB') {
      await dispatch(getFee(reducerType));
    }

    if (
      reducerType === 'FOOD_SHOPPING' &&
      getReducer(reducerType, getState).job &&
      getReducer(reducerType, getState).job.item
    ) {
      const startAt =
        getReducer(reducerType, getState).job &&
        getReducer(reducerType, getState).job.tasks &&
        getReducer(reducerType, getState).job.tasks[0] &&
        getReducer(reducerType, getState).job.tasks[0].start_at;
      const updates = {
        start_at: startAt
      };
      await dispatch(updateTaskAndRoute(reducerType, 0, updates));
    }
  };
};

const onEditVehicle = (reducerType, rideId) => ({
  type: `${reducerType}_EDIT_VEHICLE`,
  ride_id: rideId
});

export const editVehicle = (reducerType, rideId) => {
  return async (dispatch, getState) => {
    await dispatch(onEditVehicle(reducerType, rideId));
    if (rideId === 3) {
      dispatch(updateEggValidation(reducerType, { noSUV: false }));
    } else {
      dispatch(updateEggValidation(reducerType, { noSUV: true }));
    }
    await routeJob(reducerType, dispatch, getState);
  };
};

const onEditPayment = (
  reducerType,
  paymentMethod,
  isUserSelectedPaymentMethod
) => ({
  type: `${reducerType}_EDIT_PAYMENT`,
  payment_method: paymentMethod,
  isUserSelectedPaymentMethod
});

export const editPayment = (
  reducerType,
  paymentMethod,
  isUserSelectedPaymentMethod
) => {
  let userSelectedPaymentMethod = false;
  if (isUserSelectedPaymentMethod || reducerType === 'EDITJOB') {
    userSelectedPaymentMethod = true;
  }
  return (dispatch, getState) => {
    dispatch(
      onEditPayment(reducerType, paymentMethod, userSelectedPaymentMethod)
    );

    const isEditJobTypeDispatch = !!(
      reducerType === 'EDITJOB' &&
      getState().jobs &&
      getState().jobs.items &&
      getState().jobs.items[getState().editJob.job.id] &&
      getState().jobs.items[getState().editJob.job.id].dispatch_item
    );

    if (reducerType === 'dispatch' || isEditJobTypeDispatch) {
      if (paymentMethod === 'cash') {
        dispatch(updateEggValidation(reducerType, { noCash: false }));
      } else {
        dispatch(updateEggValidation(reducerType, { noCash: true }));
      }
    }
  };
};

const joinTemporaryTask = reducerType => {
  return (dispatch, getState) => {
    dispatch({
      type: `${reducerType}_JOIN_TEMPORARY_TASK`,
      update: getReducer(reducerType, getState).job.temporary_task
    });
  };
};

const addTemporaryTask = (reducerType, receiptReturnMethod) => {
  return (dispatch, getState) => {
    let taskType;
    let name;
    let parking;
    let startAt;
    let endAt;

    if (receiptReturnMethod === 'return_the_receipt') {
      taskType = 'return_receipt';
      name = 'Return the physical receipt(s)';
      parking = true;
      startAt = moment(
        getReducer(reducerType, getState).job.tasks[1].start_at
      ).add(15, 'minute');
      endAt = moment(getReducer(reducerType, getState).job.tasks[1].end_at).add(
        15,
        'minute'
      );
    } else {
      taskType = 'send_photo';
      name = 'Send a photo of receipt via GoGet app';
      parking = false;
      startAt = moment(getReducer(reducerType, getState).job.tasks[1].start_at);
      endAt = moment(getReducer(reducerType, getState).job.tasks[1].end_at);
    }

    const firstTask = getReducer(reducerType, getState).job.tasks[0];
    const temporaryTask = {
      name,
      location: firstTask.location,
      location_lat: firstTask.location_lat,
      location_long: firstTask.location_long,
      start_at: startAt,
      end_at: endAt,
      order: getReducer(reducerType, getState).job.tasks.length,
      task_type: taskType,
      parking
    };

    dispatch({
      type: `${reducerType}_ADD_TEMPORARY_TASK`,
      temporaryTask
    });
  };
};

export const editReceiptReturnMethod = (reducerType, receiptReturnMethod) => {
  return dispatch => {
    dispatch(addTemporaryTask(reducerType, receiptReturnMethod));
    dispatch({
      type: `${reducerType}_EDIT_RECEIPT_RETURN_METHOD`,
      receipt_return_method: receiptReturnMethod
    });
  };
};

export const editNotes = (reducerType, notes) => ({
  type: `${reducerType}_EDIT_NOTES`,
  notes
});

export const editPerks = (reducerType, perks) => ({
  type: `${reducerType}_EDIT_PERKS`,
  perks
});

// Armin: this is used when users change helper type from the drop down list
export const editHelperType = (reducerType, helperType) => {
  return (dispatch, getState) => {
    dispatch({
      type: `${reducerType}_EDIT_HELPER_TYPE`,
      helper_type: helperType
    });

    const isStartDateAvailableSchedule =
      getReducer(reducerType, getState)?.job?.schedules &&
      getReducer(reducerType, getState)?.job?.schedules[0]?.start_at;
    const isStartDateAvailableNewSchudle =
      getReducer(reducerType, getState)?.job?.schedulesNew &&
      getReducer(reducerType, getState)?.job?.schedulesNew[0]?.tasks &&
      getReducer(reducerType, getState)?.job?.schedulesNew[0].tasks[0]
        ?.start_at;

    if (isStartDateAvailableSchedule && isStartDateAvailableNewSchudle) {
      setTimeout(() => {
        // when users update helper type, reset the feeBreakdown
        dispatch(setFeeBreakdown(reducerType, helperType, { helper: {} }));
        dispatch(getHelperFeeBreakdown(reducerType));
      }, 500);
    }
  };
};

export const editGogetterRequirements = (
  reducerType,
  gogetterRequirements
) => ({
  type: `${reducerType}_EDIT_GOGETTER_REQUIREMENTS`,
  gogetter_requirements: gogetterRequirements
});

const setFeeBreakdown = (reducerType, serviceType, feeBreakdown) => ({
  type: `${reducerType}_SET_FEE_BREAKDOWN`,
  serviceType,
  feeBreakdown
});

export const setServiceType = (reducerType, serviceType) => ({
  type: `${reducerType}_SET_SERVICE_TYPE`,
  service_type: serviceType
});

const setFinalFee = (reducerType, fee) => ({
  type: `${reducerType}_FINAL_FEE`,
  fee
});

const setFeeCalc = (reducerType, feeCalc) => ({
  type: `${reducerType}_FEE_CALC`,
  feeCalc
});

export const updateEggValidation = (reducerType, eggValidation) => ({
  type: `${reducerType}_SET_EGG_VALIDATION`,
  eggValidation
});

export const setFormErrors = (reducerType, formErrors) => ({
  type: `${reducerType}_SET_FORM_ERRORS`,
  formErrors
});

export const setFormLoading = (reducerType, isLoading) => ({
  type: `${reducerType}_SET_FORM_LOADING`,
  isLoading
});

export const updateRoute = (reducerType, route) => ({
  type: `${reducerType}_UPDATE_ROUTE`,
  route
});

export const updateOriginalTaskAndRoute = (reducerType, tasks, route) => ({
  type: `${reducerType}_UPDATE_ORIGINAL_TASK_AND_ROUTE`,
  tasks,
  route
});

const removeOriginalTaskAndRoute = reducerType => ({
  type: `${reducerType}_REMOVE_ORIGINAL_TASK_AND_ROUTE`
});

export const setGogetterSelection = ({
  reducerType,
  blastPool,
  gogetterTrainingModuleId,
  selectedGogetterId
}) => ({
  type: `${reducerType}_SET_GOGETTER_SELECTION`,
  blast_pool: blastPool,
  gogetter_training_module_id: gogetterTrainingModuleId,
  selected_gogetter_id: selectedGogetterId
});

const onSetAutoApprove = (reducerType, autoApprove) => ({
  type: `${reducerType}_SET_AUTO_APPROVE`,
  auto_approve: autoApprove
});

export const setAutoApprove = (reducerType, autoApprove) => {
  return dispatch => {
    dispatch(onSetAutoApprove(reducerType, autoApprove));
    if (autoApprove) {
      dispatch(updateEggValidation(reducerType, { noApproval: true }));
    } else {
      dispatch(updateEggValidation(reducerType, { noApproval: false }));
    }
  };
};

export const checkRain = reducerType => {
  return dispatch => {
    axios
      .get(`${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs/raining`)
      .then(response => {
        dispatch(
          updateEggValidation(reducerType, {
            isNotRaining: !response.data.data.raining
          })
        );
      })
      .catch(() => {});
  };
};

export const setRouting = (reducerType, routing) => ({
  type: `${reducerType}_SET_ROUTING`,
  routing
});

const setShortestRouting = (reducerType, routing) => ({
  type: `${reducerType}_SET_SHORTEST_ROUTING`,
  routing
});

const startJobCreation = reducerType => ({
  type: `${reducerType}_START_JOB_CREATE`
});

const endJobCreation = reducerType => ({
  type: `${reducerType}_END_JOB_CREATE`
});

const startFetchFee = reducerType => ({
  type: `${reducerType}_START_FETCH_FEE`
});

const endFetchFee = reducerType => ({
  type: `${reducerType}_END_FETCH_FEE`
});

export const resetState = reducerType => ({
  type: `${reducerType}_RESET_STATE`
});

const adjustFee = (reducerType, fee) => ({
  type: `${reducerType}_ADJUST_FEE`,
  fee
});

const updateDistance = (reducerType, distanceCoveredM) => ({
  type: `${reducerType}_UPDATE_DISTANCE`,
  distance_covered_m: distanceCoveredM
});

export const setFirstEntry = reducerType => ({
  type: `${reducerType}_SET_FIRST_ENTRY`
});

export const dismissFirstEntry = reducerType => ({
  type: `${reducerType}_DISMISS_FIRST_ENTRY`
});

export const enterSummary = reducerType => ({
  type: `${reducerType}_ENTER_SUMMARY`
});

export const addBank = (reducerType, name, id) => ({
  type: `${reducerType}_ADD_BANK`,
  name,
  bank_id: id
});

export const updateBank = (reducerType, updates) => ({
  type: `${reducerType}_UPDATE_BANK`,
  updates
});

export const setDeliveryType = (reducerType, deliveryType) => ({
  type: `${reducerType}_SET_DELIVERY_TYPE`,
  deliveryType
});

export const addDropoff = reducerType => {
  return dispatch => {
    dispatch(runAddDropoff(reducerType));
    dispatch(updateEggDropoffValidation(reducerType));
  };
};

export const updateEggDropoffValidation = reducerType => {
  return (dispatch, getState) => {
    if (getReducer(reducerType, getState).job.tasks.length < 4) {
      dispatch(updateEggValidation(reducerType, { withinStopLimit: true }));
    } else {
      dispatch(updateEggValidation(reducerType, { withinStopLimit: false }));
    }
  };
};

export const updateFee = (reducerType, fee) => {
  return dispatch => {
    if (!Number.isInteger(fee) || fee < 0) {
      fee = 0;
    }
    dispatch(adjustFee(reducerType, fee));
  };
};

export const refreshTime = reducerType => {
  return (dispatch, getState) => {
    const pickUpTask = getReducer(reducerType, getState).job.tasks[0];
    const { page, modifyTaskPage, deliveryType } = getReducer(
      reducerType,
      getState
    );
    if (!(page === 'locations' && modifyTaskPage === 'details')) {
      if (!!pickUpTask.start_at && moment(pickUpTask.start_at) < moment()) {
        dispatch(
          updateTaskAndRoute(reducerType, 0, {
            start_at:
              deliveryType !== 'immediate' || pickUpTask.is_scheduled_delivery
                ? toNearestFifteen(
                    moment().add(pickupWindow.intervalStart, 'minute')
                  )
                : moment().add(pickupWindow.intervalStart, 'minute')
          })
        );
      } else if (
        !!pickUpTask.end_at &&
        moment(pickUpTask.end_at) <
          moment().add(pickupWindow.timeRange, 'minute')
      ) {
        dispatch(
          updateTaskAndRoute(reducerType, 0, {
            start_at:
              deliveryType !== 'immediate' || pickUpTask.is_scheduled_delivery
                ? toNearestFifteen(moment(pickUpTask.start_at))
                : moment(pickUpTask.start_at)
          })
        );
      }
    }
  };
};

export const updateTaskAndRoute = (reducerType, order, updates) => {
  return async (dispatch, getState) => {
    if (
      reducerType === 'PERSONAL_HELPER' ||
      reducerType === 'PART_TIMER' ||
      getState().editJob.service_type === 'helper'
    ) {
      if (updates.location) {
        await dispatch(editAllLocations(reducerType, updates));
      }
      if (updates.start_at) {
        await dispatch(
          editJoinedStartTime(reducerType, order, updates.start_at)
        );
      }
      if (updates.end_at) {
        await dispatch(editJoinedEndTime(reducerType, order, updates.end_at));
      }
    } else {
      if (order === 0 && !!updates && !!updates.start_at) {
        let endAtRange;
        if (
          reducerType === 'FOOD_SHOPPING' &&
          getReducer(reducerType, getState).job &&
          getReducer(reducerType, getState).job.item
        ) {
          endAtRange = getShoppingPickupWindow(
            getReducer(reducerType, getState).job.item
          );
        } else {
          endAtRange = pickupWindow.intervalEnd;
        }

        const latestStartTime = moment().add(endAtRange, 'minute');
        const startAt = moment(updates.start_at);

        if (startAt < latestStartTime) {
          updates.end_at = latestStartTime;
        } else {
          updates.end_at = undefined;
        }
      }
      await dispatch(editTask(reducerType, order, updates));
      await routeJob(reducerType, dispatch, getState);
    }
  };
};

const calculateTimeRange = ({
  reducerType,
  locations,
  route,
  serviceType,
  categoryType
}) => {
  return dispatch => {
    let lastStart = locations[0].start_at;
    let lastEnd = locations[0].end_at || locations[0].start_at;
    let orderCounter = 1;

    _.map(route?.legs, leg => {
      let startTravelTime;
      let endTravelTime;

      const { minTime, maxTime } = getMinMaxTime(leg?.summary?.time);

      startTravelTime = minTime;
      endTravelTime = maxTime;

      if (!!serviceType && serviceType === 'shopping') {
        startTravelTime += 5 * 60;
        endTravelTime += 15 * 60;
      }

      lastStart = moment(lastStart)
        .add(Math.ceil(startTravelTime / 60), 'minutes')
        .add(5, 'minutes');
      lastEnd = moment(lastEnd)
        .add(Math.ceil((endTravelTime * 1.2) / 60), 'minutes')
        .add(15, 'minutes');

      if (!!serviceType && !!categoryType && serviceType === 'shopping') {
        if (categoryType === 'store_delivery') {
          dispatch(
            editTask(reducerType, orderCounter, {
              end_at: moment(locations[1].start_at).add(
                Math.ceil((endTravelTime * 1.2) / 60),
                'minutes'
              )
            })
          );
        } else if (categoryType === 'store_drivethru') {
          dispatch(
            editTask(reducerType, orderCounter, {
              end_at: undefined
            })
          );
        }
      } else if (
        !locations[orderCounter].is_scheduled_delivery ||
        locations[orderCounter].start_at < lastStart
      ) {
        dispatch(
          editTask(reducerType, orderCounter, {
            start_at: lastStart,
            end_at: lastEnd
          })
        );
      } else if (locations[orderCounter].is_scheduled_delivery) {
        if (locations[orderCounter].start_at < lastEnd) {
          dispatch(
            editTask(reducerType, orderCounter, {
              end_at: lastEnd
            })
          );
        } else {
          dispatch(
            editTask(reducerType, orderCounter, {
              end_at: undefined
            })
          );
        }
      }
      orderCounter += 1;
    });

    if (['egg', 'dispatch', 'dispatch_flexi'].includes(serviceType)) {
      dispatch(setNonFlexiTasks(reducerType));
    }

    if (
      !!serviceType &&
      serviceType === 'dispatch_flexi' &&
      moment(locations[0].start_at) >=
        moment(locations[0].start_at).startOf('day').add(8, 'hours') &&
      moment(locations[0].start_at) <=
        moment(locations[0].start_at).startOf('day').add(13, 'hours')
    ) {
      _.map(locations, location => {
        if (location.order === 0) {
          dispatch(
            editTask(reducerType, location.order, {
              end_at: moment(lastEnd).startOf('day').add(15, 'hours')
            })
          );
        } else {
          dispatch(
            editTask(reducerType, location.order, {
              end_at: moment(lastEnd).startOf('day').add(17, 'hours')
            })
          );
        }
      });
    }
  };
};

export const updateTaskAndShortestRoute = reducerType => {
  return (dispatch, getState) => {
    getShortestRoute(reducerType, dispatch, getState);
    window.analytics.track('shortest_route', {
      category: 'job_creation',
      label: 'dispatch',
      platform: 'pwa'
    });
  };
};

const getShortestRoute = (reducerType, dispatch, getState) => {
  const { job, route } = getReducer(reducerType, getState);
  const { tasks, ride_id } = job;

  const shortestRouteService = new ShortestRouteService({
    reducerType,
    dispatch,
    locations: tasks,
    updateOriginalTaskAndRoute,
    updateTaskAndRoute,
    setShortestRouting,
    updateDistance,
    getState,
    editTask,
    getFee,
    rideId: ride_id,
    updateRoute,
    job,
    route,
    calculateTimeRange,
    setNonFlexiTasks
  });
  shortestRouteService.getRoute();
};

export const resetOriginalTaskAndRoute = reducerType => {
  return (dispatch, getState) => {
    getOriginalTaskAndRoute(reducerType, dispatch, getState);
    dispatch(setNonFlexiTasks(reducerType));

    enqueueSnackbar('Successfully reset original route.', {
      variant: 'success'
    });

    window.analytics.track('reset_route', {
      category: 'job_creation',
      label: 'dispatch',
      platform: 'pwa'
    });
  };
};

const getOriginalTaskAndRoute = (reducerType, dispatch, getState) => {
  const { original_route, original_tasks } = getReducer(reducerType, getState);
  dispatch(updateRoute(reducerType, original_route));

  let index = 0;
  _.map(original_tasks, task => {
    dispatch(
      updateTaskAndRoute(reducerType, index, {
        location: task.location,
        location_lat: task.location_lat,
        location_long: task.location_long,
        parking: task.parking,
        edo: task.edo,
        reference: task.reference,
        recipient_name: task.recipient_name,
        recipient_phone_num: task.recipient_phone_num,
        sender_name: task.sender_name,
        sender_email: task.sender_email,
        location_notes: task.location_notes
      })
    );
    index += 1;
    return true;
  });

  dispatch(updateOriginalTaskAndRoute(reducerType, null, null));
};

const routeJob = (reducerType, dispatch, getState) => {
  const { tasks, ride_id, service_type, category_type } = getReducer(
    reducerType,
    getState
  ).job;
  dispatch(setRouting(reducerType, true));
  checkEggValidation({ reducerType, tasks, dispatch, getState });
  if (_.takeWhile(tasks, task => !!task.location_lat).length > 1) {
    const routingService = new RoutingService({
      reducerType,
      dispatch,
      locations: tasks,
      editTask,
      updateRoute,
      setRouting,
      updateDistance,
      rideId: ride_id,
      serviceType: service_type,
      getFee,
      calculateTimeRange,
      categoryType: category_type
    });
    routingService.route();
  } else {
    if (tasks[0].let_gogetter_decide === 'letGoGetterDecide') {
      let lastStart = tasks[0].start_at;
      let lastEnd = tasks[0].end_at || tasks[0].start_at;
      _.map(tasks, task => {
        if (task.order !== 0) {
          let endAtRange;
          if (
            reducerType === 'FOOD_SHOPPING' &&
            getReducer(reducerType, getState).job &&
            getReducer(reducerType, getState).job.item
          ) {
            endAtRange =
              getShoppingPickupWindow(
                getReducer(reducerType, getState).job.item
              ) - pickupWindow.intervalStart;
          } else {
            // calculations based on ops timing sheet
            endAtRange = 54;
          }
          lastStart = moment(lastStart).add(30, 'minutes');
          lastEnd = moment(lastStart).add(endAtRange, 'minutes');

          if (task.is_scheduled_delivery) {
            dispatch(
              editTask(reducerType, task.order, {
                end_at: undefined
              })
            );
          } else {
            dispatch(
              editTask(reducerType, task.order, {
                start_at: lastStart,
                end_at: lastEnd
              })
            );
          }
        }
      });
    }
    dispatch(setRouting(reducerType, false));
  }
};

export const checkEggRideAvailability = reducerType => {
  return dispatch => {
    axios
      .get(
        `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/jobs/check_rides_availability_egg_jobs`
      )
      .then(response => {
        dispatch(
          updateEggValidation(reducerType, {
            eggRideAvailability: response.data.data.egg_rides_blocked
          })
        );
      })
      .catch(() => {});
  };
};

export const updatePaymentMethod = reducerType => {
  return (dispatch, getState) => {
    if (reducerType === 'DISPATCH') {
      const { payment_method } = getReducer(reducerType, getState).job;
      if (payment_method === 'cash') {
        dispatch(updateEggValidation(reducerType, { noCash: false }));
      } else {
        dispatch(updateEggValidation(reducerType, { noCash: true }));
      }
    }
  };
};

const checkEggValidation = ({ reducerType, tasks, dispatch, getState }) => {
  const { ride_id, payment_method } = getReducer(reducerType, getState).job;

  // check location coverage
  if (tasks[0].location_lat) {
    const coverageUrl = `${
      import.meta.env.VITE_SERVER_PATH
    }/private/v1/jobs/egg_coverage_check`;
    const coverageData = {
      locations: tasks
    };
    const coverageRequest = axios({
      method: 'POST',
      url: coverageUrl,
      data: coverageData
    });
    coverageRequest
      .then(({ data }) => {
        dispatch(
          updateEggValidation(reducerType, { coverage: data.data.covered })
        );
      })
      .catch(() => {});
  }

  // check time range
  const lastHour = [1, 8, 2].includes(ride_id) ? 21 : 17;

  if (
    moment(tasks[0].start_at) >=
      moment(tasks[0].start_at).startOf('day').add(8, 'hours') &&
    moment(tasks[0].start_at) <=
      moment(tasks[0].start_at).startOf('day').add(lastHour, 'hours')
  ) {
    dispatch(updateEggValidation(reducerType, { timeRange: true }));
  } else {
    dispatch(updateEggValidation(reducerType, { timeRange: false }));
  }

  // check within stop limit
  dispatch(updateEggDropoffValidation(reducerType));

  // check noCash
  if (payment_method === 'cash') {
    dispatch(updateEggValidation(reducerType, { noCash: false }));
  } else {
    dispatch(updateEggValidation(reducerType, { noCash: true }));
  }

  // check noSUV
  if (ride_id === 3) {
    dispatch(updateEggValidation(reducerType, { noSUV: false }));
  } else {
    dispatch(updateEggValidation(reducerType, { noSUV: true }));
  }

  // check isNotRaining
  dispatch(checkRain(reducerType));

  // check EggRideAvailability
  dispatch(checkEggRideAvailability(reducerType));
};

export const removeTask = (reducerType, order) => {
  return async (dispatch, getState) => {
    const isEditJobTypeDispatch = !!(
      reducerType === 'EDITJOB' &&
      getState().jobs &&
      getState().jobs.items &&
      getState().jobs.items[getState().editJob.job.id] &&
      getState().jobs.items[getState().editJob.job.id].dispatch_item
    );
    await dispatch(removeTaskStart(reducerType, order));
    // TODO: If sequence does not matter, refactor code
    if (reducerType === 'DISPATCH' || isEditJobTypeDispatch) {
      await dispatch(updateEggDropoffValidation(reducerType));
      await dispatch(resetTaskOrder(reducerType));
      await routeJob(reducerType, dispatch, getState);
      await dispatch(removeOriginalTaskAndRoute(reducerType));
    } else if (
      getReducer(reducerType, getState).job.service_type === 'helper'
    ) {
      await dispatch(getFee(reducerType));
      await dispatch(resetTaskOrder(reducerType));
    } else {
      await dispatch(resetTaskOrder(reducerType));
    }
  };
};

export const getFee = reducerType => {
  return async (dispatch, getState) => {
    dispatch(startFetchFee(reducerType));
    const requestData = {
      job: getReducer(reducerType, getState).job
    };

    const response = await getJobFee(requestData);

    if (response?.isSuccess) {
      const { data } = response;
      _.forEach(data?.data, (feeBreakdown, serviceType) => {
        const breakdown = feeBreakdown;
        if (
          !(
            getReducer(reducerType, getState).feeBreakdown[serviceType] &&
            getReducer(reducerType, getState).feeBreakdown[serviceType].final
          ) ||
          getReducer(reducerType, getState).feeBreakdown[serviceType].total !==
            breakdown.total
        ) {
          breakdown.final = breakdown.total;
        } else {
          breakdown.final = getReducer(reducerType, getState).feeBreakdown[
            serviceType
          ].final;
        }
        dispatch(setFeeBreakdown(reducerType, serviceType, breakdown));
      });
      dispatch(endFetchFee(reducerType));
    } else {
      dispatch(endFetchFee(reducerType));
    }
  };
};

export const getSuggestedFee = reducerType => {
  return (dispatch, getState) => {
    dispatch(startFetchFee(reducerType));
    const url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs/fee`;

    const requestData = {
      job: getReducer(reducerType, getState).job
    };

    const request = axios({
      method: 'POST',
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        _.forEach(data.data, (feeBreakdown, serviceType) => {
          const breakdown = feeBreakdown;
          if (
            getReducer(reducerType, getState).feeBreakdown[serviceType].final ||
            getReducer(reducerType, getState).feeBreakdown[serviceType]
              .total !== breakdown.total
          ) {
            breakdown.final = getReducer(reducerType, getState).feeBreakdown[
              serviceType
            ].final;
          }
          dispatch(setFeeBreakdown(reducerType, serviceType, breakdown));
        });
        dispatch(endFetchFee(reducerType));
      })
      .catch(() => {
        dispatch(endFetchFee(reducerType));
      });
  };
};

const onAddPromo = (reducerType, promoCode) => ({
  type: `${reducerType}_ADD_PROMO`,
  promo_code: promoCode
});

export const addRewardsPromo = (reducerType, promoCode) => {
  return dispatch => {
    dispatch(onAddPromo(reducerType, promoCode));

    enqueueSnackbar('Promo code successfully applied', {
      variant: 'success'
    });
  };
};

export const addGogetShopPromo = (reducerType, promoCode) => {
  return dispatch => {
    dispatch(onAddPromo(reducerType, promoCode));

    enqueueSnackbar('Promo code successfully applied', {
      variant: 'success'
    });
  };
};

export const addPromo = (reducerType, promoCode) => {
  return dispatch => {
    dispatch(startCheckPromo(reducerType));
    const url = `${
      import.meta.env.VITE_SERVER_PATH
    }/private/v1/promo_codes/check_eligibility`;
    let jobType;
    if (reducerType === 'DISPATCH') {
      jobType = 'dispatch';
    } else if (reducerType === 'FOOD_SHOPPING') {
      jobType = 'shopping';
    } else if (reducerType === 'PART_TIMER') {
      jobType = 'part-timer';
    } else if (reducerType === 'PERSONAL_HELPER') {
      jobType = 'personal-helper';
    } else if (reducerType === 'CHEQUE_DEPOSIT') {
      jobType = 'cheque-deposit';
    } else {
      jobType = '';
    }
    const requestData = {
      promoCode,
      jobType
    };
    const request = axios({
      method: 'POST',
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        dispatch(onAddPromo(reducerType, data.data.promo_code));
        dispatch(endCheckPromo(reducerType));
        if (reducerType === 'DISPATCH') {
          dispatch(onChangePage(reducerType, 'overall'));
        }

        enqueueSnackbar('Promo code successfully applied', {
          variant: 'success'
        });
      })
      .catch(() => {
        dispatch(endCheckPromo(reducerType));
      });
  };
};

export const removePromo = reducerType => ({
  type: `${reducerType}_REMOVE_PROMO`
});

const startCheckPromo = reducerType => ({
  type: `${reducerType}_START_CHECK_PROMO`
});

const endCheckPromo = reducerType => ({
  type: `${reducerType}_END_CHECK_PROMO`
});

export const updateHelperPricingJob = reducerType => {
  return (dispatch, getState) => {
    const method = 'PATCH';

    const url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/helper/jobs/${
      getState().editJob.job.id
    }`;
    const { feeBreakdown } = getReducer(reducerType, getState);
    const { job } = getReducer(reducerType, getState);
    const requestData = {
      job: {
        ...job,
        suggested_hourly_fee: feeBreakdown.helper.total,
        minimum_hourly_fee: feeBreakdown.helper.min_fee,
        hourly_fee: feeBreakdown.helper.final,
        fee: job.total_fee
      }
    };

    if (job.blast_pool !== GOGETTER_SELECTION_ANYONE) {
      requestData.job.gogetter_requirements = DEFAULT_GOGETTER_REQUIREMENTS;
    }

    const request = axios({
      method,
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        enqueueSnackbar(data.message, {
          variant: 'success'
        });

        dispatch(
          push(
            `/jobs/${data.data.job_id}${
              !job.status || job.status === 'open'
                ? '?searching_gogetter=true'
                : ''
            }`
          )
        );
      })
      .catch(() => {
        dispatch(endJobCreation(reducerType));
      });
  };
};

// ARMIN: helper pricing edit funciton is updateHelperPricingJob
export const createJob = reducerType => {
  return (dispatch, getState) => {
    const currentBreakdown = _.get(
      getReducer(reducerType, getState).feeBreakdown,
      getReducer(reducerType, getState).job.service_type
    );
    const feeBreakdown = currentBreakdown.final;
    const feeCalc = currentBreakdown.calculation_log;

    dispatch(startJobCreation(reducerType));
    dispatch(setFinalFee(reducerType, feeBreakdown));
    dispatch(setFeeCalc(reducerType, feeCalc));

    if (
      ((reducerType === 'EDITJOB' &&
        getReducer(reducerType, getState).job.helper_type ===
          'cheque_deposit') ||
        reducerType === 'CHEQUE_DEPOSIT') &&
      getReducer(reducerType, getState).job.receipt_return_method
    ) {
      dispatch(
        addTemporaryTask(
          reducerType,
          getReducer(reducerType, getState).job.receipt_return_method
        )
      );
      dispatch(joinTemporaryTask(reducerType));
    }

    let url;
    let method;
    if (reducerType === 'EDITJOB') {
      method = 'PATCH';
      url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs/${
        getState().editJob.job.id
      }`;
    } else {
      method = 'POST';
      url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs`;
    }

    const { job, feeBreakdown: jobFeeBreakdown } = getReducer(
      reducerType,
      getState
    );

    const requestData = {
      job: {
        ...job
      }
    };

    if (['dispatch', 'dispatch_flexi', 'egg'].includes(job.service_type)) {
      requestData.job.suggested_fee = jobFeeBreakdown[job.service_type].total;
    }

    const request = axios({
      method,
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        enqueueSnackbar(data.message, {
          variant: 'success'
        });

        dispatch(
          push(
            `/jobs/${data.data.job_id}${
              !job.status || job.status === 'open'
                ? '?searching_gogetter=true'
                : ''
            }`
          )
        );

        if (reducerType !== 'EDITJOB') {
          let analyticsLabel;
          const extraTrackingParams = {};
          if (reducerType === 'DISPATCH') {
            analyticsLabel = 'dispatch';
            if (
              getReducer(reducerType, getState).job.service_type ===
              'dispatch_flexi'
            ) {
              extraTrackingParams.selected_time = 'same-day';
            } else if (
              moment(getReducer(reducerType, getState).job.tasks[0].start_at) <
              moment().add(20, 'minute')
            ) {
              extraTrackingParams.selected_time = 'pickup_now';
            } else {
              extraTrackingParams.selected_time = 'schedule';
            }
          } else if (reducerType === 'FOOD_SHOPPING') {
            analyticsLabel = 'food_shopping';
            if (
              moment(getReducer(reducerType, getState).job.tasks[0].start_at) <
              moment().add(20, 'minute')
            ) {
              extraTrackingParams.selected_time = 'pickup_now';
            } else {
              extraTrackingParams.selected_time = 'schedule';
            }
          } else if (reducerType === 'CHEQUE_DEPOSIT') {
            analyticsLabel = 'cheque_deposit';
          } else if (
            reducerType === 'PERSONAL_HELPER' ||
            reducerType === 'PART_TIMER'
          ) {
            analyticsLabel = getReducer(reducerType, getState).job.helper_type;
          }

          window.analytics.track('job_created', {
            category: 'job_creation',
            label: analyticsLabel,
            revenue: feeBreakdown,
            currency: 'MYR',
            platform: 'pwa',
            product: 'flexible_gig',
            user_type:
              getState().user &&
              getState().user.profile &&
              getState().user.profile.is_business_account
                ? 'business_account'
                : 'free_account',
            ride_id: getReducer(reducerType, getState).job.ride_id,
            payment_type: getReducer(reducerType, getState).job.payment_method,
            promo_code:
              getReducer(reducerType, getState).job.promo_code &&
              getReducer(reducerType, getState).job.promo_code.code,
            blast_pool: getReducer(reducerType, getState).job.blast_pool,
            gogetter_type: gogetterSelection(
              getReducer(reducerType, getState).job
            ),
            selected_gogetter_id: getReducer(reducerType, getState).job
              .selected_gogetter_id,
            gogetter_training_module_id: getReducer(reducerType, getState).job
              .gogetter_training_module_id,
            service_type: getReducer(reducerType, getState).job.service_type,
            ...extraTrackingParams
          });
        }

        dispatch(resetState(reducerType));
      })
      .catch(() => {
        dispatch(endJobCreation(reducerType));
      });
  };
};

export const createMassJob = reducerType => {
  return (dispatch, getState) => {
    const { job } = getReducer(reducerType, getState);
    const { feeBreakdown } = getReducer(reducerType, getState);

    dispatch(startJobCreation(reducerType));

    const url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/helper/jobs`;
    const method = 'POST';

    const requestData = massJobMapper(job, feeBreakdown);

    if (job.blast_pool !== GOGETTER_SELECTION_ANYONE) {
      requestData.job.gogetter_requirements = DEFAULT_GOGETTER_REQUIREMENTS;
    }

    const request = axios({
      method,
      url,
      data: requestData
    });

    request
      .then(({ data }) => {
        enqueueSnackbar(data.message, {
          variant: 'success'
        });

        if (data.data.multiple) {
          dispatch(push('/jobs'));
        } else {
          dispatch(push(`/jobs/${data.data.job_id}?searching_gogetter=true`));
        }

        dispatch(resetState(reducerType));
        dispatch(endJobCreation(reducerType));
      })
      .catch(() => {
        dispatch(endJobCreation(reducerType));
      });
  };
};

const editJoinedStartTime = (reducerType, order, startAt) => ({
  type: `${reducerType}_EDIT_JOINED_START_TIME`,
  order,
  startAt
});

const editJoinedEndTime = (reducerType, order, endAt) => ({
  type: `${reducerType}_EDIT_JOINED_END_TIME`,
  order,
  endAt
});

export const updateVehicle = (reducerType, itemDetails) => {
  const {
    weight,
    size,
    rideId,
    reachedSummary,
    userSelectedVehicle,
    dispatchItem
  } = itemDetails;

  return dispatch => {
    if (dispatchItem === 'cakes') {
      dispatch(editVehicle(reducerType, 2));
      if (reachedSummary) {
        enqueueSnackbar('Vehicle updated to Car', {
          variant: 'info'
        });
      }
    } else if (!userSelectedVehicle) {
      if (weight === '1_10kg') {
        if ((size === 'small' && rideId !== 1) || !rideId) {
          dispatch(editVehicle(reducerType, 1));
          if (reachedSummary) {
            enqueueSnackbar('Vehicle updated to Bike', {
              variant: 'info'
            });
          }
        } else if (size === 'medium' && rideId !== 8) {
          dispatch(editVehicle(reducerType, 8));
          if (reachedSummary) {
            enqueueSnackbar('Vehicle updated to Bike with bag', {
              variant: 'info'
            });
          }
        } else if (size === 'large' && rideId !== 2) {
          dispatch(editVehicle(reducerType, 2));
          if (reachedSummary) {
            enqueueSnackbar('Vehicle updated to Car', {
              variant: 'info'
            });
          }
        }
      } else if (rideId !== 2) {
        dispatch(editVehicle(reducerType, 2));
        if (reachedSummary) {
          enqueueSnackbar('Vehicle updated to Car', {
            variant: 'info'
          });
        }
      }
    } else if (rideId === 1 && weight === '1_10kg' && size === 'medium') {
      dispatch(editVehicle(reducerType, 8));

      enqueueSnackbar(
        'Package is too big/heavy for a Bike. Vehicle updated to Bike with bag.',
        {
          variant: 'error'
        }
      );

      dispatch(resetUserSelectedVehicle(reducerType));
    } else if (
      ((rideId === 1 || rideId === 8) && weight !== '1_10kg') ||
      ((rideId === 1 || rideId === 8) && size === 'large')
    ) {
      dispatch(editVehicle(reducerType, 2));

      enqueueSnackbar(
        'Package is too big/heavy for a Bike. Vehicle updated to Car.',
        {
          variant: 'error'
        }
      );

      dispatch(resetUserSelectedVehicle(reducerType));
    }
  };
};

export const setUserSelectedVehicle = reducerType => ({
  type: `${reducerType}_SET_USER_SELECTED_VEHICLE`
});

const resetUserSelectedVehicle = reducerType => ({
  type: `${reducerType}_RESET_USER_SELECTED_VEHICLE`
});

export const setDeliverNow = (reducerType, deliverNow) => ({
  type: `${reducerType}_SET_DELIVER_NOW`,
  deliverNow
});

const setNonFlexiTasks = reducerType => ({
  type: `${reducerType}_SET_NON_FLEXI_TASKS`
});

export const updateShop = (reducerType, updates) => ({
  type: `${reducerType}_UPDATE_SHOP`,
  updates
});

export const updateDeliveryType = (reducerType, deliveryType) => ({
  type: `${reducerType}_UPDATE_DELIVERY_TYPE`,
  deliveryType
});

export const setFirstRender = (reducerType, isFirstRender) => ({
  type: `${reducerType}_SET_FIRST_RENDER`,
  isFirstRender
});

/*
Mass Job Creation Actions:
- addMassSchedule - Add new row in the job schedule page
- removeMassSchedule - Remove row in the job scedule page
- editMassSchedule - Update one of the rows in the job schedule page
*/

export const updateJobFees = (reducerType, updates) => ({
  type: `${reducerType}_UPDATE_JOB_FEES`,
  updates
});

export const addMassSchedule = reducerType => ({
  type: `${reducerType}_ADD_SCHEDULE`
});

export const removeMassSchedule = (reducerType, order) => ({
  type: `${reducerType}_REMOVE_SCHEDULE`,
  order
});

export const editMassSchedule = (reducerType, order, updates) => ({
  type: `${reducerType}_EDIT_SCHEDULE`,
  order,
  updates
});

export const addBreaktime = (reducerType, order) => ({
  type: `${reducerType}_ADD_BREAK_TIME`,
  order
});

export const removeBreaktime = (reducerType, order) => ({
  type: `${reducerType}_REMOVE_BREAK_TIME`,
  order
});

export const resetBreaktime = (reducerType, order) => ({
  type: `${reducerType}_RESET_BREAK_TIME`,
  order
});

export const setBreaktime = (reducerType, payload) => ({
  type: `${reducerType}_SET_BREAK_TIME`,
  ...payload
});

export const resetScheduleGogetters = reducerType => {
  return (dispatch, getState) => {
    const { job } = getReducer(reducerType, getState);
    if (job && job.schedules) {
      job.schedules.forEach(schedule => {
        dispatch(
          editMassSchedule(reducerType, schedule.order, {
            no_of_gogetters: 1
          })
        );
      });
    }
  };
};

export const getHelperFeeBreakdown = (reducerType, newFee, additionalFee) => {
  return async (dispatch, getState) => {
    dispatch(startFetchFee(reducerType));

    const { job } = getReducer(reducerType, getState);
    const { service_type: serviceType } = job;

    if (additionalFee) {
      dispatch(setAdditionlFeeForHelperJobs(reducerType, additionalFee));
    }

    const body = {
      // adjusted_fee is used for adjust fee, it can be null
      adjusted_fee: newFee || null,
      // this is only during edit flow for now
      additional_fee: additionalFee || null,
      gogetter_pool:
        job.blast_pool === GOGETTER_SELECTION_ANYONE ? job.gogetter_pool : null,
      helper_type: job.helper_type,
      job_type: job.service_type,
      schedules:
        reducerType === 'EDITJOB'
          ? [
              {
                reference: job.reference,
                no_of_gogetters: 1,
                tasks: job.tasks?.map(task => {
                  return {
                    ...task,
                    break_times: task.break_times?.filter(breakTime => {
                      return breakTime.start_at && breakTime.end_at;
                    })
                  };
                })
              }
            ]
          : job?.schedules?.map(schedule => ({
              ...schedule,
              tasks: [
                ...schedule?.days?.map((day, order) => {
                  const startAt = moment(day)
                    .hour(moment(schedule.start_at).hour())
                    .minute(moment(schedule.start_at).minute())
                    .startOf('minute');
                  let endAt = moment(day)
                    .hour(moment(schedule.end_at).hour())
                    .minute(moment(schedule.end_at).minute())
                    .startOf('minute');

                  if (
                    moment(schedule.end_at).day() >
                    moment(schedule.start_at).day()
                  ) {
                    endAt = endAt.add(1, 'day');
                  }

                  return {
                    order,
                    start_at: startAt.toISOString(),
                    end_at: endAt
                      .add(endAt.isBefore(startAt) ? 1 : 0, 'days')
                      .toISOString(),
                    break_times: schedule.break_times?.map(breakTime => {
                      const breakStart = moment(breakTime.start_at);
                      const breakEnd = moment(breakTime.end_at);
                      const taskStart = moment(startAt);
                      const taskEnd = moment(endAt);

                      let breakStartDate;
                      let breakEndDate;

                      const isGraveyardShift =
                        taskStart.day() !== taskEnd.day();

                      if (
                        isGraveyardShift &&
                        breakStart.day() !== breakEnd.day()
                      ) {
                        breakStartDate = startAt;
                        breakEndDate = endAt;
                      } else if (
                        isGraveyardShift &&
                        breakStart
                          .day(taskEnd.day())
                          .isAfter(taskEnd.startOf('day')) &&
                        breakStart
                          .day(taskEnd.day())
                          .isBefore(taskEnd.startOf('day').add(12, 'hours')) &&
                        breakEnd
                          .day(taskEnd.day())
                          .isAfter(taskEnd.startOf('day')) &&
                        breakEnd
                          .day(taskEnd.day())
                          .isBefore(taskEnd.startOf('day').add(12, 'hours'))
                      ) {
                        breakStartDate = endAt;
                        breakEndDate = endAt;
                      } else {
                        breakStartDate = startAt;
                        breakEndDate = startAt;
                      }

                      return {
                        start_at: moment(breakStartDate)
                          .hour(breakStart.hour())
                          .minute(breakStart.minute())
                          .startOf('minute')
                          .toISOString(),
                        end_at: moment(breakEndDate)
                          .hour(breakEnd.hour())
                          .minute(breakEnd.minute())
                          .startOf('minute')
                          .toISOString()
                      };
                    })
                  };
                })
              ],
              reference:
                schedule?.reference ||
                Math.random().toString(36).substring(2, 20)
            }))
    };

    const response = await getJobFeeForHelper(body);

    if (response?.isSuccess) {
      dispatch(
        setHelperFeeBreakDown(reducerType, serviceType, response?.data?.data)
      );
    }
    dispatch(endFetchFee(reducerType));
  };
};

const setAdditionlFeeForHelperJobs = (reducerType, additionalFee) => ({
  type: `${reducerType}_SET_ADDITIONAL_FEE_AND_UPDATE_TOTAL_FEE`,
  additional_fee: additionalFee
});

const setHelperFeeBreakDown = (reducerType, serviceType, response) => ({
  type: `${reducerType}_SET_HELPER_FEE_BREAKDOWN`,
  serviceType,
  hourlyFee: response.hourly_fee,
  noOfJobs: response.no_of_jobs,
  totalFee: response.total_fee,
  schedules: response.schedules
});

export const updateMassFee = (reducerType, fee) => {
  return dispatch => {
    if (!Number.isInteger(fee) || fee < 0) {
      fee = 0;
    }
    dispatch(adjustFee(reducerType, fee));
    dispatch(
      updateJobFees(reducerType, {
        finalFee: fee
      })
    );
  };
};

export const updateTasks = (reducerType, tasks) => ({
  type: `${reducerType}_UPDATE_TASKS`,
  tasks
});

export const updateSmsValue = (reducerType, value) => ({
  type: `${reducerType}_UPDATE_SMS_PREFERENCE`,
  value
});
