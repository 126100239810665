const colors = {
  primary: '#00b4ad',
  primaryLight: '#1EE6D9',
  primaryDark: '#00a19a',
  primaryBackground: '#E2F1F0',
  secondary: '#5e35b1',
  secondaryLight: '#9A69FF',
  black: '#000000',
  white: '#ffffff',
  grey: '#cbcbcb',
  greyDark: '#666666',
  greyMid: '#eeeeee',
  greyLight: '#f2f2f2',
  greyLightest: '#f6f6f6',
  greyUltraLight: '#F6F8F9',
  greyUnselected: '#E2E2E2',
  greyOnfido: '#828893',
  blue: '#127efe',
  blueLight: '#F1F8F7',
  blueChip: '#E3F2FD',
  darkerBlue: '#2569AF',
  red: '#d32f2f',
  redLight: '#ff6b6b',
  redAlert: '#F05050',
  yellow: '#FFBF18',
  green: '#00b45a',
  greenLight: '#72d9a6',
  purple: '#674FC0',
  purpleMid: '#EADFFF',
  greyD8: '#d8d8d8',
  warningPurple: '#F6F0FF',
  warningText: '#673AB7',
  blueDark: '#0074EB',
  blueMid: '#87BEF9',
  dividerPrimary: '#C7DEDD',
  goldLight: '#FFF3E4',
  facebookBlue: '#0866FF',
  hoverGrey: 'rgb(227, 227, 227)'
};

export default colors;
