import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import ProfileReducer from './profileReducer';
import jobsReducer from './jobsReducer';
import currentLocationReducer from './currentLocationReducer';
import authReducer from './authReducer';
import chatReducer from './chatReducer';
import gogetterTrackingReducer from './gogetterTrackingReducer';
import notificationReducer from './notificationReducer';
import editJobReducer from './editJobReducer';
import creditsReducer from './creditsReducer';
import foodAndShoppingReducer from './foodAndShoppingReducer';
import partTimerReducer from './partTimerReducer';
import personalHelperReducer from './personalHelperReducer';
import chequeDepositReducer from './chequeDepositReducer';
import continueCreationReducer from './continueCreationReducer';
import customPopupReducer from './customPopupReducer';
import favouritesReducer from './favouritesReducer';
import trainedReducer from './trainedReducer';
import layoutReducer from './layoutReducer';
import bulkOrderReducer from './bulkOrderReducer';
import ordersReducer from './ordersReducer';
import createHiringPostReducer from './createHiringPostReducer';
import editHiringPostReducer from './editHiringPostReducer';
import createDispatchReducer from './createDispatchReducer';
import hiringPostApplicantsReducer from './hiringPostApplicantsReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user: ProfileReducer,
    createDispatch: createDispatchReducer,
    jobs: jobsReducer,
    currentLocation: currentLocationReducer,
    auth: authReducer,
    chat: chatReducer,
    gogetterTracking: gogetterTrackingReducer,
    notification: notificationReducer,
    editJob: editJobReducer,
    credits: creditsReducer,
    createFoodAndShopping: foodAndShoppingReducer,
    createPartTimer: partTimerReducer,
    continueCreation: continueCreationReducer,
    createPersonalHelper: personalHelperReducer,
    createChequeDeposit: chequeDepositReducer,
    customPopup: customPopupReducer,
    favourites: favouritesReducer,
    teams: trainedReducer,
    layout: layoutReducer,
    createBulkOrder: bulkOrderReducer,
    orders: ordersReducer,
    createHiringPost: createHiringPostReducer,
    editHiringPost: editHiringPostReducer,
    hiringPostApplicants: hiringPostApplicantsReducer
  });
