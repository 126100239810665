import { enqueueSnackbar } from 'notistack';
import { routeToLocation } from '../../api/routing';
import { ROUTING_SETTING_CONFIG } from '../../components/maps/constants';
import { decodePolyline } from '../../libraries/polylineDecoder';
import { getMinMaxTime } from './getMinMaxTime';
import { isValidRoute } from './isValidRoute';

class JobTrackingService {
  constructor({
    gogetterLocation,
    deliveryLocation,
    dispatch,
    updateTracker,
    publicId
  }) {
    this.gogetterLocation = gogetterLocation;
    this.deliveryLocation = deliveryLocation;
    this.dispatch = dispatch;
    this.updateTracker = updateTracker;
    this.publicId = publicId;
  }

  route = async () => {
    if (this.gogetterLocation?.lat && this.gogetterLocation?.long) {
      const requestObject = {
        locations: [
          {
            lat: this.gogetterLocation?.lat,
            lon: this.gogetterLocation?.long
          },
          {
            lat: this.deliveryLocation?.lat,
            lon: this.deliveryLocation?.long
          }
        ],
        ...ROUTING_SETTING_CONFIG
      };

      const isRoutable = isValidRoute(requestObject.locations);

      if (!isRoutable) return;

      const json = encodeURI(JSON.stringify(requestObject));

      const response = await routeToLocation(json);

      if (response?.isSuccess && response?.data) {
        this.onRouteResult(response?.data);
      }
    }
  };

  onRouteResult = result => {
    if (result?.trip?.legs && result?.trip?.legs?.length > 0) {
      const route = result?.trip?.legs?.[0];
      const arrivalTime = getMinMaxTime(result?.trip?.summary?.time);
      const routeShape = decodePolyline(route?.shape, false);

      const tracker = {
        gogetterLocation: this.gogetterLocation,
        routeShape,
        arrivalTime
      };

      this.dispatch(this.updateTracker(this.publicId, tracker));
    } else {
      enqueueSnackbar('Unable to locate GoGetter. Please try again shortly.', {
        variant: 'error'
      });
    }
  };
}
export default JobTrackingService;
