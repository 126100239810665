/* eslint-disable no-param-reassign */
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import {
  getAuthToken,
  setCookie,
  logout
} from '../../libraries/authentication';

export const requestConfigInterceptor = config => {
  // Do something before request is sent
  config.headers['Content-Type'] = 'application/json';
  config.headers.Accept = 'application/json';
  if (
    (config.url.includes(import.meta.env.VITE_SERVER_PATH) ||
      config.url.includes(import.meta.env.VITE_GOGET_TEMPLATES) ||
      config.url.includes(import.meta.env.VITE_AWS_LAMBDA_GEOCODE_URL)) &&
    getAuthToken()
  ) {
    config.headers.Authorization = getAuthToken();
  }
  return config;
};

export default {
  setupInterceptors: (store, history) => {
    // Add a request interceptor
    axios.interceptors.request.use(requestConfigInterceptor, error => {
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(
      response => {
        // Do something with response data
        if (!!response.data && !!response.data.refresh_token) {
          setCookie('goget-auth-token', response.data.refresh_token);
        }
        return response;
      },
      error => {
        // Do something with response error
        if (error.response) {
          if (
            !!error.response.data &&
            !!error.response.data.error_action &&
            error.response.data.error_action === 'logout'
          ) {
            logout();
            history.push('/home');
          } else if (error.response.data.message) {
            enqueueSnackbar(error.response.data.message, {
              variant: 'error'
            });
          } else {
            enqueueSnackbar(
              "We're sorry! Something went wrong. Please give us some time to fix the error. You may also refresh the page or try again later. Thank you",
              {
                variant: 'error'
              }
            );
          }
        }

        return Promise.reject(error);
      }
    );
  }
};
