import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import ArrowForward from '@mui/icons-material/ArrowForward';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FindInPage from '@mui/icons-material/FindInPage';
import Home from '@mui/icons-material/Home';
import Person from '@mui/icons-material/Person';
import Work from '@mui/icons-material/Work';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';

import GogetLogoWhite from '../assets/white_logo.svg';

import {
  getAuthToken,
  logout,
  getCookie
} from '../../libraries/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import Crown from '../assets/crown.svg';
import VerifiedGogetters from '../assets/verifiedGogetters.svg';

import DialogGogetterApp from '../ui/DialogGogetterApp';

import { useHistory, useLocation } from 'react-router';
import { fetchBusinessStatus } from '../../api/profile';
import { updateProfileDetails } from '../../reducers/profileReducer';

const StyledContainer = styled.div`
  cursor: default;
  height: 100%;
`;
const StyledBody = styled.div`
  width: ${props => (props.isMobile ? '' : 'calc(100% - 185px)')};
  margin-left: ${props => (props.isMobile ? '' : '185px')};
`;
const StyledDrawer = styled(Drawer)`
  > div:first-child {
    background: ${colors.primary};
    width: 185px;
  }
  display: ${props =>
    props.ismobile === 'true' || props.isTracker ? 'none' : 'block'};
`;
const GogetLogo = styled.img`
  cursor: pointer;
  width: 106px;
  margin: 0 auto;
`;
const StyledListItemIcon = styled(ListItemIcon)`
  color: ${colors.white} !important;
  font-size: ${fontSize.xLarge} !important;
  min-width: 2rem !important;
`;
const StyledListItemText = styled(ListItemText)`
  padding: 0 !important;
  > span:first-child {
    color: ${colors.white};
    font-size: ${fontSize.xLarge};
  }
`;
const NestedListItem = styled(ListItem)`
  > div:first-child {
    padding-left: 4px;
  }
`;
const StyledNestedListItemText = styled(ListItemText)`
  > span:first-child {
    color: ${colors.white};
    font-size: ${fontSize.large};
  }
`;
const IconExpandMore = styled(ExpandMore)`
  color: ${colors.white} !important;
`;
const IconExpandLess = styled(ExpandLess)`
  color: ${colors.white} !important;
`;
const StyledCard = styled(Card)`
  background: ${colors.yellow} !important;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 8px !important;
  margin-bottom: 1rem;
  box-shadow: none !important;
`;
const StyledCardActionArea = styled(CardActionArea)`
  display: flex !important;
  align-items: center !important;
  height: 4rem;
  padding-left: 8px !important;
  padding-right: 8px !important;
`;
const CardText = styled.div`
  line-height: 21px;
  text-align: left;
  padding-left: 8px;
  font-size: ${fontSize.xLarge};
  font-weight: 600;
`;
const StyledArrowForward = styled(ArrowForward)`
  color: ${colors.black} !important;
  width: 20px !important;
  height: 20px !important;
`;
const StyledArrowForwardGG = styled(ArrowForward)`
  width: 20px !important;
  height: 20px !important;
`;
const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  width: 90%;
  margin: 0 auto;
  display: block;
`;
const GogetterImageWrapper = styled.div`
  padding: 0 0.5rem;
  margin: 0 auto;
`;
const GogetterCard = styled(Card)`
  border-radius: 8px !important;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: none !important;
`;
const StyledCardContent = styled.div`
  display: flex;
  background: ${colors.primaryBackground};
  color: ${colors.black};
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
`;
const LeftSideBarFooter = styled.div`
  position: absolute;
  bottom: 3rem;
  width: 100%;
`;

const DesktopLayout = props => {
  const { isMobile, children } = props;
  const [open, setOpen] = useState(true);
  const [isGogetterAppDialogOpen, setIsGogetterAppDialogOpen] = useState(false);
  const [isTracker, setIsTracker] = useState(false);

  const myAccountRef = useRef();

  const isBusinessAccount = useSelector(
    state => state.user?.profile?.is_business_account
  );
  const isGogetterInspector = useSelector(
    state => state?.user?.profile?.is_gogetter_inspector
  );

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const getBusinessStatus = async () => {
    const response = await fetchBusinessStatus();

    if (response?.isSuccess) {
      dispatch(updateProfileDetails(response?.profileDetails));
    }
  };

  const linkTo = path => {
    history.push(path);
  };

  const linkToJob = () => {
    if (getCookie('isShowingGoGetRecruit') === 'true') {
      history.push('/hiring_posts');
    } else {
      history.push('/jobs');
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const onClickModal = () => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'business_upgrade',
      platform: 'pwa'
    });

    history.push('/credits', {
      scrollToUpgrade: true
    });
  };

  const handleClickGogetterApp = () => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'be_a_gogetter',
      platform: 'pwa'
    });

    if (getAuthToken()) {
      setIsGogetterAppDialogOpen(true);
    } else {
      history.push('/home');
    }
  };

  const onCloseGogetterAppDialog = () => {
    setIsGogetterAppDialogOpen(false);
  };

  useEffect(() => {
    getBusinessStatus();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('track')) {
      setIsTracker(true);
    }
  }, [location.pathname]);

  return (
    <StyledContainer isMobile={isMobile}>
      <StyledDrawer
        variant="permanent"
        anchor="left"
        ismobile={isMobile.toString()}
        isTracker={isTracker}
      >
        <GogetLogo onClick={() => linkTo('/')} src={GogetLogoWhite} />
        <List>
          <ListItem button onClick={() => linkTo('/')}>
            <StyledListItemIcon>
              <Home />
            </StyledListItemIcon>
            <StyledListItemText primary="Create Job" />
          </ListItem>
          {getAuthToken() && (
            <>
              <ListItem button onClick={linkToJob}>
                <StyledListItemIcon>
                  <Work />
                </StyledListItemIcon>
                <StyledListItemText primary="My Jobs" />
              </ListItem>
              {isGogetterInspector && (
                <ListItem button onClick={() => linkTo('/gg_inspector')}>
                  <StyledListItemIcon>
                    <FindInPage />
                  </StyledListItemIcon>
                  <StyledListItemText primary="GG Inspector" />
                </ListItem>
              )}
              <ListItem
                ref={myAccountRef}
                id="my-account-dt"
                button
                onClick={handleClick}
              >
                <StyledListItemIcon>
                  <Person />
                </StyledListItemIcon>
                <StyledListItemText primary="My Account" />
                {open ? <IconExpandLess /> : <IconExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NestedListItem button onClick={() => linkTo('/profile')}>
                    <StyledNestedListItemText inset primary="Profile" />
                  </NestedListItem>
                  <a
                    href="https://gogetmy.zendesk.com/hc/en-us"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <NestedListItem button>
                      <StyledNestedListItemText
                        inset
                        primary="Help & Support"
                      />
                    </NestedListItem>
                  </a>
                  <a
                    href={`${
                      import.meta.env.VITE_STATIC_PATH
                    }/terms_conditions/`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <NestedListItem button>
                      <StyledNestedListItemText
                        inset
                        primary="Terms & Conditions"
                      />
                    </NestedListItem>
                  </a>
                  <NestedListItem onClick={logout} button>
                    <StyledNestedListItemText inset primary="Log Out" />
                  </NestedListItem>
                </List>
              </Collapse>
            </>
          )}
        </List>

        <LeftSideBarFooter>
          {!isBusinessAccount && (
            <StyledCard onClick={onClickModal}>
              <StyledCardActionArea>
                <ImageWrapper>
                  <Image src={Crown} />
                </ImageWrapper>
                <CardText>GoGet Business</CardText>
                <StyledArrowForward />
              </StyledCardActionArea>
            </StyledCard>
          )}

          <GogetterCard onClick={handleClickGogetterApp}>
            <CardActionArea>
              <GogetterImageWrapper>
                <Image src={VerifiedGogetters} alt="gogetters" />
              </GogetterImageWrapper>
              <StyledCardContent>
                <CardText>Earn in your free time</CardText>
                <StyledArrowForwardGG />
              </StyledCardContent>
            </CardActionArea>
          </GogetterCard>
        </LeftSideBarFooter>
      </StyledDrawer>
      <StyledBody isMobile={isMobile}>{children}</StyledBody>
      <DialogGogetterApp
        open={isGogetterAppDialogOpen}
        onClose={onCloseGogetterAppDialog}
      />
    </StyledContainer>
  );
};

export default React.memo(DesktopLayout);
