import { configureStore } from '@reduxjs/toolkit';
import { createHashHistory, createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import createRootReducer from '../reducers';
import { REDUX_VERSION } from '../libraries/ReduxVersionManager';

const PRODUCTION_ENVIRONMENT = 'production';

function getHistory() {
  if (window.cordova) {
    return createHashHistory();
  } else {
    return createBrowserHistory();
  }
}

export const history = getHistory();

const persistConfig = {
  key: `root-v${REDUX_VERSION}`,
  storage,
  whitelist: [
    'user',
    'chat',
    'createJob',
    'createDispatch',
    'notification',
    'credits',
    'auth',
    'createFoodAndShopping',
    'createPartTimer',
    'continueCreation',
    'createPersonalHelper',
    'createChequeDeposit',
    'createBulkOrder',
    'createHiringPost',
    'editHiringPost',
    'favourites'
  ]
};
const reducers = persistReducer(persistConfig, createRootReducer(history));

export default () => {
  const store = configureStore({
    devTools: import.meta.env.VITE_SERVER_TYPE_EXT !== PRODUCTION_ENVIRONMENT,
    reducer: reducers,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(routerMiddleware(history))
  });
  const persistor = persistStore(store);
  return { store, persistor };
};
