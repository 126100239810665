import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

import colors from '../style/colors';

const UpdateSnackbar = () => {
  return (
    <Snackbar
      id="sw-update-snackbar"
      open
      message={<span>A new version of GoGet is available</span>}
      action={[
        <Button
          style={{ color: colors.primary }}
          id="sw-update-reload"
          key={Math.random()}
        >
          Refresh
        </Button>
      ]}
    />
  );
};

export default UpdateSnackbar;
