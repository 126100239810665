import moment from 'moment';
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { pickupWindow } from '../libraries/constants';
import generateCreateDefaultState from './generateCreateDefaultState';
import NewRoutingService from '../services/maps/NewRoutingService';
import { getJobFee } from '../api/createJob';
import { getMinMaxTime } from '../services/maps/getMinMaxTime';
import toNearestFifteen from '../services/dateTimeFormat/toNearestFifteen';
import axios from 'axios';
import NewShortestRouteService from '../services/maps/NewShortestRouteService';
import { enqueueSnackbar } from 'notistack';
import { OVERALL_PAGE } from '../components/dispatchNew/constants';
import { push } from 'connected-react-router';
import { gogetterSelection } from '../services/analytics/trackingHelpers';

export const DISPATCH_REDUCER = 'DISPATCH';

export const initialState = generateCreateDefaultState(DISPATCH_REDUCER);

const createDispatchReducer = createSlice({
  name: DISPATCH_REDUCER,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setStartState: (_, action) => {
      return action.payload;
    },
    enterSummary: state => {
      state.reachedSummary = true;
    },
    startFetchFee: state => {
      state.fetchingFee = true;
    },
    endFetchFee: state => {
      state.fetchingFee = false;
    },
    startCheckPromo: state => {
      state.promoLoading = true;
    },
    endCheckPromo: state => {
      state.promoLoading = false;
    },
    startJobCreation: state => {
      state.creatingJob = true;
    },
    endJobCreation: state => {
      state.creatingJob = false;
    },
    setFinalFee: (state, action) => {
      state.job.fee = action.payload;
    },
    setFeeCalc: (state, action) => {
      state.job.fee_calc = action.payload;
    },
    setFeeBreakdown: (state, action) => {
      const responseFeeBreakdown = { ...state.feeBreakdown };
      const { serviceType, breakdown } = action.payload;
      responseFeeBreakdown[serviceType] = breakdown;
      state.feeBreakdown = responseFeeBreakdown;
    },
    setNonFlexiTasks: state => {
      state.job.nonFlexiTasks = state.job.tasks;
    },
    setDeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setServiceType: (state, action) => {
      state.job.service_type = action.payload;
    },
    setFirstRender: (state, action) => {
      state.isFirstRender = action.payload;
    },
    setDeliverNow: (state, action) => {
      state.deliverNow = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    setFirstEntry: state => {
      state.firstEntry = true;
    },
    dismissFirstEntry: state => {
      state.firstEntry = false;
    },
    setShortestRouting: (state, action) => {
      state.shortestRouting = action.payload;
    },
    setRouting: (state, action) => {
      state.routing = action.payload;
    },
    updateOriginalTaskAndRoute: (state, action) => {
      state.original_tasks = action.payload.tasks;
      state.original_route = action.payload.route;
    },
    updateRoute: (state, action) => {
      state.route = action.payload;
    },
    updateDistance: (state, action) => {
      state.job.distance_covered_m = action.payload;
    },
    updateEggValidation: (state, action) => {
      state.eggValidation = { ...state.eggValidation, ...action.payload };
    },
    updateSmsPreference: (state, action) => {
      state.job.sms = action.payload;
    },
    resetTaskOrder: state => {
      state.job.tasks = state.job.tasks.map((task, index) => {
        return { ...task, order: index };
      });
    },
    removeOriginalTaskAndRoute: state => {
      state.original_tasks = undefined;
      state.original_route = undefined;
    },
    removeTaskStart: (state, action) => {
      const order = action.payload;
      state.job.tasks = state.job.tasks.filter(task => task.order !== order);
    },
    removePromo: state => {
      state.job.promo_code = undefined;
    },
    resetUserSelectedVehicle: state => {
      state.userSelectedVehicle = false;
    },
    setUserSelectedVehicle: state => {
      state.userSelectedVehicle = true;
    },
    addPromo: (state, action) => {
      state.job.promo_code = action.payload;
    },
    setGogetterSelection: (state, action) => {
      const { blastPool, gogetterTrainingModuleId, selectedGogetterId } =
        action.payload;
      state.job.blast_pool = blastPool;
      state.job.selected_gogetter_id = selectedGogetterId;
      state.job.gogetter_training_module_id = gogetterTrainingModuleId;
    },
    setAutoApprove: (state, action) => {
      state.job.auto_approve = action.payload;
    },
    setFormErrors: (state, action) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    setFormLoading: (state, action) => {
      state.formLoading = action.payload;
    },
    addDropoff: state => {
      state.job.tasks.push({
        location: undefined,
        lat: undefined,
        'long': undefined,
        order: state.job.tasks.length,
        type: 'Drop off',
        parking: false
      });
    },
    startEditTask: (state, action) => {
      const { order, page } = action.payload;
      state.originalTaskToEdit = state.job.tasks.find(
        task => task.order === order
      );
      state.modifyTask = order;
      state.modifyTaskPage = page;
    },
    editTask: (state, action) => {
      const { order, updates } = action.payload;

      state.job.tasks = state.job.tasks.map(task => {
        if (task.order === order) {
          return { ...task, ...updates };
        }
        return task;
      });
    },
    editItem: (state, action) => {
      state.job.item = { ...state.job.item, ...action.payload };
    },
    editImage: (state, action) => {
      state.job.images = state.job.images.map(image => {
        if (image.id === action.payload.id) {
          return { ...image, ...action.payload.updates };
        }
        return image;
      });
    },
    editPayment: (state, action) => {
      const { paymentMethod, isUserSelectedPaymentMethod } = action.payload;
      state.job.payment_method = paymentMethod;
      state.isUserSelectedPaymentMethod = isUserSelectedPaymentMethod;
    },
    editVehicle: (state, action) => {
      state.job.ride_id = action.payload;
    },
    editNotes: (state, action) => {
      state.job.notes = action.payload;
    },
    adjustFee: (state, action) => {
      const currentFeeBreakdown = { ...state.feeBreakdown };
      currentFeeBreakdown[state.job.service_type] = {
        ...state.feeBreakdown[state.job.service_type],
        'final': action.payload
      };
      state.feeBreakdown = currentFeeBreakdown;
    },
    doneEditTask: state => {
      state.originalTaskToEdit = undefined;
      state.modifyTask = undefined;
      state.modifyTaskPage = undefined;
    }
  }
});

export const updateFee = fee => {
  return dispatch => {
    let adjustedFee = fee;
    if (!Number.isInteger(adjustedFee) || adjustedFee < 0) {
      adjustedFee = 0;
    }
    dispatch(adjustFee(adjustedFee));
  };
};

export const getSuggestedFee = () => {
  return (dispatch, getState) => {
    dispatch(startFetchFee());
    const url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs/fee`;

    const jobStore = getState().createDispatch;

    const requestData = {
      job: jobStore.job
    };

    const request = axios({
      method: 'POST',
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        _.forEach(data.data, (feeBreakdown, serviceType) => {
          const breakdown = feeBreakdown;
          if (
            jobStore.feeBreakdown[serviceType].final ||
            jobStore.feeBreakdown[serviceType].total !== breakdown.total
          ) {
            breakdown.final = jobStore.feeBreakdown[serviceType].final;
          }
          dispatch(setFeeBreakdown({ serviceType, breakdown }));
        });
        dispatch(endFetchFee());
      })
      .catch(() => {
        dispatch(endFetchFee());
      });
  };
};

export const createJob = () => {
  return (dispatch, getState) => {
    const currentBreakdown = _.get(
      getState().createDispatch.feeBreakdown,
      getState().createDispatch.job.service_type
    );
    const feeBreakdown = currentBreakdown.final;
    const feeCalc = currentBreakdown.calculation_log;

    dispatch(startJobCreation());
    dispatch(setFinalFee(feeBreakdown));
    dispatch(setFeeCalc(feeCalc));

    const url = `${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs`;

    const { job, feeBreakdown: jobFeeBreakdown } = getState().createDispatch;

    const requestData = {
      job: {
        ...job,
        suggested_fee: jobFeeBreakdown[job.service_type].total
      }
    };

    const request = axios({
      method: 'POST',
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        enqueueSnackbar(data.message, {
          variant: 'success'
        });

        dispatch(
          push(
            `/jobs/${data.data.job_id}${
              !job.status || job.status === 'open'
                ? '?searching_gogetter=true'
                : ''
            }`
          )
        );

        const extraTrackingParams = {};

        const analyticsLabel = 'dispatch';
        if (getState().createDispatch.job.service_type === 'dispatch_flexi') {
          extraTrackingParams.selected_time = 'same-day';
        } else if (
          moment(getState().createDispatch.job.tasks[0].start_at) <
          moment().add(20, 'minute')
        ) {
          extraTrackingParams.selected_time = 'pickup_now';
        } else {
          extraTrackingParams.selected_time = 'schedule';
        }

        window.analytics.track('job_created', {
          category: 'job_creation',
          label: analyticsLabel,
          revenue: feeBreakdown,
          currency: 'MYR',
          platform: 'pwa',
          product: 'flexible_gig',
          user_type:
            getState().user &&
            getState().user.profile &&
            getState().user.profile.is_business_account
              ? 'business_account'
              : 'free_account',
          ride_id: getState().createDispatch.job.ride_id,
          payment_type: getState().createDispatch.job.payment_method,
          promo_code:
            getState().createDispatch.job.promo_code &&
            getState().createDispatch.job.promo_code.code,
          blast_pool: getState().createDispatch.job.blast_pool,
          gogetter_type: gogetterSelection(getState().createDispatch.job),
          selected_gogetter_id:
            getState().createDispatch.job.selected_gogetter_id,
          gogetter_training_module_id:
            getState().createDispatch.job.gogetter_training_module_id,
          service_type: getState().createDispatch.job.service_type,
          ...extraTrackingParams
        });

        dispatch(resetState());
      })
      .catch(() => {
        dispatch(endJobCreation());
      });
  };
};

export const addPromoCode = promoCode => {
  return dispatch => {
    dispatch(startCheckPromo());
    const url = `${
      import.meta.env.VITE_SERVER_PATH
    }/private/v1/promo_codes/check_eligibility`;

    const requestData = {
      promoCode,
      jobType: 'dispatch'
    };
    const request = axios({
      method: 'POST',
      url,
      data: requestData
    });
    request
      .then(({ data }) => {
        dispatch(addPromo(data.data.promo_code));
        dispatch(endCheckPromo());

        dispatch(changePage(OVERALL_PAGE));

        enqueueSnackbar('Promo code successfully applied', {
          variant: 'success'
        });
      })
      .catch(() => {
        dispatch(endCheckPromo());
      });
  };
};

export const onEditVehicle = rideId => {
  return async (dispatch, getState) => {
    await dispatch(editVehicle(rideId));
    if (rideId === 3) {
      dispatch(updateEggValidation({ noSUV: false }));
    } else {
      dispatch(updateEggValidation({ noSUV: true }));
    }
    await routeJob(dispatch, getState);
  };
};

export const updateVehicle = itemDetails => {
  const {
    weight,
    size,
    rideId,
    reachedSummary,
    userSelectedVehicle,
    dispatchItem
  } = itemDetails;

  return dispatch => {
    if (dispatchItem === 'cakes') {
      dispatch(onEditVehicle(2));
      if (reachedSummary) {
        enqueueSnackbar('Vehicle updated to Car', {
          variant: 'info'
        });
      }
    } else if (!userSelectedVehicle) {
      if (weight === '1_10kg') {
        if ((size === 'small' && rideId !== 1) || !rideId) {
          dispatch(onEditVehicle(1));
          if (reachedSummary) {
            enqueueSnackbar('Vehicle updated to Bike', {
              variant: 'info'
            });
          }
        } else if (size === 'medium' && rideId !== 8) {
          dispatch(onEditVehicle(8));
          if (reachedSummary) {
            enqueueSnackbar('Vehicle updated to Bike with bag', {
              variant: 'info'
            });
          }
        } else if (size === 'large' && rideId !== 2) {
          dispatch(onEditVehicle(2));
          if (reachedSummary) {
            enqueueSnackbar('Vehicle updated to Car', {
              variant: 'info'
            });
          }
        }
      } else if (rideId !== 2) {
        dispatch(onEditVehicle(2));
        if (reachedSummary) {
          enqueueSnackbar('Vehicle updated to Car', {
            variant: 'info'
          });
        }
      }
    } else if (rideId === 1 && weight === '1_10kg' && size === 'medium') {
      dispatch(onEditVehicle(8));

      enqueueSnackbar(
        'Package is too big/heavy for a Bike. Vehicle updated to Bike with bag.',
        {
          variant: 'error'
        }
      );

      dispatch(resetUserSelectedVehicle());
    } else if (
      ((rideId === 1 || rideId === 8) && weight !== '1_10kg') ||
      ((rideId === 1 || rideId === 8) && size === 'large')
    ) {
      dispatch(onEditVehicle(2));

      enqueueSnackbar(
        'Package is too big/heavy for a Bike. Vehicle updated to Car.',
        {
          variant: 'error'
        }
      );

      dispatch(resetUserSelectedVehicle());
    }
  };
};

export const updateTaskAndShortestRoute = () => {
  return (dispatch, getState) => {
    getShortestRoute(dispatch, getState);
    window.analytics.track('shortest_route', {
      category: 'job_creation',
      label: 'dispatch',
      platform: 'pwa'
    });
  };
};

export const resetOriginalTaskAndRoute = () => {
  return (dispatch, getState) => {
    getOriginalTaskAndRoute(dispatch, getState);
    dispatch(setNonFlexiTasks());

    enqueueSnackbar('Successfully reset original route.', {
      variant: 'success'
    });

    window.analytics.track('reset_route', {
      category: 'job_creation',
      label: 'dispatch',
      platform: 'pwa'
    });
  };
};

export const refreshTime = () => {
  return (dispatch, getState) => {
    const jobStore = getState().createDispatch;
    const pickUpTask = jobStore.job.tasks[0];
    const { page, modifyTaskPage, deliveryType } = jobStore;
    if (!(page === 'locations' && modifyTaskPage === 'details')) {
      if (!!pickUpTask.start_at && moment(pickUpTask.start_at) < moment()) {
        dispatch(
          updateTaskAndRoute({
            order: 0,
            updates: {
              start_at:
                deliveryType !== 'immediate' || pickUpTask.is_scheduled_delivery
                  ? toNearestFifteen(
                      moment().add(pickupWindow.intervalStart, 'minute')
                    )
                  : moment().add(pickupWindow.intervalStart, 'minute')
            }
          })
        );
      } else if (
        !!pickUpTask.end_at &&
        moment(pickUpTask.end_at) <
          moment().add(pickupWindow.timeRange, 'minute')
      ) {
        dispatch(
          updateTaskAndRoute({
            order: 0,
            updates: {
              start_at:
                deliveryType !== 'immediate' || pickUpTask.is_scheduled_delivery
                  ? toNearestFifteen(moment(pickUpTask.start_at))
                  : moment(pickUpTask.start_at)
            }
          })
        );
      }
    }
  };
};

export const updateTaskAndRoute = ({ order, updates }) => {
  const { editTask } = createDispatchReducer.actions;
  return (dispatch, getState) => {
    if (order === 0 && updates && updates.start_at) {
      const endAtRange = pickupWindow.intervalEnd;

      const latestStartTime = moment().add(endAtRange, 'minute');
      const startAt = moment(updates.start_at);

      if (startAt < latestStartTime) {
        updates.end_at = latestStartTime;
      } else {
        updates.end_at = undefined;
      }
    }
    dispatch(editTask({ order, updates }));
    routeJob(dispatch, getState);
  };
};

export const removeTask = order => {
  return (dispatch, getState) => {
    const { tasks } = getState().createDispatch.job;
    dispatch(removeTaskStart(order));

    if (tasks.length < 4) {
      dispatch(updateEggValidation({ withinStopLimit: true }));
    } else {
      dispatch(updateEggValidation({ withinStopLimit: false }));
    }

    dispatch(resetTaskOrder());
    routeJob(dispatch, getState);
    dispatch(removeOriginalTaskAndRoute());
  };
};

export const updatePaymentMethod = () => {
  return (dispatch, getState) => {
    const { payment_method } = getState().createDispatch.job;
    dispatch(getFee());
    if (payment_method === 'cash') {
      dispatch(updateEggValidation({ noCash: false }));
    } else {
      dispatch(updateEggValidation({ noCash: true }));
    }
  };
};

export const onEditPayment = paymentDetails => {
  const { paymentMethod, isUserSelectedPaymentMethod } = paymentDetails;
  return dispatch => {
    dispatch(editPayment({ paymentMethod, isUserSelectedPaymentMethod }));

    if (paymentMethod === 'cash') {
      dispatch(updateEggValidation({ noCash: false }));
    } else {
      dispatch(updateEggValidation({ noCash: true }));
    }
  };
};

const getOriginalTaskAndRoute = (dispatch, getState) => {
  const { original_route, original_tasks } = getState().createDispatch;
  dispatch(updateRoute(original_route));

  let index = 0;
  _.map(original_tasks, task => {
    dispatch(
      updateTaskAndRoute({
        order: index,
        updates: {
          location: task.location,
          location_lat: task.location_lat,
          location_long: task.location_long,
          parking: task.parking,
          edo: task.edo,
          reference: task.reference,
          recipient_name: task.recipient_name,
          recipient_phone_num: task.recipient_phone_num,
          sender_name: task.sender_name,
          sender_email: task.sender_email,
          location_notes: task.location_notes
        }
      })
    );
    index += 1;
    return true;
  });

  dispatch(updateOriginalTaskAndRoute({ tasks: null, route: null }));
};

const getShortestRoute = (dispatch, getState) => {
  const { job, route } = getState().createDispatch;
  const { tasks, ride_id } = job;

  const shortestRouteService = new NewShortestRouteService({
    dispatch,
    locations: tasks,
    updateOriginalTaskAndRoute,
    updateTaskAndRoute,
    setShortestRouting,
    updateDistance,
    getState,
    editTask,
    getFee,
    rideId: ride_id,
    updateRoute,
    job,
    route,
    calculateTimeRange,
    setNonFlexiTasks
  });
  shortestRouteService.getRoute();
};

const checkRain = () => {
  return dispatch => {
    axios
      .get(`${import.meta.env.VITE_SERVER_PATH}/private/v1/jobs/raining`)
      .then(response => {
        dispatch(
          updateEggValidation({
            isNotRaining: !response.data.data.raining
          })
        );
      })
      .catch(() => {});
  };
};

const checkEggRideAvailability = () => {
  return dispatch => {
    axios
      .get(
        `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/jobs/check_rides_availability_egg_jobs`
      )
      .then(response => {
        dispatch(
          updateEggValidation({
            eggRideAvailability: response.data.data.egg_rides_blocked
          })
        );
      })
      .catch(() => {});
  };
};

const checkEggValidation = ({ tasks, dispatch, getState }) => {
  const { ride_id, payment_method } = getState().createDispatch.job;

  // check location coverage
  if (tasks[0].location_lat) {
    const coverageUrl = `${
      import.meta.env.VITE_SERVER_PATH
    }/private/v1/jobs/egg_coverage_check`;
    const coverageData = {
      locations: tasks
    };
    const coverageRequest = axios({
      method: 'POST',
      url: coverageUrl,
      data: coverageData
    });
    coverageRequest
      .then(({ data }) => {
        dispatch(updateEggValidation({ coverage: data.data.covered }));
      })
      .catch(() => {});
  }

  // check time range
  const lastHour = [1, 8, 2].includes(ride_id) ? 21 : 17;

  if (
    moment(tasks[0].start_at) >=
      moment(tasks[0].start_at).startOf('day').add(8, 'hours') &&
    moment(tasks[0].start_at) <=
      moment(tasks[0].start_at).startOf('day').add(lastHour, 'hours')
  ) {
    dispatch(updateEggValidation({ timeRange: true }));
  } else {
    dispatch(updateEggValidation({ timeRange: false }));
  }

  // check within stop limit
  if (tasks.length < 4) {
    dispatch(updateEggValidation({ withinStopLimit: true }));
  } else {
    dispatch(updateEggValidation({ withinStopLimit: false }));
  }

  // check noCash
  if (payment_method === 'cash') {
    dispatch(updateEggValidation({ noCash: false }));
  } else {
    dispatch(updateEggValidation({ noCash: true }));
  }

  // check noSUV
  if (ride_id === 3) {
    dispatch(updateEggValidation({ noSUV: false }));
  } else {
    dispatch(updateEggValidation({ noSUV: true }));
  }

  // check isNotRaining
  dispatch(checkRain());

  // check EggRideAvailability
  dispatch(checkEggRideAvailability());
};

const calculateTimeRange = ({ locations, route, serviceType }) => {
  const { editTask, setNonFlexiTasks } = createDispatchReducer.actions;
  return dispatch => {
    let lastStart = locations[0].start_at;
    let lastEnd = locations[0].end_at || locations[0].start_at;
    let orderCounter = 1;

    _.map(route?.legs, leg => {
      const { minTime, maxTime } = getMinMaxTime(leg?.summary?.time);

      const startTravelTime = minTime;
      const endTravelTime = maxTime;

      lastStart = moment(lastStart)
        .add(Math.ceil(startTravelTime / 60), 'minutes')
        .add(5, 'minutes');
      lastEnd = moment(lastEnd)
        .add(Math.ceil((endTravelTime * 1.2) / 60), 'minutes')
        .add(15, 'minutes');

      if (
        !locations[orderCounter].is_scheduled_delivery ||
        locations[orderCounter].start_at < lastStart
      ) {
        dispatch(
          editTask({
            order: orderCounter,
            updates: {
              start_at: lastStart,
              end_at: lastEnd
            }
          })
        );
      } else if (locations[orderCounter].is_scheduled_delivery) {
        if (locations[orderCounter].start_at < lastEnd) {
          dispatch(
            editTask({
              order: orderCounter,
              updates: {
                end_at: lastEnd
              }
            })
          );
        } else {
          dispatch(
            editTask({
              order: orderCounter,
              updates: {
                end_at: undefined
              }
            })
          );
        }
      }
      orderCounter += 1;
    });

    if (['egg', 'dispatch', 'dispatch_flexi'].includes(serviceType)) {
      dispatch(setNonFlexiTasks());
    }

    if (
      !!serviceType &&
      serviceType === 'dispatch_flexi' &&
      moment(locations[0].start_at) >=
        moment(locations[0].start_at).startOf('day').add(8, 'hours') &&
      moment(locations[0].start_at) <=
        moment(locations[0].start_at).startOf('day').add(13, 'hours')
    ) {
      _.map(locations, location => {
        if (location.order === 0) {
          dispatch(
            editTask({
              order: location.order,
              updates: {
                end_at: moment(lastEnd).startOf('day').add(15, 'hours')
              }
            })
          );
        } else {
          dispatch(
            editTask({
              order: location.order,
              updates: {
                end_at: moment(lastEnd).startOf('day').add(17, 'hours')
              }
            })
          );
        }
      });
    }
  };
};

export const getFee = () => {
  const { startFetchFee, endFetchFee, setFeeBreakdown } =
    createDispatchReducer.actions;
  return async (dispatch, getState) => {
    const jobStore = getState().createDispatch;
    dispatch(startFetchFee());
    const requestData = {
      job: jobStore.job
    };

    const response = await getJobFee(requestData);

    if (response?.isSuccess) {
      const { data } = response;
      _.forEach(data?.data, (feeBreakdown, serviceType) => {
        const breakdown = feeBreakdown;
        if (
          !(
            jobStore.feeBreakdown[serviceType] &&
            jobStore.feeBreakdown[serviceType].final
          ) ||
          jobStore.feeBreakdown[serviceType].total !== breakdown.total
        ) {
          breakdown.final = breakdown.total;
        } else {
          breakdown.final = jobStore.feeBreakdown[serviceType].final;
        }
        dispatch(setFeeBreakdown({ serviceType, breakdown }));
      });
      dispatch(endFetchFee());
    } else {
      dispatch(endFetchFee());
    }
  };
};

const routeJob = (dispatch, getState) => {
  const { setRouting, updateRoute, updateDistance } =
    createDispatchReducer.actions;
  const { tasks, ride_id, service_type, category_type } =
    getState().createDispatch.job;

  dispatch(setRouting(true));
  checkEggValidation({ tasks, dispatch, getState });
  const tasksWithLocation = tasks.filter(task => task.location_lat);

  if (tasksWithLocation && tasksWithLocation.length > 1) {
    const routingService = new NewRoutingService({
      dispatch,
      locations: tasks,
      updateRoute,
      setRouting,
      updateDistance,
      rideId: ride_id,
      serviceType: service_type,
      getFee,
      calculateTimeRange,
      categoryType: category_type
    });
    routingService.route();
  }
};

export const {
  resetState,
  enterSummary,
  changePage,
  setFirstEntry,
  dismissFirstEntry,
  addDropoff,
  startEditTask,
  editTask,
  setDeliveryType,
  setServiceType,
  setFirstRender,
  setDeliverNow,
  doneEditTask,
  updateEggValidation,
  removeTaskStart,
  resetTaskOrder,
  removeOriginalTaskAndRoute,
  setShortestRouting,
  setNonFlexiTasks,
  updateDistance,
  updateRoute,
  updateOriginalTaskAndRoute,
  editItem,
  editImage,
  editPayment,
  resetUserSelectedVehicle,
  setUserSelectedVehicle,
  editVehicle,
  updateSmsPreference,
  editNotes,
  removePromo,
  addPromo,
  startCheckPromo,
  endCheckPromo,
  startFetchFee,
  endFetchFee,
  setFeeBreakdown,
  adjustFee,
  setGogetterSelection,
  setAutoApprove,
  setFormErrors,
  setFormLoading,
  startJobCreation,
  endJobCreation,
  setFinalFee,
  setFeeCalc,
  setStartState
} = createDispatchReducer.actions;

export default createDispatchReducer.reducer;
