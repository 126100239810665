import { createSlice } from '@reduxjs/toolkit';
import {
  SALARY_TYPE_EXACT,
  SALARY_TYPE_RANGE,
  SUMMARY_PAGE
} from '../components/hiringPost/constants';

import { initialState as createInitialState } from './createHiringPostReducer';

export const initialState = {
  ...createInitialState,
  page: SUMMARY_PAGE
};

const editHiringPostReducer = createSlice({
  name: 'editHiringPost',
  initialState,
  reducers: {
    startEdit: (state, action) => {
      state.page = SUMMARY_PAGE;
      state.job_details = {
        ...action.payload.jobDetails,
        salary_type: action.payload.jobDetails.salary_range_end
          ? SALARY_TYPE_RANGE
          : SALARY_TYPE_EXACT
      };
    },
    editJobDetails: (state, action) => {
      state.job_details = {
        ...state.job_details,
        ...action.payload.update
      };
    },
    changePage: (state, action) => {
      state.page = action.payload.page;
    },
    startUpdateJob: state => {
      state.isPostingJob = true;
    },
    endUpdateJob: state => {
      state.isPostingJob = false;
    },
    resetState: () => {
      return initialState;
    }
  }
});

export const {
  startEdit,
  editJobDetails,
  changePage,
  startUpdateJob,
  endUpdateJob,
  resetState
} = editHiringPostReducer.actions;

export default editHiringPostReducer.reducer;
