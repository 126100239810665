import { MALAYSIAN_AND_NON_MALAYSIAN } from '../../components/hiringPost/constants';

export const DEFAULT_GOGETTER_REQUIREMENTS = {
  dress_code: 'casual',
  skills_required: [],
  gender: 'anyone',
  age_range_start: undefined,
  age_range_end: undefined,
  interview_method: 'no interview',
  nationality: MALAYSIAN_AND_NON_MALAYSIAN
};
