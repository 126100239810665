import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import compareVersions from 'compare-versions';
import styled from 'styled-components/macro';

import Button from '@mui/material/Button';

import AppleIcon from '@mui/icons-material/Apple';

import { socialLogin } from '../../actions/auth';

import colors from '../../style/colors';

const AppleButton = styled(Button)`
  background-color: ${colors.black};
  color: ${colors.white};

  :hover {
    opacity: 0.8;
    background-color: ${colors.black};
  }
`;

class AppleLogin extends Component {
  onCordovaAppleSignIn = () => {
    const { socialLogin } = this.props;
    if (window.cordova) {
      window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        response => {
          const user = {
            uid: response.user,
            user: {
              name: `${response.fullName.givenName} ${response.fullName.familyName}`,
              email: response.email
            },
            type: 'apple'
          };
          socialLogin(user);
        },
        error => {
          if (error.code !== '1001') {
            enqueueSnackbar(
              error.localizedFailureReason || error.localizedDescription,
              {
                variant: 'error'
              }
            );
          }
        }
      );
    }
  };

  render() {
    return (
      <>
        {window.cordova &&
          window.device.version &&
          compareVersions.compare(window.device.version, '13.0', '>=') && (
            <AppleButton
              size="large"
              variant="contained"
              onClick={this.onCordovaAppleSignIn}
              startIcon={<AppleIcon />}
              disableElevation
            >
              CONTINUE WITH APPLE
            </AppleButton>
          )}
      </>
    );
  }
}

export default connect(null, { socialLogin })(AppleLogin);
