export const OVERALL_PAGE = 'overall';
export const VEHICLE_PAGE = 'vehicle';
export const NOTES_PAGE = 'notes';
export const PAYMENT_PAGE = 'payment';
export const PROMO_PAGE = 'promo';
export const GOGETTER_PAGE = 'gogetter';
export const ADJUST_FEE_PAGE = 'adjustFee';
export const IMAGE_PAGE = 'image';
export const ITEM_PAGE = 'item';
export const LOCATIONS_PAGE = 'locations';

export const FIRST_TASK_ORDER = 0;
export const MAXIMUM_DISPATCH_TASKS = 6;

export const DELIVERY_TYPE_IMMEDIATE = 'immediate';
export const DELIVERY_TYPE_SCHEDULE = 'schedule';
export const DELIVERY_TYPE_SAME_DAY = 'same_day';

export const SERVICE_TYPE_DISPATCH = 'dispatch';
export const SERVICE_TYPE_DISPATCH_FLEXI = 'dispatch_flexi';
export const SERVICE_TYPE_EGG = 'egg';

export const ITEM_SIZE_EXTRA_SMALL = 'extra_small';
export const ITEM_SIZE_SMALL = 'small';
export const ITEM_SIZE_MEDIUM = 'medium';
export const ITEM_SIZE_LARGE = 'large';

export const ITEM_TYPE_PARCEL_DOCUMENT = 'parcel-document';
export const ITEM_TYPE_FOOD_DRINKS = 'food-drinks';
export const ITEM_TYPE_FOOD = 'food';
export const ITEM_TYPE_CAKES = 'cakes';
export const ITEM_TYPE_FLOWERS = 'flowers';
export const ITEM_TYPE_OTHERS = 'others';
