import { decodePolyline } from '../../libraries/polylineDecoder';

export const generateShapeFromLegs = legs => {
  if (!legs || !Array.isArray(legs)) return null;
  const shape = [];

  legs.forEach(leg => {
    const legShape = decodePolyline(leg?.shape);
    shape.push(...legShape);
  });

  return shape;
};
