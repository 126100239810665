import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
import { getCookie } from '../../libraries/authentication';
import { createBrowserHistory } from 'history';

const DEVELOP_ENVIRONMENT = 'develop';
const PRODUCTION_ENVIRONMENT = 'production';
const DEVELOP_SAMPLE_RATE = 0.1;
const PRODUCTION_SAMPLE_RATE = 0.2;
const BLACKLISTED_ERRORS = [
  // HERE Maps Errors
  '[timeout]: Could not fetch meta-info from map tile service',
  '[timeout]: Could not fetch copyrights from map tile service',
  'H.Map#setViewBounds',
  'H.map.Group#removeObject',
  // Leaflet Map Errors
  '_leaflet_pos',
  'e is undefined',
  // Clarity Errors
  "null is not an object (evaluating 'Ir.sessionId')",
  "null is not an object (evaluating 'Gr.version')",
  "null is not an object (evaluating 'Ct.sessionId')",
  // Generic Errors
  'Network Error',
  'Request failed with status code',
  "Failed to execute 'transaction' on 'IDBDatabase'",
  'Request aborted',
  'QuotaExceededError',
  'Registration failed - push service error',
  "Unexpected token '<'"
];

const history = createBrowserHistory();

export const SentryRoute = Sentry.withSentryRouting(Route);

const configureSentry = (env, sampleRate) => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ignoreErrors: BLACKLISTED_ERRORS,
    environment: env,
    release: import.meta.env.VITE_GIT_REV_COUNT,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tracesSampleRate: sampleRate
  });
};

export const initializeSentry = () => {
  Sentry.setUser({
    email:
      getCookie('user-email-address') ||
      window.localStorage.getItem('user-email-address') ||
      ''
  });

  if (import.meta.env.VITE_SERVER_TYPE_EXT === DEVELOP_ENVIRONMENT) {
    configureSentry(DEVELOP_ENVIRONMENT, DEVELOP_SAMPLE_RATE);
  } else if (import.meta.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
    configureSentry(PRODUCTION_ENVIRONMENT, PRODUCTION_SAMPLE_RATE);
  }
};
