/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logIn } from '../actions/auth';
import {
  getAuthToken,
  setCookie,
  deleteCookie
} from '../libraries/authentication';
import { continueCreation } from '../actions/continueCreation';

class ManageUrlParams extends Component {
  componentDidMount() {
    const { history, logIn, continueCreation } = this.props;
    const url = new URL(window.location.href);

    // handle access trade
    const atid = url && url.searchParams && url.searchParams.get('atid');
    if (atid) {
      setCookie('atid', atid);
      setCookie('atidTimestamp', new Date().toISOString());
    } else if (
      url &&
      url.searchParams &&
      (url.searchParams.get('utm_source') ||
        url.searchParams.get('utm_medium') ||
        url.searchParams.get('utm_campaign'))
    ) {
      deleteCookie('atid');
      deleteCookie('atidTimestamp');
    }

    // login with JWT token
    const token = url && url.searchParams && url.searchParams.get('token');
    if (token) {
      if (getAuthToken()) {
        history.push(url.pathname);
      } else {
        continueCreation(url.pathname);
        logIn({ token });
      }
    }
  }

  render() {
    return <></>;
  }
}

export default connect(null, { logIn, continueCreation })(ManageUrlParams);
