import { lazy } from 'react';
import { lazyRetry } from '../../libraries/lazyRetry';

const Credits = lazy(() =>
  lazyRetry(() => import('../../components/credits/Credits'))
);
const Dispatch = lazy(() =>
  lazyRetry(() => import('../../components/dispatchNew/DispatchCreate'))
);
const Tracking = lazy(() =>
  lazyRetry(() => import('../../components/tracking/Tracking'))
);
const MobileUpgradeAccount = lazy(() =>
  lazyRetry(() => import('../../components/gogetBusiness/MobileUpgradeAccount'))
);
const HiringPostCreate = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/HiringPostCreate'))
);
const ShortTermLongTermSelection = lazy(() =>
  lazyRetry(() =>
    import('../../components/helper/ui/ShortTermLongTermSelection')
  )
);
const PartTimerCreate = lazy(() =>
  lazyRetry(() => import('../../components/helper/partTimer/PartTimerCreate'))
);
const ChequeDepositCreate = lazy(() =>
  lazyRetry(() => import('../../components/chequeDeposit/ChequeDepositCreate'))
);
const PersonalHelperCreate = lazy(() =>
  lazyRetry(() =>
    import('../../components/helper/personalHelper/PersonalHelperCreate')
  )
);
const FoodAndShoppingCreate = lazy(() =>
  lazyRetry(() =>
    import('../../components/foodAndShopping/FoodAndShoppingCreate')
  )
);
const BulkOrderCreate = lazy(() =>
  lazyRetry(() => import('../../components/bulkOrder/BulkOrderCreate'))
);

export const publicRouters = [
  {
    path: '/upgrade_account',
    component: MobileUpgradeAccount
  },
  {
    path: '/dispatch',
    component: Dispatch
  },
  {
    path: '/part_timer/gig',
    component: PartTimerCreate
  },
  {
    path: '/part_timer/recruit',
    component: HiringPostCreate
  },
  {
    path: '/part_timer',
    component: ShortTermLongTermSelection
  },
  {
    path: '/cheque_deposit',
    component: ChequeDepositCreate
  },
  {
    path: '/personal_helper',
    component: PersonalHelperCreate
  },
  {
    path: '/food_shopping',
    component: FoodAndShoppingCreate
  },
  {
    path: '/credits',
    component: Credits
  },
  {
    path: '/bulk_order',
    component: BulkOrderCreate
  },
  {
    path: '/track/:publicId',
    component: Tracking
  }
];
