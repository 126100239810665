import { cloneDeep, mapKeys, size } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isFetching: false,
  updatedAt: undefined,
  totalOrdersCount: undefined,
  totalOrdersReceived: 0,
  items: {},
  tabType: {
    processing: {
      received: 0,
      total: undefined
    },
    jobs_posted: {
      received: 0,
      total: undefined
    },
    history: {
      received: 0,
      total: undefined
    }
  }
};

const ordersReducer = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    startRequestOrders: state => {
      state.isFetching = true;
    },
    endRequestOrders: state => {
      state.isFetching = false;
    },
    resetTotal: state => {
      state.tabType = {
        processing: {
          ...state.tabType.processing,
          total: undefined
        },
        jobs_posted: {
          ...state.tabType.jobs_posted,
          total: undefined
        },
        history: {
          ...state.tabType.history,
          total: undefined
        }
      };
    },
    receiveOrders: (state, action) => {
      const newOrders = mapKeys(action.payload.bulks, 'id');
      const allOrders = {
        ...state.items,
        ...newOrders
      };
      state.isFetching = false;
      state.updatedAt = action.payload.tab_type_totals?.updated_at;
      state.totalOrdersCount = action.payload.totalBulksCount;
      state.totalOrdersReceived = size(allOrders);
      state.items = allOrders;
      state.tabType = {
        processing: {
          ...state.tabType.processing,
          total: action.payload.tab_type_totals?.processing
        },
        jobs_posted: {
          ...state.tabType.jobs_posted,
          total: action.payload.tab_type_totals?.posted
        },
        history: {
          ...state.tabType.history,
          total: action.payload.tab_type_totals?.history
        }
      };
    },
    updateReceivedTab: (state, action) => {
      const tabType = cloneDeep(state.tabType);
      tabType[action.payload.tabType] = {
        ...tabType[action.payload.tabType],
        received: action.payload.received
      };
      state.tabType = tabType;
    }
  }
});

export default ordersReducer.reducer;

export const {
  startRequestOrders,
  endRequestOrders,
  resetTotal,
  receiveOrders,
  updateReceivedTab
} = ordersReducer.actions;
