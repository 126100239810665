import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loaded: false,
  currentPage: 'overview',
  showHeader: true,
  pending: [],
  favourites: [],
  recommended: []
};

const favouritesReducer = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    updateFavourites: (state, action) => {
      state.loaded = action.payload.loaded;
      state.pending = action.payload.pending;
      state.favourites = action.payload.favourites;
      state.recommended = action.payload.recommended;
    },
    setLoadingFavourites: state => {
      state.loaded = false;
    },
    setFavouritePage: (state, action) => {
      state.showHeader = action.payload.showHeader;
      state.currentPage = action.payload.currentPage;
    }
  }
});

export const { updateFavourites, setLoadingFavourites, setFavouritePage } =
  favouritesReducer.actions;

export default favouritesReducer.reducer;
