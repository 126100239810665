export const lazyRetry = componentImport => {
  return new Promise((resolve, reject) => {
    // Check if the window has already been refreshed for this component
    const hasRefreshed =
      window.sessionStorage.getItem('retry-lazy-refreshed') || false;
    // Try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem('retry-lazy-refreshed', false);
        resolve(component);
      })
      .catch(error => {
        // Refresing will update the index.html file as it is not cached
        // with the new bundle hashes resolving the error
        if (!hasRefreshed) {
          window.sessionStorage.setItem('retry-lazy-refreshed', true);
          return window.location.reload();
        }
        reject(error);
      });
  });
};
