// TODO: Armin check if we can retire this file
import React from 'react';
import styled from 'styled-components/macro';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: ${props => props.isMobile && '24px !important'};
  }
`;
const StyledDiv = styled.div`
  max-width: 400px;
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  width: ${props => (props.isMobile ? '260px' : '350px')};
  height: ${props => (props.isMobile ? '164px' : '220px')};
  margin: 0 auto;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
const Title = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: ${fontSize.oneHalfRem};
  color: ${colors.primary};
  text-align: center;
`;
const StyledDialogActions = styled(DialogActions)`
  padding-left: 24px !important;
  padding-right: 24px !important;
  margin-bottom: 8px;
`;

const DialogUnavailableTile = props => {
  const {
    open,
    onClose,
    onClickBA,
    onClickPartTimerUnavailable,
    onClickPersonalHelperUnavailable,
    jobType,
    isBusinessAccount,
    isMobile
  } = props;
  const isPersonalHelperType =
    jobType === 'Moving Bulky Items' ||
    jobType === 'Flyering' ||
    jobType === 'Packer';
  const isComingSoon =
    jobType === 'Manning Booth' ||
    jobType === 'Event Staff' ||
    jobType === 'Promoter' ||
    jobType === 'Packer' ||
    jobType === 'Floor Staff';
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      isMobile={isMobile}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDiv>
        <ImgWrapper isMobile={isMobile}>
          <Img src="UnavailableTilePopUpImage.png" />
        </ImgWrapper>
        <DialogContent>
          <Title isMobile={isMobile}>Coming Soon</Title>
          <DialogContentText>
            {
              // eslint-disable-next-line
              isBusinessAccount
                ? `No worries! You can still post a ${jobType} job in the old site.`
                : isPersonalHelperType
                ? `No worries! You can still post a ${jobType} job in Personal Helper.`
                : `No worries! You can still post a ${jobType} job in Part Timer.`
            }
          </DialogContentText>
        </DialogContent>
        <StyledDialogActions>
          {
            // eslint-disable-next-line
            isBusinessAccount && !isComingSoon ? (
              <Button onClick={onClickBA} color="primary">
                Proceed to old site
              </Button>
            ) : isPersonalHelperType ? (
              <Button
                onClick={onClickPersonalHelperUnavailable}
                color="primary"
              >
                Proceed to Personal Helper
              </Button>
            ) : (
              <Button onClick={onClickPartTimerUnavailable} color="primary">
                Proceed to Part Timer
              </Button>
            )
          }
        </StyledDialogActions>
      </StyledDiv>
    </StyledDialog>
  );
};
export default DialogUnavailableTile;
