import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getAuthToken } from './authentication';
import { getProfile } from '../reducers/profileReducer';
import { getCreditsRemaining } from '../actions/credits';

// Dev Note: Update this value when a redux persist refresh is needed
export const REDUX_VERSION = 19; // 31st July 2024

export const REDUX_PERSIST_KEY = 'persist:root';
const REDUX_VERSION_KEY = 'redux-version';

const ReduxVersionManager = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // If the old style redux persist exists, remove it
    if (window?.localStorage?.getItem(REDUX_PERSIST_KEY)) {
      window.localStorage.removeItem(REDUX_PERSIST_KEY);
    }

    if (window?.localStorage?.getItem(REDUX_VERSION_KEY)) {
      if (window.localStorage.getItem(REDUX_VERSION_KEY) < REDUX_VERSION) {
        // Remove the previous version persisted state
        window.localStorage.removeItem(
          `${REDUX_PERSIST_KEY}-v${REDUX_VERSION - 1}`
        );
        // Update the stored Redux version
        window.localStorage.setItem(REDUX_VERSION_KEY, REDUX_VERSION);
        // If user is logged in, refresh the profile and credits
        if (getAuthToken()) {
          dispatch(getProfile());
          dispatch(getCreditsRemaining());
        }
      }
    } else {
      window.localStorage.setItem(REDUX_VERSION_KEY, REDUX_VERSION);
    }
  }, []);

  return <></>;
};

export default ReduxVersionManager;
