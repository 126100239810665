import {
  APPLICANT_GENDER_ANYONE,
  EMPLOYMENT_TYPE_PART_TIME,
  JOB_DETAILS_PAGE,
  SALARY_FREQUENCY_MONTHLY,
  SALARY_TYPE_RANGE,
  MALAYSIAN_AND_NON_MALAYSIAN,
  SUMMARY_PAGE,
  MULTIPLE_CHOICE_QUESTION
} from '../components/hiringPost/constants';
import { createSlice } from '@reduxjs/toolkit';

export const DEFAULT_SCREENING_QUESTION = {
  order: 1,
  question_type: MULTIPLE_CHOICE_QUESTION,
  question: undefined,
  answers: ['']
};

export const initialState = {
  page: JOB_DETAILS_PAGE,
  firstEntry: false,
  isPostingJob: false,
  isFetchingFee: false,
  isFeeLoaded: false,
  feeBreakdown: undefined,
  abandonDetails: undefined,
  job_details: {
    title: undefined,
    helper_type: undefined,
    employment_type: EMPLOYMENT_TYPE_PART_TIME,
    duration_months: undefined,
    hours_per_week: undefined,
    city: undefined,
    state: undefined,
    salary_type: SALARY_TYPE_RANGE,
    salary_range_start: undefined,
    salary_range_end: undefined,
    salary_range_timeframe: SALARY_FREQUENCY_MONTHLY,
    description: undefined,
    gender: APPLICANT_GENDER_ANYONE,
    nationality: MALAYSIAN_AND_NON_MALAYSIAN,
    age_range_start: undefined,
    age_range_end: undefined,
    additional_requirements: [],
    benefits: [],
    application_email_notification: false,
    screening_questions: [DEFAULT_SCREENING_QUESTION]
  }
};

const createHiringPostReducer = createSlice({
  name: 'createHiringPost',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    editJobDetails: (state, action) => {
      state.job_details = {
        ...state.job_details,
        ...action.payload.update
      };
    },
    changePage: (state, action) => {
      state.page = action.payload.page;
    },
    setFirstEntry: state => {
      state.firstEntry = true;
    },
    dismissFirstEntry: state => {
      state.firstEntry = false;
    },
    startJobPosting: state => {
      state.isPostingJob = true;
    },
    endJobPosting: state => {
      state.isPostingJob = false;
    },
    startFetchFee: state => {
      state.isFetchingFee = true;
      state.isFeeLoaded = false;
    },
    endFetchFee: state => {
      state.isFetchingFee = false;
    },
    updateFeeBreakdown: (state, action) => {
      state.isFeeLoaded = true;
      state.feeBreakdown = action.payload.feeBreakdown;
    },
    editAbandonDetails: (state, action) => {
      state.abandonDetails = action.payload.abandonDetails;
    },
    addNewQuestion: state => {
      state.job_details.screening_questions.push({
        ...DEFAULT_SCREENING_QUESTION,
        order: state.job_details.screening_questions.length + 1
      });
    },
    deleteQuestion: (state, action) => {
      state.job_details.screening_questions =
        state.job_details.screening_questions
          .filter(question => question.order !== action.payload.order)
          .map((question, index) => {
            return { ...question, order: index + 1 };
          });
    },
    updateQuestion: (state, action) => {
      state.job_details.screening_questions =
        state.job_details.screening_questions.map(question => {
          if (question.order === action.payload.order) {
            return {
              ...question,
              [action.payload.field]: action.payload.value
            };
          }
          return question;
        });
    },
    skipQuestions: state => {
      state.job_details.screening_questions = [DEFAULT_SCREENING_QUESTION];
      state.page = SUMMARY_PAGE;
    }
  }
});

export const {
  resetState,
  editJobDetails,
  changePage,
  setFirstEntry,
  dismissFirstEntry,
  startJobPosting,
  endJobPosting,
  startFetchFee,
  endFetchFee,
  updateFeeBreakdown,
  editAbandonDetails,
  addNewQuestion,
  deleteQuestion,
  updateQuestion,
  skipQuestions
} = createHiringPostReducer.actions;

export default createHiringPostReducer.reducer;
