import axios from 'axios';
import {
  SERVER_URL,
  failedResponse,
  successResponse,
  HTTP_STATUS_OK
} from './apiHelpers';

export const fetchFestivalPromos = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/festival_promos`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        festivalBanners: response?.data?.data?.festival_banners
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
