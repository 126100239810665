const pickupWindow = {
  timeRange: 20,
  intervalStart: 5
};
pickupWindow.intervalEnd = pickupWindow.timeRange + pickupWindow.intervalStart;

const getShoppingPickupWindow = item => {
  const { size } = item;
  const priceRange = item.item_price_range;
  let shoppingIntervalEnd;

  switch (priceRange) {
    case 'RM1 - RM20':
      shoppingIntervalEnd = 45;
      break;
    case 'RM21 - RM50':
      if (size === 'small') {
        shoppingIntervalEnd = 45;
      } else {
        shoppingIntervalEnd = 120;
      }
      break;
    case 'RM51 - RM100':
      if (size === 'small') {
        shoppingIntervalEnd = 60;
      } else {
        shoppingIntervalEnd = 150;
      }
      break;
    case 'RM101 - RM200':
      if (size === 'small') {
        shoppingIntervalEnd = 60;
      } else {
        shoppingIntervalEnd = 150;
      }
      break;
    case 'RM201 - RM500':
      if (size === 'small') {
        shoppingIntervalEnd = 75;
      } else if (size === 'medium') {
        shoppingIntervalEnd = 150;
      } else {
        shoppingIntervalEnd = 180;
      }
      break;
    case 'More than RM500':
      if (size === 'small') {
        shoppingIntervalEnd = 75;
      } else if (size === 'medium') {
        shoppingIntervalEnd = 150;
      } else {
        shoppingIntervalEnd = 180;
      }
      break;
    default:
      break;
  }
  return shoppingIntervalEnd + pickupWindow.intervalStart;
};

export { pickupWindow, getShoppingPickupWindow };
