import React from 'react';
import styled from 'styled-components/macro';
import Slider from 'react-slick';
import _ from 'lodash';

const ImageWrapper = styled.div`
  width: 100%;
`;
const StyledImg = styled.img`
  width: 97.5%;
`;

export const IMAGE_TEST_ID = 'image-slide';

const ImageSlider = props => {
  const { images, handleClickBanner } = props;

  const renderImages = () => {
    return _.map(images, (image, index) => (
      <ImageWrapper key={index}>
        <StyledImg
          alt="description"
          onClick={
            image.linkTo ? () => handleClickBanner(image.linkTo) : () => {}
          }
          src={image.src}
          style={{ margin: 'auto' }}
          data-testid={`${IMAGE_TEST_ID}-${index}`}
        />
      </ImageWrapper>
    ));
  };

  const settings = {
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '25px'
  };

  return <Slider {...settings}>{renderImages()}</Slider>;
};

export default React.memo(ImageSlider);
