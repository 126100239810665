import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Facebook from './Facebook';
import AppleLogin from './AppleLogin';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LoginSignupButtons = () => {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const isFacebookRedirect = location.search.includes('code');

  return (
    <ButtonContainer>
      <Link to="/sign_up">
        <Button size="large" variant="contained" fullWidth disableElevation>
          Sign Up
        </Button>
      </Link>
      <AppleLogin />
      {isLoading ? (
        <Button size="large" variant="outlined" fullWidth disabled>
          <CircularProgress />
        </Button>
      ) : (
        <Facebook onUpdateLoading={setIsLoading} hide={isFacebookRedirect} />
      )}

      <Link to="/login">
        <Button size="large" variant="outlined" fullWidth>
          Log In
        </Button>
      </Link>
    </ButtonContainer>
  );
};

export default LoginSignupButtons;
