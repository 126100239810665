import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import Div100vh from 'react-div-100vh';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CtaButton from '../ui/CtaButton';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import StrongPasswordField from './StrongPasswordField';

import colors from '../../style/colors';

import { updatePassword, validateResetPasswordToken } from '../../api/auth';
import { enqueueSnackbar } from 'notistack';
import { isValidStrongPassword } from '../../services/authentication';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const SpinnerWrapper = styled.div`
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isShowingConfirmPassword, setIsShowingConfirmPassword] =
    useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const desktopLayout = useSelector(state => state.layout?.desktopLayout);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    async function validatePasswordToken() {
      const response = await validateResetPasswordToken(params.token);

      if (response.isSuccess) {
        setIsLoading(false);
      } else {
        history.push('/login');
      }
    }

    if (!params.token) {
      history.push('/forgot_password');
    }

    if (params.token) {
      validatePasswordToken();
    }
  }, []);

  const onBack = () => {
    history.push('/login');
  };

  const handleResetPassword = async () => {
    if (password && confirmPassword && password === confirmPassword) {
      setIsResetting(true);

      const response = await updatePassword(params.token, password);

      if (response.isSuccess) {
        setIsResetting(false);
        enqueueSnackbar(response.message, {
          variant: 'success'
        });
        history.push('/login', { email: response.email });
      } else {
        setIsResetting(false);
      }
    }
  };

  const isPasswordValid =
    !password || (password && isValidStrongPassword(password));
  const isConfirmPasswordValid =
    !confirmPassword || (confirmPassword && confirmPassword === password);

  return (
    <>
      <FixedHeaderAppBar
        isMobile={!desktopLayout}
        title="Reset password"
        onBackClick={onBack}
      />
      <Container
        desktopLayout={desktopLayout}
        style={{ height: 'calc(100vh - 64px)' }}
      >
        {isLoading ? (
          <SpinnerWrapper>
            <CircularProgress size={35} />
          </SpinnerWrapper>
        ) : (
          <Card desktopLayout={desktopLayout}>
            <StrongPasswordField
              name="password"
              password={password}
              onChange={e => setPassword(e.target.value)}
              fullWidth
            />
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              type={isShowingConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle confirm password visibility"
                      onClick={() =>
                        setIsShowingConfirmPassword(!isShowingConfirmPassword)
                      }
                    >
                      {isShowingConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={!isConfirmPasswordValid && 'Passwords do not match'}
              error={!isConfirmPasswordValid}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <BtnWrapper>
              <CtaButton
                disabled={
                  isResetting ||
                  !password ||
                  !isPasswordValid ||
                  !confirmPassword ||
                  !isConfirmPasswordValid
                }
                onClick={handleResetPassword}
              >
                RESET PASSWORD
              </CtaButton>
            </BtnWrapper>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ResetPassword;
