import axios from 'axios';
import { getAuthToken, setCookie } from './authentication';
import { checkPushStatus } from './notification';

export const initializeCordovaNotification = (
  recievedCallbackFunction,
  openCallbackFunction
) => {
  if (window.cordova) {
    // push notifications
    // Enable to debug issues.
    // window.plugins.OneSignal.setLogLevel(6, 6);

    window.plugins.OneSignal.setAppId(import.meta.env.VITE_ONE_SIGNAL_APP_ID);
    window.plugins.OneSignal.setNotificationWillShowInForegroundHandler(
      recievedCallbackFunction
    );
    window.plugins.OneSignal.setNotificationOpenedHandler(openCallbackFunction);

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(
      accept => {
        setCookie('isOneSignalEnabled', String(accept));
      }
    );

    registerCordovaPush();

    window.plugins.OneSignal.addPermissionObserver(state => {
      if (state && state.status && state.status === 2) {
        registerCordovaPush();
      }
    });

    window.plugins.OneSignal.addSubscriptionObserver(state => {
      if (!state.from.subscribed && state.to.subscribed) {
        registerCordovaPush();
      }
    });
  }
};

export const registerCordovaPush = () => {
  if (window.cordova && getAuthToken()) {
    // For debugging we can do -> alert("registerCordovaPush");
    window.plugins.OneSignal.getDeviceState(registerOneSignalNotification);
  }
};

export const unregisterCordovaPush = () => {
  if (window.cordova) {
    window.plugins.OneSignal.getDeviceState(unregisterOneSignalNotification);
  }
};

const registerOneSignalNotification = status => {
  const deviceId = status?.userId;

  if (
    deviceId &&
    (status?.notificationPermissionStatus === 2 ||
      status?.hasNotificationPermission)
  ) {
    axios
      .post(
        `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/users/register_ios_push`,
        {
          one_signal_id: deviceId
        }
      )
      .then(() => {
        checkPushStatus(deviceId);
      });
  }
};

const unregisterOneSignalNotification = status => {
  axios.post(
    `${import.meta.env.VITE_SERVER_PATH}/private/v1/users/unregister_ios_push`,
    {
      one_signal_id: status?.userId
    }
  );
};
