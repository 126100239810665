import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import Div100vh from 'react-div-100vh';

import DesktopBanner from '../ui/DesktopBanner';
import MobileBanner from '../ui/MobileBanner';
import DialogUnavailableTile from '../ui/DialogUnavailableTile';
import HelpButton from '../../ui/HelpButton';
import { getCreditsRemaining } from '../../../actions/credits';
import { getAuthToken } from '../../../libraries/authentication';

import DiscoveryJobTiles from '../ui/DiscoveryJobTiles';

const DiscoverWrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
`;
const Body = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 32px;
`;
const IpadWrapper = styled.div`
  width: 500px;
`;
const DesktopWrapper = styled.div`
  display: flex;
`;
const RightWrapper = styled.div`
  flex: 1;
`;
const LeftWrapper = styled.div`
  width: 648px;
`;

const DesktopDiscover = props => {
  const {
    isBusinessAccount,
    isTablet,
    desktopLayout,
    open,
    onClose,
    onClickBA,
    onClickPartTimerUnavailable,
    onClickPersonalHelperUnavailable,
    jobType
  } = props;

  useEffect(() => {
    const { authUser, isBusinessAccount, getCreditsRemaining } = props;

    if (getAuthToken()) {
      getCreditsRemaining();
    }

    if (authUser && window.trackingPermitted) {
      window.analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
        phone_num: authUser.phone_num,
        user_type: isBusinessAccount ? 'business_account' : 'free_account',
        signup_at: authUser.created_at,
        admin: authUser.is_admin,
        roles: authUser.roles,
        signup_method: authUser.signup_method
      });
    }
  }, []);

  return (
    <>
      <DiscoverWrapper>
        <Body ipadLayout={isTablet}>
          {isTablet ? (
            <IpadWrapper>
              <MobileBanner isBusinessAccount={isBusinessAccount} />
              <DiscoveryJobTiles
                isMobile={!desktopLayout}
                isTablet={isTablet}
              />
              <HelpButton desktopLayout />
            </IpadWrapper>
          ) : (
            <DesktopWrapper>
              <LeftWrapper>
                <DiscoveryJobTiles
                  isMobile={!desktopLayout}
                  isTablet={isTablet}
                />
              </LeftWrapper>
              <RightWrapper>
                <DesktopBanner
                  ipadLayout={isTablet}
                  isBusinessAccount={isBusinessAccount}
                />
                <HelpButton desktopLayout />
              </RightWrapper>
            </DesktopWrapper>
          )}
        </Body>
      </DiscoverWrapper>
      <DialogUnavailableTile
        open={open}
        onClose={onClose}
        onClickBA={onClickBA}
        onClickPartTimerUnavailable={onClickPartTimerUnavailable}
        onClickPersonalHelperUnavailable={onClickPersonalHelperUnavailable}
        jobType={jobType}
        isBusinessAccount={isBusinessAccount}
      />
    </>
  );
};

const mapStateToProps = state => ({
  desktopLayout: state.layout?.desktopLayout,
  isTablet: state.layout?.isTablet
});

const mapDispatchToProps = dispatch => ({
  getCreditsRemaining: () => dispatch(getCreditsRemaining())
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(DesktopDiscover)
);
