import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';

import GoGetRunningMan from '../icons/DefaultGoGetter.svg';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

import { clickNotification } from '../../../libraries/notification';
import { readNotification } from '../../../actions/notification';

const StyledCard = styled(ButtonBase)`
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${colors.greyLight};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  background-color: ${({ isRead }) => !isRead && colors.greyLight};

  :hover {
    background-color: ${colors.hoverGrey};
    border-color: ${colors.hoverGrey};
  }
`;
const CardContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
const TextContent = styled.div`
  flex: 1;
  text-align: left;
  overflow: hidden;
`;
const NewBadge = styled.div`
  padding: 0.25rem;
  width: min-content;
  background-color: ${colors.primary};
  font-size: ${fontSize.small};
  color: ${colors.white};
  border-radius: 4px;
  text-align: center;
  margin-bottom: 0.5rem;
`;
const NotificationFrom = styled.div`
  font-size: ${fontSize.large};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;
const NotificationContent = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.greyDark};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  display: -webkit-box;
  line-height: 21px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const DateWrapper = styled.div`
  font-size: ${fontSize.small};
  color: ${colors.black};
  text-align: right;
  display: ${props => (props.isInAppNotification ? 'none' : 'flex')};
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
`;

const NotificationBox = props => {
  const {
    notifiableId,
    action,
    notificationId,
    read,
    avatar,
    createdAt,
    content,
    title,
    isInAppNotification
  } = props;

  const dispatch = useDispatch();

  const isLessThanOneDay = moment().diff(createdAt, 'hours') < 24;

  const handleNotificationClick = () => {
    clickNotification({
      notificationId,
      notifiableId,
      action,
      read,
      push: id => dispatch(push(id)),
      readNotification: id => dispatch(readNotification(id))
    });
  };

  return (
    <StyledCard
      isRead={read}
      onClick={handleNotificationClick}
      isInAppNotification={isInAppNotification}
    >
      <CardContent>
        <Avatar src={avatar || GoGetRunningMan} />
        <TextContent>
          <NotificationFrom>{title}</NotificationFrom>
          <NotificationContent>{content}</NotificationContent>
        </TextContent>
        <DateWrapper isInAppNotification={isInAppNotification}>
          {!read && !isInAppNotification && isLessThanOneDay && (
            <NewBadge>NEW</NewBadge>
          )}
          <div>{createdAt ? moment(createdAt).format('DD MMM') : ''}</div>
          <div>{createdAt ? moment(createdAt).format('hh:mm A') : ''}</div>
        </DateWrapper>
      </CardContent>
    </StyledCard>
  );
};

export default NotificationBox;
