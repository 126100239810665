import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Button from '@mui/material/Button';
import Div100vh from 'react-div-100vh';
import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import FixedHeaderAppBar from './FixedHeaderAppBar';
import GoGetLogo from '../assets/black_logo.png';

import { useTranslation } from 'react-i18next';

const Container = styled(Div100vh)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;
const Card = styled.div`
  color: ${colors.greyDark};
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
`;
const Img = styled.img`
  width: 100px;
  margin: 0 auto;
  display: block;
`;
const Numbers = styled.div`
  font-size: 96px;
  color: ${colors.primary};
`;
const Message = styled.div`
  font-size: ${fontSize.xLarge};
  margin-top: 16px;
`;
const FinePrint = styled.div`
  font-size: ${fontSize.large};
  margin-top: 8px;
  margin-bottom: 36px;
`;
const StyledButton = styled(Button)`
  width: calc(100% - 8rem);
  color: ${colors.white} !important;
`;

export const HOME_BUTTON = '404-home-button';

const Page404 = props => {
  const { history, desktopLayout } = props;

  const { t } = useTranslation();

  useEffect(() => {
    window.analyticsPage('page404', { platform: 'pwa' });
  }, []);

  const onBack = () => {
    history.goBack();
  };

  const onHome = () => {
    history.push('/');
  };

  return (
    <>
      <FixedHeaderAppBar
        showWallet
        isMobile={!desktopLayout}
        onBackClick={onBack}
      />
      <Container style={{ height: 'calc(100rvh - 68px)' }}>
        <Card>
          <Img src={GoGetLogo} />
          <Numbers>404</Numbers>
          <Message>{t('page404.title')}</Message>
          <FinePrint>{t('page404.description')}</FinePrint>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={onHome}
            data-testid={HOME_BUTTON}
          >
            {t('page404.buttonText')}
          </StyledButton>
        </Card>
      </Container>
    </>
  );
};

export default Page404;
