import React from 'react';
import styled from 'styled-components/macro';

import BulkOrder from '../../assets/bulkUpload.svg';
import ManageBulkUpload from '../../assets/manageBulkUpload.svg';

import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import Close from '@mui/icons-material/Close';

import DialogDrawer from '../../ui/DialogDrawer';

import fontSize from '../../../style/fontSize';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderText = styled.div`
  font-size: ${fontSize.xLarge};
`;
const StyledClose = styled(Close)`
  cursor: pointer;
`;
const StyledDivider = styled(Divider)`
  margin: 1rem 0;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 0;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IconButton = styled(Card)`
  box-sizing: border-box;
  width: 125px;
  height: 125px;
`;
const StyledButton = styled(CardActionArea)`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Icon = styled.img`
  width: 100%;
`;
const IconButtonLabel = styled.div`
  margin-top: 1rem;
  font-size: ${fontSize.large};
`;

export const CLOSE_BUTTON_TEST_ID = 'close-button';
export const CREATE_BUTTON_TEST_ID = 'create-button';
export const MANAGE_BUTTON_TEST_ID = 'manage-button';

const BulkDialogDrawer = props => {
  const {
    isOpen,
    onClickCreate,
    onClickManage,
    handleClose,
    handleOpen,
    desktopLayout
  } = props;

  return (
    <DialogDrawer
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      maxWidth="sm"
      desktop={desktopLayout}
    >
      <Header>
        <HeaderText>Bulk Order</HeaderText>
        <StyledClose onClick={handleClose} data-testid={CLOSE_BUTTON_TEST_ID} />
      </Header>
      <StyledDivider light />
      <Content>
        <ButtonContainer>
          <IconButton>
            <StyledButton
              onClick={onClickCreate}
              data-testid={CREATE_BUTTON_TEST_ID}
            >
              <Icon src={BulkOrder} alt="Create Bulk" />
            </StyledButton>
          </IconButton>
          <IconButtonLabel>Create</IconButtonLabel>
        </ButtonContainer>
        <ButtonContainer>
          <IconButton>
            <StyledButton
              onClick={onClickManage}
              data-testid={MANAGE_BUTTON_TEST_ID}
            >
              <Icon src={ManageBulkUpload} alt="Manage Bulk" />
            </StyledButton>
          </IconButton>
          <IconButtonLabel>Manage</IconButtonLabel>
        </ButtonContainer>
      </Content>
    </DialogDrawer>
  );
};

export default BulkDialogDrawer;
