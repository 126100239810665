import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConnectedRouter } from 'connected-react-router';
import { getUrlParameter } from './libraries/ApplicationService';
import 'normalize.css/normalize.css';
import 'leaflet/dist/leaflet.css';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import axiosInterceptors from './services/axios/interceptors';
import AppOpenAnalytics from './components/AppOpenAnalytics';
import AppTrackingHandler from './components/AppTrackingHandler';
import CurrentLocationTracker from './components/CurrentLocationTracker';
import ForceUpdate from './components/ForceUpdate';
import NotificationReceiver from './components/notification/NotificationReceiver';
import ManageUrlParams from './components/ManageUrlParams';
import UpdateSnackbar from './components/UpdateSnackbar';
import Routers from './Routers';
import IconButton from '@mui/material/IconButton';
import configureStore, { history } from './store/configureStore';
import './style/App.scss';
import colors from './style/colors';
import NewReferralIntroDialog from './components/ui/NewReferralIntroDialog';
import WindowSizeHandler from './components/WindowSizeHandler';
import CreditUpdatePusher from './libraries/CreditUpdatePusher';
import ReduxVersionManager from './libraries/ReduxVersionManager';
import { setCookie } from './libraries/authentication';

const { persistor, store } = configureStore();

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white
    },
    error: {
      main: colors.red,
      contrastText: colors.white
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ipad: 750,
      md: 960,
      desktop: 1200,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Open Sans', sans-serif",
    h5: {
      fontWeight: 700,
      fontSize: '1rem'
    },
    h6: {
      fontWeight: 700,
      fontSize: '0.875rem'
    }
  }
});

const styles = {
  root: {
    top: '0 !important'
  }
};

axiosInterceptors.setupInterceptors(store, history);
class App extends Component {
  constructor(props) {
    super(props);

    let mobile = true;
    if (
      window.cordova &&
      window.device &&
      window.device.model &&
      window.device.model.toLowerCase().includes('ipad')
    ) {
      mobile = false;
    }
    this.state = {
      mobile,
      isTabletBrowser: false
    };
  }

  componentDidMount() {
    if (!window.cordova) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    const utmSource = getUrlParameter(history?.location, 'utm_source');
    const utmedium = getUrlParameter(history?.location, 'utm_medium');
    const utmCampaign = getUrlParameter(history?.location, 'utm_campaign');

    if (utmSource || utmedium || utmCampaign) {
      setCookie('utm_source', utmSource);
      setCookie('utm_medium', utmedium);
      setCookie('utm_campaign', utmCampaign);
    }
  }

  componentWillUnmount() {
    if (!window.cordova) {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  }

  updateWindowDimensions = () => {
    const { mobile } = this.state;
    if (window.innerWidth <= 750 && !mobile) {
      this.setState({ mobile: true });
    } else if (window.innerWidth > 750 && mobile) {
      this.setState({ mobile: false });
    }

    if (window.innerWidth > 1024) {
      this.setState({ isTabletBrowser: false });
    } else {
      this.setState({ isTabletBrowser: true });
    }
  };

  render() {
    const { mobile, isTabletBrowser } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store} context={ReactReduxContext}>
          <PersistGate loading={null} persistor={persistor}>
            <WindowSizeHandler>
              <AppTrackingHandler>
                <>
                  <SnackbarProvider
                    action={
                      <IconButton onClick={() => closeSnackbar()} size="small">
                        <CloseIcon sx={{ color: 'white' }} />
                      </IconButton>
                    }
                    autoHideDuration={5000}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    preventDuplicate
                  />
                  <NewReferralIntroDialog />
                  <ManageUrlParams history={history} />
                  <UpdateSnackbar />
                  <CurrentLocationTracker />
                  <NotificationReceiver mobile={mobile} history={history} />
                  <CreditUpdatePusher />
                  <AppOpenAnalytics />
                  <ReduxVersionManager />
                  <ForceUpdate />
                  <ConnectedRouter
                    history={history}
                    context={ReactReduxContext}
                  >
                    <LocalizationProvider utils={DateFnsUtils}>
                      <Routers
                        mobile={mobile}
                        isTabletBrowser={isTabletBrowser}
                        history={history}
                      />
                    </LocalizationProvider>
                  </ConnectedRouter>
                </>
              </AppTrackingHandler>
            </WindowSizeHandler>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
