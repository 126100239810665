import { enqueueSnackbar } from 'notistack';
import { ROUTING_SETTING_CONFIG } from '../../components/maps/constants';
import { routeToLocation } from '../../api/routing';
import { generateShapeFromLegs } from './generateShapeFromLegs';
import { isValidRoute } from './isValidRoute';

const KM_TO_M_MULTIPLIER = 1000;

class NewShortestRouteService {
  constructor({
    dispatch,
    locations,
    updateOriginalTaskAndRoute,
    updateTaskAndRoute,
    setShortestRouting,
    updateDistance,
    getState,
    editTask,
    getFee,
    rideId,
    updateRoute,
    job,
    route,
    calculateTimeRange,
    setNonFlexiTasks
  }) {
    this.dispatch = dispatch;
    this.locations = locations;
    this.updateOriginalTaskAndRoute = updateOriginalTaskAndRoute;
    this.updateTaskAndRoute = updateTaskAndRoute;
    this.setShortestRouting = setShortestRouting;
    this.updateDistance = updateDistance;
    this.getState = getState;
    this.editTask = editTask;
    this.getFee = getFee;
    this.rideId = rideId;
    this.updateRoute = updateRoute;
    this.job = job;
    this.route = route;
    this.calculateTimeRange = calculateTimeRange;
    this.setNonFlexiTasks = setNonFlexiTasks;
    this.state = {
      permutations: [],
      distances: [],
      shortestRoute: [],
      shortestPermutation: [],
      originalTasks: [],
      originalRoute: []
    };
  }

  getRoute = () => {
    if (this.route?.shape?.length > 1) {
      const { permutations, distances, originalTasks, originalRoute } =
        this.state;

      this.dispatch(this.setShortestRouting(true));

      // function to get route combinations
      const permutator = length => {
        const firstOrder = [];
        for (let i = 0; i < length; i++) {
          firstOrder.push(i);
        }
        const permute = (array, taskOrder = []) => {
          if (array.length === 0 && taskOrder[0] === 0) {
            permutations.push(taskOrder);
          } else {
            for (let j = 0; j < array.length; j++) {
              const current = array.slice();
              const next = current.splice(j, 1);
              permute(current.slice(), taskOrder.concat(next));
            }
          }
        };
        permute(firstOrder);
      };

      // Save original tasks and route for Undo cases
      originalTasks.push(this.job.tasks);
      originalRoute.push(this.route);
      distances.push(this.route?.summary?.length * KM_TO_M_MULTIPLIER);

      permutator(this.locations?.length);

      const requestObject = {
        locations: [],
        ...ROUTING_SETTING_CONFIG
      };

      permutations?.forEach((permutation, index) => {
        // Ignore the first permutation as it will always be the original
        // routing that was set by the poster
        if (index !== 0) {
          const mappedLocations = permutation.map(taskOrder => ({
            lat: this.locations?.[taskOrder]?.location_lat,
            lon: this.locations?.[taskOrder]?.location_long
          }));

          requestObject.locations = mappedLocations;

          const isRoutable = isValidRoute(requestObject.locations);

          if (!isRoutable) return;

          this.routeWithValhalla(requestObject, permutation);
        }
      });
    }
  };

  routeWithValhalla = async (params, permutation) => {
    try {
      const json = encodeURI(JSON.stringify(params));

      const response = await routeToLocation(json);

      if (response?.isSuccess && response?.data) {
        this.onRouteResult(response?.data, permutation);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  };

  onRouteResult = async (result, permutation) => {
    if (result?.trip) {
      const {
        distances,
        shortestRoute,
        shortestPermutation,
        permutations,
        originalTasks,
        originalRoute
      } = this.state;
      const newRoute = result.trip;
      newRoute.shape = generateShapeFromLegs(newRoute?.legs);
      const currentDistance = newRoute.summary?.length * KM_TO_M_MULTIPLIER;
      const originalDistance = distances[0];
      const originalPermutation = permutations[0];

      if (currentDistance) {
        distances.push(currentDistance);
      }

      const minDistance = Math.min(...distances);

      if (
        currentDistance === minDistance &&
        currentDistance !== originalDistance
      ) {
        shortestRoute.push(newRoute);
        shortestPermutation.push(permutation);
      } else if (originalDistance === minDistance) {
        shortestRoute.push(originalRoute[0]);
        shortestPermutation.push(originalPermutation);
      }

      if (distances.length === permutations.length) {
        if (
          shortestPermutation[0] !== originalPermutation &&
          (currentDistance !== 0 ||
            newRoute.legs[1].summary.length * KM_TO_M_MULTIPLIER !== 0 ||
            newRoute.legs[2].summary.length * KM_TO_M_MULTIPLIER !== 0)
        ) {
          await shortestPermutation[0].map(async (order, index) => {
            const { tasks } = this.job;
            if (order > 0) {
              await this.dispatch(
                this.editTask({
                  order: index,
                  updates: {
                    location: tasks?.[order]?.location,
                    location_lat: tasks?.[order]?.location_lat,
                    location_long: tasks?.[order]?.location_long,
                    parking: tasks?.[order]?.parking,
                    edo: tasks?.[order]?.edo,
                    recipient_name: tasks?.[order]?.recipient_name,
                    recipient_phone_num: tasks?.[order]?.recipient_phone_num,
                    sender_name: tasks?.[order]?.sender_name,
                    sender_email: tasks?.[order]?.sender_email,
                    location_notes: tasks?.[order]?.location_notes,
                    reference: tasks?.[order]?.reference
                  }
                })
              );
            }
            await this.dispatch(this.setNonFlexiTasks());
            return true;
          });
          await this.dispatch(this.updateRoute(shortestRoute[0]));
          await this.dispatch(this.updateDistance(minDistance));
          await this.dispatch(
            this.calculateTimeRange({
              locations: this.locations,
              route: shortestRoute[0],
              serviceType: this.job.service_type
            })
          );
          await this.dispatch(this.getFee());
          enqueueSnackbar(
            'Successfully optimised route to shortest distance.',
            {
              variant: 'success'
            }
          );
          await this.dispatch(
            this.updateOriginalTaskAndRoute({
              tasks: originalTasks[0],
              route: originalRoute[0]
            })
          );
          await this.dispatch(this.setShortestRouting(false));
        } else {
          enqueueSnackbar('This route is already the shortest distance.', {
            variant: 'success'
          });

          await this.dispatch(this.setShortestRouting(false));
        }
      }
    }
  };
}

export default NewShortestRouteService;
