import { createSlice } from '@reduxjs/toolkit';
import { getListofApplicants } from '../api/hiringPost';

export const initialState = {
  applications: []
};

const hiringPostApplicantsReducer = createSlice({
  name: 'hiringPostApplicants',
  initialState,
  reducers: {
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
    clearApplications: state => {
      state.applications = [];
    }
  }
});

export const fetchApplicants = jobId => {
  return async dispatch => {
    const data = await getListofApplicants(jobId);
    if (data?.isSuccess) {
      dispatch(setApplications(data?.applications));
    }
  };
};

export const { setApplications, clearApplications } =
  hiringPostApplicantsReducer.actions;

export default hiringPostApplicantsReducer.reducer;
