import React, { Suspense } from 'react';
import styled from 'styled-components/macro';
import { Switch as ReactSwitch } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

import { isTablet } from 'react-device-detect';

import DesktopLayout from './components/desktopLayout/DesktopLayout';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import PageLoadingBar from './components/ui/PageLoadingBar';
import ScrollToTop from './components/ScrollToTop';
import LoadingData from './components/loadingData/LoadingData';

import Discover from './components/discover/Discover';
import EditPhoneAndEmail from './components/auth/EditPhoneAndEmail';
import Page404 from './components/ui/Page404';
import PhoneVerification from './components/auth/PhoneVerification';

import { getAuthToken, getCookie } from './libraries/authentication';

import { privateRouters, authRouters } from './services/routers';
import { publicRouters } from './services/routers/publicRouters';
import { SentryRoute } from './services/sentry';

const ViewWrap = styled.div`
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(100% + var(--safe-area-inset-bottom));
`;

const PrivateRoute = ({
  component: Component,
  componentProps: ComponentProps,
  ...rest
}) => {
  return (
    <SentryRoute
      {...rest}
      render={routeProps =>
        getAuthToken() !== null &&
        getCookie('user-phone-verified') === 'true' ? (
          <Component {...routeProps} {...ComponentProps} />
        ) : window.localStorage.getItem('loading-localStorage') === 'true' ? (
          <LoadingData />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );
};

const AuthRoute = ({
  component: Component,
  componentProps: ComponentProps,
  ...rest
}) => (
  <SentryRoute
    {...rest}
    render={props =>
      getAuthToken() === null ? (
        window.localStorage.getItem('loading-localStorage') === 'true' ? (
          <LoadingData />
        ) : (
          <Component {...props} {...ComponentProps} />
        )
      ) : getCookie('user-phone-verified') === 'true' ? (
        <Redirect to="/" />
      ) : (
        <Redirect to="/phone_verification" />
      )
    }
  />
);

const PhoneVerificationRoute = ({
  component: Component,
  componentProps: ComponentProps,
  ...rest
}) => (
  <SentryRoute
    {...rest}
    render={props =>
      getAuthToken() === null ? (
        window.localStorage.getItem('loading-localStorage') === 'true' ? (
          <LoadingData />
        ) : (
          <Redirect to="/login" />
        )
      ) : getCookie('user-phone-verified') === 'true' ? (
        <Redirect to="/" />
      ) : window.localStorage.getItem('loading-localStorage') === 'true' ? (
        <LoadingData />
      ) : (
        <Component {...props} {...ComponentProps} />
      )
    }
  />
);

const Routers = props => {
  const { mobile, isTabletBrowser } = props;

  return (
    <ViewWrap>
      <DesktopLayout isMobile={mobile}>
        <ErrorBoundary desktopLayout={!mobile}>
          <Suspense fallback={<PageLoadingBar />}>
            <ScrollToTop />
            <ReactSwitch>
              <Switch>
                <SentryRoute
                  path="/"
                  render={routeProps => (
                    <Discover {...routeProps} desktopLayout={!mobile} />
                  )}
                  exact
                />
                <SentryRoute
                  path="/edit_phone_and_email"
                  render={routeProps => (
                    <EditPhoneAndEmail
                      {...routeProps}
                      desktopLayout={!mobile}
                    />
                  )}
                  exact
                />
                <PhoneVerificationRoute
                  path="/phone_verification"
                  component={PhoneVerification}
                  componentProps={{ desktopLayout: !mobile }}
                  exact
                />
                {publicRouters.map(({ path, component: Component }) => (
                  <SentryRoute
                    key={path}
                    path={path}
                    render={routeProps => (
                      <Component {...routeProps} desktopLayout={!mobile} />
                    )}
                    exact
                  />
                ))}
                {privateRouters.map(privateRouter => (
                  <PrivateRoute
                    key={privateRouter.path}
                    path={privateRouter.path}
                    component={
                      (mobile || isTablet || isTabletBrowser) &&
                      privateRouter?.mobileComponent
                        ? privateRouter?.mobileComponent
                        : privateRouter.component
                    }
                    componentProps={{ desktopLayout: !mobile, isTablet }}
                    exact
                  />
                ))}
                {authRouters.map(authRouter => (
                  <AuthRoute
                    key={authRouter.path}
                    path={authRouter.path}
                    component={authRouter.component}
                    componentProps={{ desktopLayout: !mobile }}
                    exact
                  />
                ))}
                <SentryRoute
                  render={routeProps => (
                    <Page404 {...routeProps} desktopLayout={!mobile} />
                  )}
                />
              </Switch>
            </ReactSwitch>
          </Suspense>
        </ErrorBoundary>
      </DesktopLayout>
    </ViewWrap>
  );
};

export default Routers;
