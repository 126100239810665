import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';

import { initPusher } from './authentication';
import { updateCredits } from '../actions/credits';

const CreditUpdatePusher = props => {
  const { updateCredits } = props;

  const { channel, pusher } = initPusher(props?.userId);

  useEffect(() => {
    Pusher.logToConsole =
      import.meta.env.VITE_SERVER_TYPE_EXT === 'develop' &&
      import.meta.env.VITE_SHOW_PUSHER_LOG === 'true';

    channel?.bind('credits_remaining', data => {
      updateCredits(
        data?.data?.credits_remaining,
        data?.data?.credits_expiration
      );
    });
    return () => {
      pusher?.unsubscribe(`presence-channel-${props?.userId}`);
    };
  });

  return <></>;
};

const mapStateToProps = state => ({
  userId: state.user?.profile?.id
});

const mapDispatchToProps = dispatch => ({
  updateCredits: (creditRemaining, creditExpiry) =>
    dispatch(updateCredits(creditRemaining, creditExpiry))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditUpdatePusher);
