import { makeStyles } from '@mui/styles';
import React from 'react';
import colors from '../../style/colors';

const useStyles = makeStyles(() => ({
  root: {
    display: props => (props.open ? 'block' : 'none'),
    position: props => (props.positionAbsolute ? 'absolute' : 'relative'),
    padding: '8px',
    margin: '8px',
    borderRadius: '8px',
    lineHeight: '20px',
    color: colors.white,
    background: colors.blue,
    zIndex: 999,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '0',
      height: '0',
      borderWidth: '10px',
      borderStyle: 'solid',
      top: '-20px',
      left: '40px',
      borderColor: `transparent transparent ${colors.blue} transparent`
    }
  }
}));

const GGPopover = ({ render, open, style, positionAbsolute = true }) => {
  const classes = useStyles({ open, positionAbsolute });

  return (
    <div className={classes.root} style={style}>
      <div>{render()}</div>
    </div>
  );
};

export default GGPopover;
