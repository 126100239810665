export const SERVER_URL = import.meta.env.VITE_SERVER_PATH;
export const FESTIVE = 'festive';

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_FORBIDDEN = 403;

export const failedResponse = responseData => {
  return {
    ...responseData,
    isSuccess: false
  };
};

export const successResponse = responseData => {
  return {
    ...responseData,
    isSuccess: true
  };
};
