import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateDesktopLayout,
  updateTabletLayout
} from '../reducers/layoutReducer';

// Had to create this wrapper as store wasn't available in App.js as thats where it gets injected,
// so trying to call on the dispatch method will throw an error that store is not available.

const WindowSizeHandler = ({ children }) => {
  const [mobile, setMobile] = useState(true);

  const dispatch = useDispatch();

  const updateWindowDimensions = () => {
    if (window.innerWidth <= 750 && !mobile) {
      dispatch(updateDesktopLayout(false));
      setMobile(true);
    } else if (window.innerWidth > 750 && mobile) {
      dispatch(updateDesktopLayout(true));
      setMobile(false);
    }

    // Handle Tablet View
    if (window.innerWidth > 1024) {
      dispatch(updateTabletLayout(false));
    } else {
      dispatch(updateTabletLayout(true));
    }
  };

  useEffect(() => {
    if (
      window.cordova &&
      window.device &&
      window.device.model &&
      window.device.model.toLowerCase().includes('ipad')
    ) {
      dispatch(updateTabletLayout(true));
      setMobile(false);
    }

    if (!window.cordova) {
      updateWindowDimensions();
      window.addEventListener('resize', updateWindowDimensions);
    }

    return function cleanup() {
      if (!window.cordova) {
        window.removeEventListener('resize', updateWindowDimensions);
      }
    };
  }, [mobile]);

  return <>{children}</>;
};

export default WindowSizeHandler;
