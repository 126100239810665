import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Div100vh from 'react-div-100vh';
import styled from 'styled-components/macro';
import { isEmail, isMobilePhone } from 'validator';
import _ from 'lodash';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import CtaButton from '../ui/CtaButton';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import NatureOfUsageToggle from '../ui/NatureOfUsageToggle';

import {
  getAuthToken,
  getCookie,
  logout
} from '../../libraries/authentication';
import { industries } from '../kyc/constants/kycServices';

import { updatePhoneAndEmailAndResendCode } from '../../actions/auth';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const TextFieldWrapper = styled.div`
  margin-top: 24px;
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;
const Title = styled.div`
  font-size: ${fontSize.xxLarge};
  font-weight: 600;
  margin: 1rem 0;
`;
const FormTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
`;

const EditPhone = props => {
  const { updatePhoneAndEmail, history, desktopLayout, profile } = props;
  const [userDetails, setUserDetails] = useState({
    phoneNum:
      getCookie('user-phone-number') &&
      getCookie('user-phone-number').includes('null')
        ? '60'
        : getCookie('user-phone-number'),
    email: profile?.email,
    companyName: '',
    industry: '',
    natureOfUsage: 'personal',
    referralCode: ''
  });

  useEffect(() => {
    if (!getAuthToken()) {
      history.push('/home');
    }
  }, []);

  const handleOnChange = e => {
    const newDetails = { ...userDetails };

    newDetails[e.target.name] = e.target.value;

    setUserDetails(newDetails);
  };

  const handleClick = async () => {
    const { email, phoneNum } = userDetails;

    if (email && phoneNum) {
      await updatePhoneAndEmail(userDetails);
    } else if (phoneNum) {
      await updatePhoneAndEmail(userDetails);
    }
  };

  const handleNatureOfUsage = value => {
    const newDetails = { ...userDetails };

    newDetails.natureOfUsage = value;

    setUserDetails(newDetails);
  };

  const onBack = () => {
    if (_.isNil(getCookie('user-phone-number'))) {
      logout();
      history.push('/home');
    } else {
      history.push('/phone_verification');
    }
  };

  const emailValid = !userDetails.email || isEmail(userDetails.email);
  const phoneValid =
    userDetails.phoneNum && isMobilePhone(userDetails.phoneNum, ['ms-MY']);
  const businessDetailsValid = userDetails.companyName && userDetails.industry;
  const hasNatureOfUsage =
    profile?.nature_of_usage === 'personal' ||
    profile?.nature_of_usage === 'business';

  return (
    <>
      <FixedHeaderAppBar
        isMobile={!desktopLayout}
        showWallet={desktopLayout}
        title="SMS verification"
        onBackClick={onBack}
      />
      <Container
        desktopLayout={desktopLayout}
        style={{ height: 'calc(100rvh - 64px)' }}
      >
        <Card desktopLayout={desktopLayout}>
          <div>
            <Title>You're almost there!</Title>
            {!hasNatureOfUsage && (
              <>
                <TextFieldWrapper>
                  <FormTitle>Select your nature of usage:</FormTitle>
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <NatureOfUsageToggle
                    desktopLayout={desktopLayout}
                    natureOfUsage={userDetails.natureOfUsage}
                    handleButton={handleNatureOfUsage}
                  />
                </TextFieldWrapper>
              </>
            )}
            <TextFieldWrapper>
              <FormTitle>Contact details:</FormTitle>
            </TextFieldWrapper>
            <TextFieldWrapper>
              <TextField
                name="phoneNum"
                type="tel"
                onChange={e => handleOnChange(e)}
                label="Phone No."
                value={userDetails.phoneNum}
                margin="normal"
                variant="outlined"
                fullWidth
                helperText={!phoneValid && 'Please enter a valid phone number.'}
                error={!phoneValid}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </TextFieldWrapper>
            {window.localStorage.getItem('user-email-address') &&
              window.localStorage
                .getItem('user-email-address')
                .includes('change@me') && (
                <TextFieldWrapper>
                  <TextField
                    name="email"
                    onChange={e => handleOnChange(e)}
                    label="Email"
                    margin="normal"
                    value={userDetails.email}
                    variant="outlined"
                    fullWidth
                    helperText={
                      !emailValid && 'Please enter a valid email address.'
                    }
                    error={!emailValid}
                  />
                </TextFieldWrapper>
              )}
            <TextField
              name="referralCode"
              onChange={e => handleOnChange(e)}
              label="Referral Code (Optional)"
              margin="normal"
              value={userDetails.referralCode}
              variant="outlined"
              fullWidth
            />
            {userDetails.natureOfUsage === 'business' && (
              <>
                <TextFieldWrapper>
                  <FormTitle>Business details:</FormTitle>
                </TextFieldWrapper>
                <TextField
                  name="companyName"
                  onChange={e => handleOnChange(e)}
                  label="Company Name"
                  margin="normal"
                  value={userDetails.companyName}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  select
                  name="industry"
                  onChange={e => handleOnChange(e)}
                  label="Industry"
                  margin="normal"
                  value={userDetails.industry}
                  variant="outlined"
                  fullWidth
                >
                  {industries.map(industry => {
                    return (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          </div>
          <BtnWrapper>
            <CtaButton
              disabled={
                !phoneValid ||
                (userDetails.natureOfUsage === 'business' &&
                  !businessDetailsValid)
              }
              onClick={handleClick}
            >
              CONTINUE
            </CtaButton>
          </BtnWrapper>
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    profile: state?.user?.profile
  };
};

const mapDispatchToProps = dispatch => ({
  updatePhoneAndEmail: userInfo =>
    dispatch(updatePhoneAndEmailAndResendCode(userInfo)),
  push: () => dispatch(push())
});
export default connect(mapStateToProps, mapDispatchToProps)(EditPhone);
