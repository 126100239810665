import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';

import LinearProgress from '@mui/material/LinearProgress';

const Wrapper = styled.div``;
const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`;
const ScrollContainer = styled.div`
  padding: 0.25rem 1rem 0.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(5.5rem, 100%), 1fr));

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      scroll-behavior: smooth;
      padding: 0.25rem 0.5rem;

      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;
const ProgressWrapper = styled.div`
  margin: 0.25rem 0 0.5rem 0;
`;
const ScrollProgress = styled(LinearProgress)`
  && {
    height: 5px;
    width: 50px;
    border-radius: 2.5px;
    margin: 0 auto;
  }
`;

const ScrollableTileContainer = props => {
  const { children, isMobile } = props;
  const [scrollProgress, setScrollProgress] = useState(0);
  const containerRef = useRef(null);

  const handleScrollProgress = () => {
    const scrollWidth = containerRef?.current?.scrollWidth;
    const viewableWidth = containerRef?.current?.offsetWidth;
    const scrollPos = containerRef?.current?.scrollLeft;

    const scrolledPercent = ((viewableWidth + scrollPos) / scrollWidth) * 100;

    setScrollProgress(scrolledPercent);
  };

  useEffect(() => {
    if (containerRef?.current) {
      containerRef?.current?.addEventListener('scroll', () =>
        handleScrollProgress()
      );
      handleScrollProgress();
    }

    return () =>
      containerRef?.current &&
      containerRef?.current?.removeEventListener('scroll', () =>
        handleScrollProgress()
      );
  }, [containerRef]);

  return (
    <Wrapper>
      <Container>
        <ScrollContainer ref={containerRef} isMobile={isMobile}>
          {children}
        </ScrollContainer>
      </Container>
      {isMobile && (
        <ProgressWrapper>
          <ScrollProgress
            variant="determinate"
            color="primary"
            value={scrollProgress}
          />
        </ProgressWrapper>
      )}
    </Wrapper>
  );
};

export default React.memo(ScrollableTileContainer);
