import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

class LastSeenUpdate extends Component {
  componentDidMount() {
    const { userId } = this.props;
    if (
      import.meta.env.VITE_ACTIVITY_LOG_URL &&
      import.meta.env.VITE_ACTIVITY_LOG_KEY &&
      userId
    ) {
      const url = `${import.meta.env.VITE_ACTIVITY_LOG_URL}/v1/users/${userId}`;
      const request = axios({
        method: 'POST',
        url,
        headers: { Authorization: import.meta.env.VITE_ACTIVITY_LOG_KEY }
      });
      request.then(() => {});
    }
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = state => {
  return {
    userId: state.user && state.user.profile && state.user.profile.id
  };
};

export default connect(mapStateToProps)(LastSeenUpdate);
