import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Div100vh from 'react-div-100vh';

import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';

import {
  createPosterReferral,
  PhoneConfirmation,
  resendCode
} from '../../actions/auth';
import { getCookie, logout } from '../../libraries/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const Title = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.greyDark};
  font-weight: 600;
`;
const TextWrapper = styled.div`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Description = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
  line-height: 21px;
`;
const PhoneWrapper = styled(Link)`
  padding-left: 0.5rem;
  color: ${colors.primary};
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-input {
    letter-spacing: 24px;
    text-align: center;
  }
`;

const PhoneVerification = props => {
  const { history, continueCreation, PhoneConfirmation, desktopLayout } = props;

  const timerRef = useRef(null);

  const [confirmCode, setConfirmCode] = useState('');
  const [time, setTime] = useState({});
  const [seconds, setSeconds] = useState(30);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onBack = () => {
    logout();
    history.push('/home');
  };

  const secondsToTime = secs => {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  const countDown = () => {
    // Remove one second, set state so a re-render happens.
    setSeconds(seconds => seconds - 1);

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(timerRef.current);
    }
  };

  const startTimer = () => {
    if (timerRef.current === null && seconds > 0) {
      timerRef.current = setInterval(() => countDown(), 1000);
    }
  };

  const handleChange = e => {
    setConfirmCode(e.target.value);
  };

  useEffect(() => {
    if (confirmCode.length === 6) {
      setIsSubmitting(true);
      PhoneConfirmation({ confirm_code: confirmCode }, phoneVerified => {
        setIsSubmitting(false);
        if (phoneVerified) {
          createPosterReferral();
          if (continueCreation && continueCreation !== undefined) {
            history.push(continueCreation);
          } else {
            history.push('/');
          }
        } else {
          history.push('/phone_verification');
        }
      });
    }
  }, [confirmCode]);

  useEffect(() => {
    setTime(secondsToTime(seconds));

    if (seconds === 0) {
      clearInterval(timerRef.current);
    }
  }, [seconds]);

  useEffect(() => {
    setTime(secondsToTime(30));
    startTimer();
    window.analyticsPage('phone_verification', { platform: 'pwa' });

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  return (
    <>
      <FixedHeaderAppBar
        isMobile={!desktopLayout}
        title="SMS verification"
        onBackClick={onBack}
      />
      <Container
        desktopLayout={desktopLayout}
        style={{ height: 'calc(100rvh - 64px)' }}
      >
        <Card desktopLayout={desktopLayout}>
          <div>
            {getCookie('user-phone-number') ? (
              <Description>
                Enter the code we sent via SMS to your registered phone number:
                <PhoneWrapper to="/edit_phone_and_email">
                  +{getCookie('user-phone-number')}
                </PhoneWrapper>
              </Description>
            ) : (
              onBack()
            )}
            <div id="form">
              <StyledTextField
                id="outlined-bare"
                disabled={isSubmitting}
                margin="normal"
                variant="outlined"
                type="number"
                value={confirmCode}
                onChange={handleChange}
              />
            </div>
          </div>

          {time.s === 0 ? (
            <>
              <TextWrapper>
                <Title>Didn't receive the code?</Title>
              </TextWrapper>
              <Button
                onClick={resendCode}
                style={{ display: 'block', margin: '0 auto' }}
              >
                Resend Code
              </Button>
            </>
          ) : (
            <>
              <TextWrapper>
                <Title>Resend code in {time.s} seconds</Title>
              </TextWrapper>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    continueCreation: state.createJob && state.continueCreation.path
  };
};

export default connect(mapStateToProps, {
  PhoneConfirmation,
  resendCode,
  push,
  createPosterReferral
})(PhoneVerification);
