import axios from 'axios';
import { set } from 'idb-keyval';
import { setCookie, getCookie, getAuthToken } from './authentication';
import { enqueueSnackbar } from 'notistack';

export const clickNotification = ({
  notificationId,
  notifiableId,
  action,
  read,
  push,
  readNotification,
  defaultPath = null
}) => {
  if (!read) {
    readNotification(notificationId);
  }

  let path;
  if (defaultPath) {
    path = defaultPath;
  }

  switch (action) {
    case 'poster_job':
      path = `/jobs/${notifiableId}`;
      break;
    case 'poster_message':
      path = `/jobs/${notifiableId}/messages`;
      break;
    case 'create_job':
      path = '/';
      break;
    case 'poster_content':
      path = `/announcements/${notifiableId}`;
      break;
    case 'poster_credits':
      path = '/credits';
      break;
    case 'referral':
      path = '/referral';
      break;
    case 'hiring_post_applications':
      path = `/hiring_posts/${notifiableId}`;
      break;
    case 'poster_profile':
      path = '/profile';
      break;
    default:
      break;
  }

  if (path) {
    push(path);
  }
};

export const checkPushStatus = deviceId => {
  if (window.cordova) {
    // check push status from server
    axios
      .post(
        `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/users/check_push_status`,
        { one_signal_id: deviceId }
      )
      .then(({ data }) => {
        if (data.data.active) {
          setCookie('pushEnabled', 'true');
        } else {
          setCookie('pushEnabled', 'false');
        }
      });
  } else if (pushCapable()) {
    if (getAuthToken() && Notification.permission === 'granted') {
      navigator.serviceWorker.ready
        .then(swreg => {
          return swreg.pushManager.getSubscription();
        })
        .then(sub => {
          if (sub) {
            const url = `${
              import.meta.env.VITE_SERVER_PATH
            }/private/v1/users/check_push_status`;
            return axios({
              method: 'POST',
              url,
              data: { endpoint: sub.endpoint }
            }).then(({ data }) => {
              if (data && data.data && data.data.active === true) {
                setCookie('pushEnabled', 'true');
              } else {
                setCookie('pushEnabled', 'false');
              }
            });
          }
        });
    } else {
      setCookie('pushEnabled', 'false');
    }
  }
};

export const pushEnabled = () => {
  return getCookie('pushEnabled') && getCookie('pushEnabled') === 'true';
};

export const pushCapable = () => {
  if (window.cordova) {
    return true;
  } else {
    const isSafari =
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0;

    return (
      !isSafari && 'Notification' in window && 'serviceWorker' in navigator
    );
  }
};

export const disablePush = (successFunction = null) => {
  if (window.cordova) {
    window.plugins.OneSignal.getDeviceState(status => {
      const deviceId = status?.userId;
      const url = `${
        import.meta.env.VITE_SERVER_PATH
      }/private/v1/users/toggle_push`;
      axios({
        method: 'POST',
        url,
        data: {
          one_signal_id: deviceId,
          active: false
        }
      }).then(() => {
        setCookie('pushEnabled', 'false');
        if (successFunction) {
          successFunction();
        }
      });
    });
  } else if (pushCapable() && Notification.permission === 'granted') {
    navigator.serviceWorker.ready
      .then(swreg => {
        return swreg.pushManager.getSubscription();
      })
      .then(sub => {
        if (sub) {
          const url = `${
            import.meta.env.VITE_SERVER_PATH
          }/private/v1/users/toggle_push`;
          return axios({
            method: 'POST',
            url,
            data: {
              endpoint: sub.endpoint,
              active: false
            }
          }).then(() => {
            setCookie('pushEnabled', 'false');
            if (successFunction) {
              successFunction();
            }
          });
        }
      });
  }
};

export const enablePush = (successFunction = null) => {
  if (window.cordova) {
    if (getCookie('isOneSignalEnabled') === 'true') {
      window.plugins.OneSignal.getDeviceState(status => {
        const deviceId = status?.userId;
        const url = `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/users/toggle_push`;
        axios({
          method: 'POST',
          url,
          data: {
            one_signal_id: deviceId,
            active: true
          }
        }).then(() => {
          setCookie('pushEnabled', 'true');
          if (successFunction) {
            successFunction();
          }
        });
      });
    } else {
      enqueueSnackbar(
        'It seems you have disabled notifications from system settings. Please enable it there and restart the GoGet application.',
        {
          variant: 'error'
        }
      );
    }
  } else if (pushCapable()) {
    Notification.requestPermission(result => {
      if (result === 'denied') {
        enqueueSnackbar(
          'Notifications have been blocked on your browser. Please allow websites to send push notifications via your browser settings.',
          {
            variant: 'error'
          }
        );
      } else if (result === 'granted') {
        configurePushSub(successFunction);
      }
    });
  }
};

export const initializePwaPush = () => {
  if (
    !window.cordova &&
    pushCapable() &&
    Notification.permission === 'granted'
  ) {
    logInPush();
  }
};

const logInPush = () => {
  let reg;
  navigator.serviceWorker.ready
    .then(swreg => {
      reg = swreg;
      return swreg.pushManager.getSubscription();
    })
    .then(sub => {
      if (sub === null) {
        // Create a new subscription
        const vapidPublicKey = import.meta.env.VITE_VAPID_PUBLIC_KEY;
        const convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidPublicKey
        });
      }
      // We have a subscription
      return sub;
    })
    .then(newSub => {
      if (newSub) {
        const url = `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/users/prepare_push`;
        return axios({
          method: 'POST',
          url,
          data: JSON.stringify(newSub)
        }).then(({ data }) => {
          if (data && data.data && data.data.user_id) {
            set('user-id', data.data.user_id);
            setCookie('pushEnabled', 'true');
          }
        });
      }
    });
};

const configurePushSub = successFunction => {
  let reg;
  navigator.serviceWorker.ready
    .then(swreg => {
      reg = swreg;
      return swreg.pushManager.getSubscription();
    })
    .then(sub => {
      if (sub === null) {
        // Create a new subscription
        const vapidPublicKey = import.meta.env.VITE_VAPID_PUBLIC_KEY;
        const convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidPublicKey
        });
      }
      // We have a subscription
      return sub;
    })
    .then(newSub => {
      if (newSub) {
        const url = `${
          import.meta.env.VITE_SERVER_PATH
        }/private/v1/users/register_push`;
        return axios({
          method: 'POST',
          url,
          data: JSON.stringify(newSub)
        }).then(({ data }) => {
          set('user-id', data.data.user_id);
          setCookie('pushEnabled', 'true');
          if (successFunction) {
            successFunction();
          }
        });
      }
    });
};

const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
