import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import colors from '../../style/colors';
import FreeCreditDisplayIcon from '../assets/freeCreditDisplayIcon.svg';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    backgroundColor: colors.primayBackground,
    display: 'flex',
    justifyContent: 'center'
  },
  img: {
    height: '170px'
  },
  paper: {
    marginLeft: '24px',
    marginRight: '24px',
    width: '100%'
  },
  submitButton: {
    borderRadius: '6px',
    height: '50px'
  },
  circleAlphabet: {
    borderRadius: '50%',
    fontSize: '0.75rem',
    fontWeight: 500,
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    marginRight: theme.spacing(1)
  },
  primaryBackground: {
    backgroundColor: colors.primary
  },
  purpleBackground: {
    backgroundColor: '#674FC0'
  }
}));

const NewReferralIntroDialog = ({ isOpen }) => {
  const [open, setOpen] = useState(isOpen);
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  };

  const shouldDisplay = () => {
    if (!isOpen) return false;
    return isNil(
      window.localStorage.getItem('referral-intro-dialog-displayed')
    );
  };

  useEffect(() => {
    if (shouldDisplay()) {
      setOpen(true);
      window.localStorage.setItem('referral-intro-dialog-displayed', true);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      PaperProps={{
        className: classes.paper
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <Box className={classes.imageContainer}>
        <img
          className={classes.img}
          src={FreeCreditDisplayIcon}
          alt="background"
        />
      </Box>
      <DialogContent>
        <Box mt={3} textAlign="center">
          <Typography variant="h5">Claim your free credits</Typography>
        </Box>
        <Box mt={5} display="flex">
          <Box
            className={clsx(classes.circleAlphabet, classes.primaryBackground)}
          >
            A
          </Box>
          <Box flex={1}>
            <Typography variant="body2">
              Get{' '}
              <Typography component="span" variant="h6">
                RM5
              </Typography>{' '}
              off your first job. When the job is completed, you&apos;ll get
              another RM5 credits.
            </Typography>
          </Box>
        </Box>
        <Box mt={3} display="flex">
          <Box
            className={clsx(classes.circleAlphabet, classes.purpleBackground)}
          >
            B
          </Box>
          <Box flex={1}>
            <Typography variant="body2">
              Get{' '}
              <Typography component="span" variant="h6">
                RM15 - RM100
              </Typography>{' '}
              free credits once you buy a business plan.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexGrow={1} my={4} justifyContent="center">
          <Button
            size="large"
            className={classes.submitButton}
            variant="contained"
            onClick={onClose}
            color="primary"
            autoFocus
          >
            START NOW
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: state.customPopup.newReferralIntroDialogOpen
  };
};

export default connect(mapStateToProps)(NewReferralIntroDialog);
