export const POST_TYPE_PAGE = 'post_type';
export const JOB_DETAILS_PAGE = 'job_details';
export const JOB_SCHEDULE_PAGE = 'schedule_payment';
export const GOGETTER_REQUIREMENTS_PAGE = 'gogetter_requirements';
export const SCREENING_QUESTIONS_PAGE = 'screening_questions';
export const SUMMARY_PAGE = 'summary';

export const EMPLOYMENT_TYPE_FULL_TIME = 'full_time';
export const EMPLOYMENT_TYPE_PART_TIME = 'part_time';
export const EMPLOYMENT_TYPE_CONTRACT = 'contract';

export const SALARY_TYPE_RANGE = 'range';
export const SALARY_TYPE_EXACT = 'exact';

export const SALARY_FREQUENCY_MONTHLY = 'month';
export const SALARY_FREQUENCY_WEEKLY = 'week';
export const SALARY_FREQUENCY_DAILY = 'day';
export const SALARY_FREQUENCY_HOURLY = 'hour';

export const APPLICANT_GENDER_ANYONE = 'anyone';
export const APPLICANT_GENDER_MALE = 'male';
export const APPLICANT_GENDER_FEMALE = 'female';

export const MALAYSIAN_AND_NON_MALAYSIAN = 'all';
export const MALAYSIAN = 'malaysian';
export const MALAYSIAN_TEXT = 'Malaysians only';
export const MALAYSIAN_AND_NON_MALAYSIAN_TEXT = 'Open to all';

export const ACTIVE = 'active';
export const CLOSED = 'closed';

export const MAX_APPLICANT_NUMBER = 10;

export const MAXIMUM_HOURS_PER_WEEK = 168;
export const MAXIMUM_DURATION_MONTHS = 24;
export const MAXIMUM_SALARY_AMOUNT = 10000;

export const APPLICANT_SUITABLE = 'suitable';
export const APPLICANT_MAYBE = 'maybe';
export const APPLICANT_UNSUITABLE = 'not_suitable';
export const APPLICANT_UNLABELLED = 'unlabelled';

export const APPLICANT_SUITABLE_TEXT = 'Suitable';
export const APPLICANT_MAYBE_TEXT = 'Maybe';
export const APPLICANT_UNSUITABLE_TEXT = 'Unsuitable';
export const APPLICANT_UNLABELLED_TEXT = 'Unlabelled';

export const MAX_SCREENING_QUESTIONS = 5;
export const MAX_SCREENING_ANSWERS = 5;
export const SCREENING_QUESTION_CHAR_LIMIT = 150;
export const SCREENING_ANSWER_CHAR_LIMIT = 75;

export const MULTIPLE_CHOICE_QUESTION = 'single';
export const CHECKBOX_QUESTION = 'multiple';
export const OPEN_TEXT_QUESTION = 'open';

export const SALARY_TIMEFRAMES = [
  SALARY_FREQUENCY_MONTHLY,
  SALARY_FREQUENCY_WEEKLY,
  SALARY_FREQUENCY_DAILY,
  SALARY_FREQUENCY_HOURLY
];

export const getSalaryTimelineName = type => {
  if (type === SALARY_FREQUENCY_WEEKLY) return 'week';

  if (type === SALARY_FREQUENCY_DAILY) return 'day';

  if (type === SALARY_FREQUENCY_HOURLY) return 'hour';

  return 'month';
};

export const getEmploymentTypeName = type => {
  if (type === EMPLOYMENT_TYPE_PART_TIME) return 'Part time';

  if (type === EMPLOYMENT_TYPE_CONTRACT) return 'Contract';

  return 'Full time';
};

export const getAgeText = (start, end) => {
  if (!start || !end) return 'Any';

  return `${start} - ${end}`;
};

export const getActivePageName = activePage => {
  switch (activePage) {
    case JOB_DETAILS_PAGE:
      return 'Job Details';
    case JOB_SCHEDULE_PAGE:
      return 'Duration & Salary';
    case GOGETTER_REQUIREMENTS_PAGE:
      return 'GoGetter Requirements';
    case SCREENING_QUESTIONS_PAGE:
      return 'Screening Questions';
    case SUMMARY_PAGE:
      return 'Review';
    default:
      return '';
  }
};

export const getActivePageIndex = (activePage, isEdit) => {
  switch (activePage) {
    case POST_TYPE_PAGE:
      return 0;
    case JOB_DETAILS_PAGE:
      return isEdit ? 0 : 1;
    case JOB_SCHEDULE_PAGE:
      return isEdit ? 1 : 2;
    case GOGETTER_REQUIREMENTS_PAGE:
      return isEdit ? 2 : 3;
    case SCREENING_QUESTIONS_PAGE:
      return isEdit ? 3 : 4;
    case SUMMARY_PAGE:
      return isEdit ? 4 : 5;
    default:
      return 0;
  }
};

export const getSuitabilityText = suitability => {
  switch (suitability) {
    case APPLICANT_SUITABLE:
      return APPLICANT_SUITABLE_TEXT;
    case APPLICANT_MAYBE:
      return APPLICANT_MAYBE_TEXT;
    case APPLICANT_UNSUITABLE:
      return APPLICANT_UNSUITABLE_TEXT;
    default:
      return '-';
  }
};

export const longTermJobTasks = {
  admin_staff: 'Admin Staff',
  caller: 'Caller',
  catering_helper: 'Catering Helper',
  customer_service: 'Customer Service',
  data_entry: 'Data Entry',
  event_crew: 'Event Staff',
  floor_staff: 'Floor Staff',
  florist_assistant: 'Florist Assistant',
  flyering: 'Flyering',
  general_labour: 'General Labour',
  kitchen_helper: 'Kitchen Helper',
  manning_booth: 'Manning Booth',
  bulky: 'Moving Bulky Items',
  salesperson: 'Salesperson',
  picker_and_packer: 'Packer',
  promoter: 'Promoter',
  retail_staff: 'Retail Staff',
  sales_admin: 'Sales Admin',
  stamping: 'Stamping',
  usher: 'Usher',
  waiter_server: 'Waiter Or Server',
  warehouse_worker: 'Warehouse Worker'
};

export const longTermParams = [
  'admin_staff',
  'bulky',
  'caller',
  'catering_helper',
  'customer_service',
  'data_entry',
  'event_crew',
  'floor_staff',
  'florist_assistant',
  'flyering',
  'general_labour',
  'kitchen_helper',
  'manning_booth',
  'salesperson',
  'picker_and_packer',
  'promoter',
  'retail_staff',
  'sales_admin',
  'stamping',
  'usher',
  'waiter_server',
  'warehouse_worker'
];
