import {
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_FULFUILED,
  MARK_AS_READ,
  MARK_ALL_AS_READ
} from '../reducers/constants/notification';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

const ROOT_URL = `${import.meta.env.VITE_SERVER_PATH}`;

const recieveNotification = (json, firstLoad) => ({
  type: FETCH_NOTIFICATION_FULFUILED,
  updatedAt: json.updatedAt,
  notifications: json.notifications,
  unreadActivityCount: json.unread_activity_count,
  unreadCount: json.unread_count,
  unreadGeneralCount: json.unread_general_count,
  firstLoad
});

export function getNotifications(updatedAt) {
  const url = updatedAt
    ? `${ROOT_URL}/private/v1/notifications/?updatedAt=${updatedAt}`
    : `${ROOT_URL}/private/v1/notifications`;
  const firstLoad = !updatedAt;
  return dispatch => {
    dispatch({ type: FETCH_NOTIFICATION });
    return axios
      .get(url)
      .then(response => {
        dispatch(recieveNotification(response.data.data, firstLoad));
      })
      .catch(() => {});
  };
}

export function readNotification(id) {
  return async dispatch => {
    try {
      await axios.post(`${ROOT_URL}/private/v1/notifications/${id}/read`, {});
      dispatch({ type: MARK_AS_READ, notificationId: id });
      return {
        success: true
      };
    } catch (error) {
      return {
        success: false
      };
    }
  };
}

export function readAllNotification() {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/private/v1/notifications/read_all`, {})
      .then(response => {
        dispatch({ type: MARK_ALL_AS_READ });

        enqueueSnackbar(response.data.message, {
          variant: 'success'
        });
      })
      .then(() => {
        dispatch(getNotifications());
      })
      .catch(() => {});
  };
}
