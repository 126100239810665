import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components/macro';
import _ from 'lodash';

import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';

import {
  getAuthToken,
  getCookie,
  setCookie
} from '../../libraries/authentication';
import { initializeCordovaNotification } from '../../libraries/cordovaNotification';
import {
  clickNotification,
  checkPushStatus,
  initializePwaPush
} from '../../libraries/notification';

import NotificationBox from './ui/NotificationBox';
import EntryPopUp from '../entryPopUp/EntryPopUp';

import { getNotifications, readNotification } from '../../actions/notification';
import { getCreditsRemaining } from '../../actions/credits';
import { getProfile } from '../../reducers/profileReducer';
import { getConversations } from '../../actions/chat';
import { getGogetterTracker } from '../../actions/gogetterTracking';
import NotifcationUpdatePusher from '../../libraries/NotifcationUpdatePusher';

const NotificationWrapper = styled.div`
  height: 0px;
  display: flex;
  justify-content: center;
  overflow: visible;
  @media only screen and (min-width: 751px) {
    margin-left: 185px;
  }
`;
const StyledPaper = styled(Paper)`
  overflow: hidden;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  position: absolute;
  top: 8px;
  z-index: 9999;
  min-width: 200px;
  max-width: 400px;
  width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
`;

class NotificatonReceiver extends Component {
  state = {
    isModalOpen: false,
    isNotificationDisplayed: false
  };

  componentDidMount() {
    setCookie('pushEnabled', 'false');
    this.getNotifications();

    if (window.cordova) {
      initializeCordovaNotification(
        this.recieveCordovaNotification,
        this.openCordovaNotification
      );
    } else {
      initializePwaPush();
      checkPushStatus();
    }
  }

  openCordovaNotification = data => {
    const { history, readNotification } = this.props;

    const notificationData = data?.notification?.rawPayload?.custom?.a;

    if (
      notificationData &&
      notificationData.push_data &&
      notificationData.push_type &&
      notificationData.push_type === 'notification'
    ) {
      const pushData = notificationData.push_data;
      clickNotification({
        notificationId: pushData.id,
        notifiableId: pushData.notifiable_id,
        action: pushData.action,
        read: true,
        push: history.push,
        readNotification,
        defaultPath: '/notifications'
      });
    }
  };

  recieveCordovaNotification = data => {
    const { getCreditsRemaining, getConversations, getGogetterTracker } =
      this.props;
    // For testing purpose and debugging you can do it using alert -> alert('data' + JSON.stringify(data));
    const notificationData = data?.notification?.rawPayload?.custom?.a;
    if (notificationData && notificationData.push_type) {
      switch (notificationData.push_type) {
        case 'notification':
          this.getNotifications();
          break;
        case 'credit':
          getCreditsRemaining();
          break;
        case 'job':
          if (notificationData.id) {
            getGogetterTracker(notificationData.id);
          }
          break;
        case 'chat':
          if (notificationData.id) {
            getConversations(notificationData.id);
          }
          break;
        case 'gogetter_tracking':
          if (notificationData.id) {
            getGogetterTracker(notificationData.id);
          }
          break;
        default:
          break;
      }
    }
  };

  getNotifications = () => {
    const {
      getNotifications,
      updatedAt,
      popupNotification,
      profile,
      getProfile
    } = this.props;

    if (getAuthToken() && getCookie('user-phone-verified') === 'true') {
      getNotifications(updatedAt).then(() => {
        this.showNotification();
      });

      if (!profile) {
        getProfile();
      }

      this.setState({
        isModalOpen: !!(getAuthToken() && popupNotification)
      });
    }
  };

  handleClickModal = (e, popupType, notificationId) => {
    const { readNotification } = this.props;
    if (popupType === 'popup_info') {
      readNotification(notificationId);
      this.setState(state => ({
        isModalOpen: !state.isModalOpen
      }));
    }
  };

  handleClickPopupAccept = (e, popupType, notificationId) => {
    const { readNotification } = this.props;
    if (popupType === 'popup_accept') {
      this.setState(state => ({
        isModalOpen: !state.isModalOpen
      }));
      readNotification(notificationId);
    }
  };

  showNotification = () => {
    const { newNotification } = this.props;
    if (newNotification) {
      this.setState(
        {
          isNotificationDisplayed: true
        },
        () => {
          setTimeout(() => {
            this.setState({
              isNotificationDisplayed: false
            });
          }, 3000);
        }
      );

      if (window.cordova && navigator.vibrate) {
        navigator.vibrate(1000);
      }
    } else {
      this.setState({
        isNotificationDisplayed: false
      });
    }
  };

  render() {
    const { popupNotification, loading, url, newNotification, updatedAt } =
      this.props;
    const { isModalOpen, isNotificationDisplayed } = this.state;

    return (
      <>
        <NotifcationUpdatePusher updatedAt={updatedAt} />
        {popupNotification && (
          <Dialog
            open={isModalOpen}
            onClose={
              popupNotification.action === 'popup_info'
                ? e => {
                    this.handleClickModal(
                      e,
                      popupNotification.action,
                      popupNotification.id
                    );
                  }
                : () => {}
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <EntryPopUp
              image={popupNotification.attachment_url}
              isAcknowledgementPopup={
                popupNotification.action === 'popup_accept'
              }
              onClose={e => {
                this.handleClickModal(
                  e,
                  popupNotification.action,
                  popupNotification.id
                );
              }}
              onButtonClick={e => {
                this.handleClickPopupAccept(
                  e,
                  popupNotification.action,
                  popupNotification.id
                );
              }}
              headerText={popupNotification.title}
              bodyText={popupNotification.content}
              learnMoreUrl={popupNotification.learn_more_url}
            />
          </Dialog>
        )}
        {!(
          url.includes('notifications') ||
          url.includes('messages') ||
          url.includes('announcements') ||
          (newNotification &&
            newNotification.action === 'poster_job' &&
            url.includes(`/jobs/${newNotification.notifiable_id}`)) ||
          (newNotification &&
            newNotification.action === 'create_job' &&
            url.includes('/')) ||
          (newNotification &&
            newNotification.action === 'poster_credits' &&
            url.includes('credits'))
        ) && (
          <NotificationWrapper>
            <Slide
              direction="down"
              in={newNotification && isNotificationDisplayed}
              timeout={500}
            >
              <StyledPaper elevation={6}>
                {newNotification && !loading && (
                  <NotificationBox
                    notifiableId={newNotification.notifiable_id}
                    action={newNotification.action}
                    notificationId={newNotification.id}
                    content={newNotification.content}
                    read={newNotification.read}
                    avatar={newNotification.avatar_link}
                    title={newNotification.title}
                    createdAt={newNotification.created_at}
                    isInAppNotification
                  />
                )}
              </StyledPaper>
            </Slide>
          </NotificationWrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const newNotifications =
    state.notification &&
    _.sortBy(_.values(state.notification.newNotifications), [
      o => {
        return o.id;
      }
    ]).reverse();
  const allNotifications =
    state.notification &&
    _.sortBy(_.values(state.notification.notifications), [
      o => {
        return o.id;
      }
    ]).reverse();
  const popUpNotifications =
    allNotifications &&
    _.find(allNotifications, ['group', 'popup']) &&
    _.filter(allNotifications, { group: 'popup', read: false, active: true });
  const filteredNewNotifications = _.reject(newNotifications, {
    read: true,
    group: 'popup'
  });
  return {
    popupNotification: popUpNotifications && popUpNotifications[0],
    allNotifications,
    newNotification: filteredNewNotifications && filteredNewNotifications[0],
    updatedAt: state.notification && state.notification.updatedAt,
    loading: state.notification && state.notification.loading,
    url:
      state.router && state.router.location && state.router.location.pathname,
    profile: state.user && state.user.profile
  };
};

export default connect(mapStateToProps, {
  getNotifications,
  readNotification,
  push,
  getCreditsRemaining,
  getProfile,
  getConversations,
  getGogetterTracker
})(NotificatonReceiver);
