import React from 'react';
import styled from 'styled-components/macro';

import LinearProgress from '@mui/material/LinearProgress';

const StyledLinearProgress = styled(LinearProgress)`
  && {
    opacity: 0.5;
  }
`;

const PageLoadingBar = () => {
  return <StyledLinearProgress />;
};

export default PageLoadingBar;
