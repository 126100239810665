import axios from 'axios';
import {
  SERVER_URL,
  HTTP_STATUS_OK,
  successResponse,
  failedResponse
} from './apiHelpers';

export const getPublicTrackingData = async publicId => {
  if (!publicId) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/track/${publicId}`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getGogetterTracking = async jobId => {
  if (!jobId) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/jobs/${jobId}/gogetter_location`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
