import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import Div100vh from 'react-div-100vh';
import { push } from 'connected-react-router';

import DialogUnavailableTile from '../ui/DialogUnavailableTile';
import MobileBanner from '../ui/MobileBanner';

import colors from '../../../style/colors';

import HelpButton from '../../ui/HelpButton';
import DiscoveryJobTiles from '../ui/DiscoveryJobTiles';
import { getCreditsRemaining } from '../../../actions/credits';
import { getAuthToken } from '../../../libraries/authentication';

const DiscoverWrapper = styled(Div100vh)`
  cursor: default;
  min-width: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const Body = styled.div``;
const HelperWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const SegmentDivider = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${colors.greyLight};
`;

const MobileDiscover = props => {
  const {
    isBusinessAccount,
    open,
    onClose,
    onClickBA,
    onClickPartTimerUnavailable,
    onClickPersonalHelperUnavailable,
    jobType
  } = props;

  useEffect(() => {
    const { authUser, isBusinessAccount, getCreditsRemaining } = props;

    if (getAuthToken()) {
      getCreditsRemaining();
    }

    if (authUser && window.trackingPermitted) {
      window.analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
        phone_num: authUser.phone_num,
        user_type: isBusinessAccount ? 'business_account' : 'free_account',
        signup_at: authUser.created_at,
        admin: authUser.is_admin,
        roles: authUser.roles,
        signup_method: authUser.signup_method
      });
    }
  }, []);

  return (
    <>
      <DiscoverWrapper style={{ height: 'calc(100rvh - 125px)' }}>
        <Body>
          <MobileBanner />
          <DiscoveryJobTiles isMobile />
          <SegmentDivider />
          <HelperWrapper>
            <HelpButton />
          </HelperWrapper>
        </Body>
      </DiscoverWrapper>
      <DialogUnavailableTile
        open={open}
        onClose={onClose}
        onClickBA={onClickBA}
        onClickPartTimerUnavailable={onClickPartTimerUnavailable}
        onClickPersonalHelperUnavailable={onClickPersonalHelperUnavailable}
        jobType={jobType}
        isBusinessAccount={isBusinessAccount}
        isMobile
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  getCreditsRemaining: () => dispatch(getCreditsRemaining())
});

export default connect(null, mapDispatchToProps)(MobileDiscover);
