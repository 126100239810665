import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { detectIE } from './libraries/ApplicationService';
import { initializeSentry } from './services/sentry';
import './i18n';

initializeSentry();

const startApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

const cordovaReady = () => {
  startApp();
};

if (window.cordova) {
  document.addEventListener('deviceready', cordovaReady, false);
  serviceWorker.unregister();
} else {
  if (!navigator.cookieEnabled) {
    window.location.href = '/cookies.html';
  }
  detectIE();
  startApp();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.register();
}
