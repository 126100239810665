import _, { cloneDeep } from 'lodash';
import {
  NO_UPDATES,
  RECEIVE_JOBS,
  RECEIVE_JOBS_SEARCH,
  REQUEST_JOBS,
  RESET_JOBS,
  RESET_TOTAL,
  SET_ACTIVE_JOB_ID,
  SET_ACTIVE_JOB_LOADING,
  SET_ATTENTION_FILTER,
  SET_CURRENT_TAB,
  SET_DATE_FILTER,
  UPDATE_PAYROLL_DETAILS,
  UPDATE_RECEIVED_TAB
} from './constants/jobs';

export const jobsReducerDefaultState = {
  activeJobId: undefined,
  settingActiveJob: false,
  currentTab: 0,
  isFetching: false,
  updatedAt: undefined,
  totalJobsCount: undefined,
  totalJobsReceived: 0,
  dateFilter: 'all',
  attentionFilter: 'all',
  dates: {
    startAt: undefined,
    endAt: undefined
  },
  items: {},
  tabType: {
    attention: {
      received: 0,
      total: undefined
    },
    open: {
      received: 0,
      total: undefined
    },
    accepted: {
      received: 0,
      total: undefined
    },
    in_progress: {
      received: 0,
      total: undefined
    },
    completed: {
      received: 0,
      total: undefined
    },
    cancelled: {
      received: 0,
      total: undefined
    }
  }
};

export default (state = jobsReducerDefaultState, action) => {
  switch (action.type) {
    case REQUEST_JOBS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_JOBS:
      const newJobs = _.mapKeys(action.items, 'id');
      const allJobs = {
        ...state.items,
        ...newJobs
      };
      return {
        ...state,
        isFetching: false,
        updatedAt: action.updatedAt,
        totalJobsCount: action.totalJobsCount,
        totalJobsReceived: _.size(allJobs),
        items: allJobs,
        tabType: {
          attention: {
            ...state.tabType.attention,
            total: action.attention_total,
            payment_release_total: action.payment_release_total,
            gogetter_approval_total: action.gogetter_approval_total,
            shopping_payment_total: action.shopping_payment_total
          },
          open: {
            ...state.tabType.open,
            total: action.open_total
          },
          accepted: {
            ...state.tabType.accepted,
            total: action.accepted_total
          },
          in_progress: {
            ...state.tabType.in_progress,
            total: action.in_progress_total
          },
          completed: {
            ...state.tabType.completed,
            total: action.completed_total
          },
          cancelled: {
            ...state.tabType.cancelled,
            total: action.cancelled_total
          }
        }
      };
    case RECEIVE_JOBS_SEARCH:
      const newJobsSearch = _.mapKeys(action.items, 'id');
      const allJobsSearch = {
        ...state.items,
        ...newJobsSearch
      };
      return {
        ...state,
        totalJobsReceived: _.size(allJobsSearch),
        items: allJobsSearch
      };
    case RESET_TOTAL:
      return {
        ...state,
        tabType: {
          attention: {
            ...state.tabType.attention,
            total: undefined
          },
          open: {
            ...state.tabType.open,
            total: undefined
          },
          accepted: {
            ...state.tabType.accepted,
            total: undefined
          },
          in_progress: {
            ...state.tabType.in_progress,
            total: undefined
          },
          completed: {
            ...state.tabType.completed,
            total: undefined
          },
          cancelled: {
            ...state.tabType.cancelled,
            total: undefined
          }
        }
      };
    case UPDATE_RECEIVED_TAB:
      const tabType = { ...state.tabType };
      tabType[action.tabType] = {
        ...tabType[action.tabType],
        received: action.received
      };
      return {
        ...state,
        tabType
      };
    case NO_UPDATES:
      return {
        ...state,
        isFetching: false
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab
      };
    case SET_ATTENTION_FILTER:
      return {
        ...state,
        attentionFilter: action.attentionFilter
      };
    case SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.dateFilter,
        dates: action.dates
      };
    case RESET_JOBS:
      return {
        ...state,
        updatedAt: undefined,
        totalJobsCount: undefined,
        totalJobsReceived: 0,
        items: {},
        tabType: {
          attention: {
            received: 0,
            total: undefined
          },
          open: {
            received: 0,
            total: undefined
          },
          accepted: {
            received: 0,
            total: undefined
          },
          in_progress: {
            received: 0,
            total: undefined
          },
          completed: {
            received: 0,
            total: undefined
          },
          cancelled: {
            received: 0,
            total: undefined
          }
        }
      };
    case SET_ACTIVE_JOB_ID:
      return {
        ...state,
        activeJobId: action.activeJobId
      };
    case SET_ACTIVE_JOB_LOADING:
      return {
        ...state,
        settingActiveJob: action.settingActiveJob
      };
    case UPDATE_PAYROLL_DETAILS:
      const job = cloneDeep(state.items[action.jobId]);

      job.payrolls[action.order][action.name] = action.value;

      return {
        ...state,
        items: {
          ...state.items,
          [action.jobId]: job
        }
      };
    default:
      return state;
  }
};
