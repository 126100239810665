import axios from 'axios';
import { getAuthToken } from '../libraries/authentication';
import {
  SERVER_URL,
  successResponse,
  failedResponse,
  HTTP_STATUS_OK
} from './apiHelpers';

export const getPostingFee = async () => {
  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/fee`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        feeBreakdown: {
          discount_percent: response?.data?.data?.discount_percent,
          final_fee: response?.data?.data?.final_fee,
          original_fee: response?.data?.data?.original_fee
        }
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const postHiringPost = async jobDetails => {
  if (!jobDetails || !getAuthToken()) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts`;
    const response = await axios.post(url, { hiring_post: jobDetails });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        hiringPostId: response?.data?.data?.hiring_post_id
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const fetchHiringPosts = async (status, nextCursor) => {
  if (!status || !getAuthToken()) return failedResponse();

  try {
    let url;
    if (nextCursor) {
      url = `${SERVER_URL}/private/v2/hiring_posts?status=${status}&cursor=${nextCursor}`;
    } else {
      url = `${SERVER_URL}/private/v2/hiring_posts?status=${status}`;
    }

    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        response: response?.data?.data?.records,
        nextCursor: response?.data?.data?.next_cursor
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getHiringPost = async hiringPostId => {
  if (!hiringPostId || !getAuthToken()) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/${hiringPostId}`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        response: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getListofApplicants = async hiringPostId => {
  if (!hiringPostId || !getAuthToken()) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/${hiringPostId}/applications`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        hiringPost: response?.data?.data?.hiring_post,
        applications: response?.data?.data?.applications
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getApplicantDetails = async (hiringPostId, applicantId) => {
  if (!hiringPostId || !getAuthToken() || !applicantId) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v3/hiring_posts/${hiringPostId}/applications/${applicantId}`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        applicant: {
          ...response?.data?.data?.applicant,
          suitability: response?.data?.data?.suitability
        },
        screening_questions: response?.data?.data?.applicant_screening_answers,
        coverNote: response?.data?.data?.cover_note
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getApplicantsReviews = async (hiringPostId, applicantId) => {
  if (!hiringPostId || !getAuthToken() || !applicantId) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/${hiringPostId}/applications/${applicantId}/reviews`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        response: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const fetchHiringPostReceipt = async hiringPostId => {
  if (!hiringPostId || !getAuthToken()) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/${hiringPostId}/receipt`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        receiptDetails: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const patchHiringPost = async jobDetails => {
  if (!jobDetails || !getAuthToken()) return failedResponse();

  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/${jobDetails?.id}`;
    const response = await axios.patch(url, { hiring_post: jobDetails });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse();
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const postAbandonTrigger = async (helperType, employmentType) => {
  if (!getAuthToken() || !helperType || !employmentType)
    return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v2/hiring_posts/creation_tracker`;
    const response = await axios.post(url, {
      helper_type: helperType,
      employment_type: employmentType
    });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse();
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const updateApplicantSuitability = async params => {
  if (!getAuthToken() || !params) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v3/hiring_posts/${params?.hiringPostId}/applications/${params?.applicantId}`;
    const response = await axios.patch(url, {
      suitability: params?.suitability
    });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse();
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
