import Login from '../../components/auth/Login';
import AuthHome from '../../components/auth/HomePage';
import ForgotPassword from '../../components/auth/ForgotPassword';
import ResetPassword from '../../components/auth/ResetPassword';
import SignUp from '../../components/auth/SignUp';

export const authRouters = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: AuthHome
  },
  {
    path: '/forgot_password',
    component: ForgotPassword
  },
  {
    path: '/reset_password/:token',
    component: ResetPassword
  },
  {
    path: '/sign_up',
    component: SignUp
  }
];
