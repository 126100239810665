const MINIMUM_LOCATIONS = 2;

export const isValidRoute = locations => {
  if (!locations) return false;

  if (locations.length === 0) return false;

  const locationsWithLatLng = locations.filter(loc => loc?.lat && loc?.lon);

  if (locationsWithLatLng.length < MINIMUM_LOCATIONS) {
    return false;
  }

  return true;
};
