import moment from 'moment';
import { GOGETTER_SELECTION_ANYONE } from './constants';
import { GOGETTER_POOL_ALL } from '../../constants';

/* eslint-disable no-param-reassign */
const massJobMapper = (job, feeBreakdown) => {
  const mappedJob = {
    ...job,
    payment_confirmation: false
  };

  delete job.tasks[0].start_at;
  delete job.tasks[0].end_at;
  delete job.tasks[0].order;

  if (job.blast_pool !== GOGETTER_SELECTION_ANYONE) {
    mappedJob.gogetter_pool = GOGETTER_POOL_ALL;
  }

  const mappedSchedules = job.schedulesNew.map((schedule, index) => {
    return {
      ...schedule,
      tasks: [
        ...schedule.tasks.map(task => {
          return {
            ...task,
            ...job.tasks[0],
            break_times: job.schedules[index]?.break_times?.map(breakTime => {
              const breakStart = moment(breakTime.start_at);
              const breakEnd = moment(breakTime.end_at);
              const taskStart = moment(task.start_at);
              const taskEnd = moment(task.end_at);

              let breakStartDate;
              let breakEndDate;

              const isGraveyardShift = taskStart.day() !== taskEnd.day();

              if (isGraveyardShift && breakStart.day() !== breakEnd.day()) {
                breakStartDate = task.start_at;
                breakEndDate = task.end_at;
              } else if (
                isGraveyardShift &&
                breakStart.day(taskEnd.day()).isAfter(taskEnd.startOf('day')) &&
                breakStart
                  .day(taskEnd.day())
                  .isBefore(taskEnd.startOf('day').add(12, 'hours')) &&
                breakEnd.day(taskEnd.day()).isAfter(taskEnd.startOf('day')) &&
                breakEnd
                  .day(taskEnd.day())
                  .isBefore(taskEnd.startOf('day').add(12, 'hours'))
              ) {
                breakStartDate = task.end_at;
                breakEndDate = task.end_at;
              } else {
                breakStartDate = task.start_at;
                breakEndDate = task.start_at;
              }

              return {
                start_at: moment(breakStartDate)
                  .hour(breakStart.hour())
                  .minute(breakStart.minute())
                  .startOf('minute')
                  .toISOString(),
                end_at: moment(breakEndDate)
                  .hour(breakEnd.hour())
                  .minute(breakEnd.minute())
                  .startOf('minute')
                  .toISOString()
              };
            })
          };
        })
      ],
      no_of_gogetters: schedule.no_of_gogetters,
      fee_calcs: [{}],
      fee: schedule.adjusted_fee || schedule.suggested_fee,
      reference: schedule?.reference,
      surge_fee: feeBreakdown?.helper?.surge_fee
    };
  });

  mappedJob.schedules = mappedSchedules;
  delete mappedJob.fees;
  delete mappedJob.tasks;
  delete mappedJob.schedulesNew;

  // TODO: Armin: delete schedules[0].minimum_Fee and suggested_fee

  return {
    job: {
      ...mappedJob,
      suggested_hourly_fee: job?.hourly_fee?.suggested,
      minimum_hourly_fee: job?.hourly_fee?.minimum,
      hourly_fee: feeBreakdown?.helper?.final
    }
  };
};

export { massJobMapper };
