export const MINIMUM_LENGTH = 14;
const NUMBER_REGEX = /\d/;
const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;
const SPECIAL_CHARACTER_REGEX = /[!@$%^&*#]/;

export const isMinLengthPassword = password => {
  return password?.length >= MINIMUM_LENGTH;
};

export const isNumberInPassword = password => {
  return NUMBER_REGEX.test(password);
};

export const isUppercaseInPassword = password => {
  return UPPERCASE_REGEX.test(password);
};

export const isLowercaseInPassword = password => {
  return LOWERCASE_REGEX.test(password);
};

export const isSpecialCharacterInPassword = password => {
  return SPECIAL_CHARACTER_REGEX.test(password);
};

export const isValidStrongPassword = password => {
  return (
    password &&
    isMinLengthPassword(password) &&
    isNumberInPassword(password) &&
    isUppercaseInPassword(password) &&
    isLowercaseInPassword(password) &&
    isSpecialCharacterInPassword(password)
  );
};
