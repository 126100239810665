import axios from 'axios';
import { getAuthToken, getCookie } from '../libraries/authentication';
import {
  SERVER_URL,
  successResponse,
  failedResponse,
  HTTP_STATUS_OK
} from './apiHelpers';

export const fetchBusinessStatus = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/users/business_status`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        profileDetails: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const fetchProfile = async () => {
  try {
    const url = `${SERVER_URL}/private/v2/users`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        profile: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const fetchReviews = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/users/reviews`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        reviews: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const updateUserProfile = async params => {
  try {
    const url = `${SERVER_URL}/private/v1/users`;
    const response = await axios.patch(url, {
      user: params
    });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message,
        user: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const updateUserPassword = async password => {
  try {
    const url = `${SERVER_URL}/private/v1/users/update_password`;
    const response = await axios.patch(url, {
      user: {
        password
      }
    });

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const fetchReferralStatus = async () => {
  try {
    const url = `${SERVER_URL}/private/v1/users/poster_referral_status`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        referralData: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getUserKycTier = async () => {
  try {
    const url = `${SERVER_URL}/private/v2/kyc/get_kyc_details`;

    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const updateUserUsageInfo = async userInfo => {
  try {
    if (!getAuthToken()) return { isSuccess: false };

    const url = `${SERVER_URL}/private/v2/users/update_user_info`;

    const requestData = {
      nature_of_usage: userInfo?.nature_of_usage,
      service_interests: userInfo?.service_interests,
      business: {
        company_name: userInfo?.business_details?.company_name,
        industry: userInfo?.business_details?.industry
      },
      utm_object: {
        utm_source: getCookie('utm_source'),
        utm_medium: getCookie('utm_medium'),
        utm_campaign: getCookie('utm_campaign')
      }
    };

    const response = await axios.patch(url, requestData);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message,
        updatedUserData: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getUserUsageInfo = async () => {
  try {
    if (!getAuthToken()) return { isSuccess: false };

    const url = `${SERVER_URL}/private/v2/users/user_info`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const deleteUser = async (userId, comment) => {
  try {
    if (!getAuthToken() || !userId || !comment) return failedResponse();

    const url = `${SERVER_URL}/private/v2/users/${userId}`;
    const response = await axios.delete(url, { data: { comment } });

    if (response?.status === 200) {
      return successResponse({
        message: response?.data?.message
      });
    } else if (response?.status === 400) {
      return failedResponse({
        message: response?.data?.message,
        hasRequestedMultimeTimes: true
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const downgradeAccount = async () => {
  if (!getAuthToken()) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/users/expire_ba_role`;
    const response = await axios.post(url, {});

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        message: response?.data?.message
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};

export const getBlockeeList = async () => {
  if (!getAuthToken()) return failedResponse();
  try {
    const url = `${SERVER_URL}/private/v1/blocks`;
    const response = await axios.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        blocks: response?.data?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
