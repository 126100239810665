import {
  BULK_ORDER_ADD_DROPOFF,
  BULK_ORDER_CANCEL_PROCESSING,
  BULK_ORDER_CHANGE_PAGE,
  BULK_ORDER_CREATION_END,
  BULK_ORDER_CREATION_START,
  BULK_ORDER_DISMISS_FIRST_ENTRY,
  BULK_ORDER_EDIT_DROPOFF,
  BULK_ORDER_EDIT_IMAGE,
  BULK_ORDER_REMOVE_DROPOFF,
  BULK_ORDER_RESET_DROPOFFS,
  BULK_ORDER_RESET_STATE,
  BULK_ORDER_SET_FIRST_ENTRY,
  BULK_ORDER_UNDO_REMOVE,
  BULK_ORDER_UPDATE,
  BULK_ORDER_UPDATE_JOB_DETAILS,
  BULK_REORDER_DROPOFF_TOP
} from './constants/bulkOrder';
import {
  DROPOFF_STATUS_CANCELLED,
  DROPOFF_STATUS_PENDING_UPLOAD,
  DROPOFF_STATUS_PROCESSED,
  ITEM_SIZE_EXTRA_SMALL,
  ITEM_WEIGHT_LIGHTEST
} from '../components/bulkOrder/constants';

import { BIKE_RIDE_ID } from '../constants';

const ORDER_ONE = 1;

const bulkOrderDropoff = {
  order: 1,
  receiver_name: '',
  receiver_phone: '',
  address: {
    location: null,
    location_lat: null,
    location_long: null,
    unit: ''
  },
  description: '',
  order_id: '',
  sender_name: '',
  sender_email: '',
  isValidAddress: false,
  isOutOfCoverage: true,
  coverageArea: null,
  isApprovedForDispatch: false,
  fee: null,
  distance_covered_m: null,
  hasBeenEdited: false,
  status: DROPOFF_STATUS_PROCESSED
};

const bulkOrderDropoffInitialState = {
  totalRows: 1,
  status: DROPOFF_STATUS_PENDING_UPLOAD,
  rowToProcess: 0,
  fileName: null,
  deletedDropOff: null,
  creatingBulkOrder: false,
  dropOffs: [bulkOrderDropoff]
};

export const bulkOrderReducerDefaultState = {
  page: 'introduction',
  firstEntry: false,
  jobDetails: {
    instruction: '',
    sms: false,
    merchant_contact_name: '',
    merchant_contact_phone: '',
    pickup_date: undefined,
    timeslot: undefined,
    item_size: ITEM_SIZE_EXTRA_SMALL,
    item_weight: ITEM_WEIGHT_LIGHTEST,
    ride_id: BIKE_RIDE_ID,
    address: {
      location: '',
      location_lat: '',
      location_long: '',
      unit: '',
      coverage_area: ''
    },
    images: [
      {
        id: 0
      },
      {
        id: 1
      },
      {
        id: 2
      }
    ]
  },
  ...bulkOrderDropoffInitialState
};

export default (state = bulkOrderReducerDefaultState, action) => {
  switch (action.type) {
    case BULK_ORDER_RESET_STATE:
      return bulkOrderReducerDefaultState;
    case BULK_ORDER_CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case BULK_ORDER_SET_FIRST_ENTRY:
      return {
        ...state,
        firstEntry: true
      };
    case BULK_ORDER_DISMISS_FIRST_ENTRY:
      return {
        ...state,
        firstEntry: false
      };
    case BULK_ORDER_UPDATE_JOB_DETAILS:
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          ...action.update
        }
      };
    case BULK_ORDER_EDIT_IMAGE:
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          images: state.jobDetails.images.map(image => {
            if (image.id === action.id) {
              return {
                ...image,
                ...action.updates
              };
            }
            return image;
          })
        }
      };
    case BULK_ORDER_UPDATE:
      return {
        ...state,
        ...action.updates
      };
    case BULK_ORDER_CANCEL_PROCESSING:
      return {
        ...state,
        ...bulkOrderDropoffInitialState,
        status: DROPOFF_STATUS_CANCELLED,
        fileName: state.fileName
      };
    case BULK_ORDER_EDIT_DROPOFF:
      return {
        ...state,
        dropOffs: state?.dropOffs?.map(dropOff => {
          if (dropOff.order === action.order) {
            return {
              ...dropOff,
              ...action.updates,
              hasBeenEdited: true
            };
          }
          return dropOff;
        })
      };
    case BULK_ORDER_RESET_DROPOFFS:
      return {
        ...state,
        ...bulkOrderDropoffInitialState
      };
    case BULK_ORDER_ADD_DROPOFF:
      const lastDropOff = state?.dropOffs?.length - 1;
      return {
        ...state,
        dropOffs: [
          ...state.dropOffs,
          {
            ...bulkOrderDropoff,
            order: state.dropOffs[lastDropOff]?.order + ORDER_ONE || ORDER_ONE
          }
        ]
      };
    case BULK_ORDER_REMOVE_DROPOFF:
      return {
        ...state,
        deletedDropOff: state.dropOffs?.filter(
          drop => drop.order === action.order
        )[0],
        dropOffs: state.dropOffs?.filter(drop => drop.order !== action.order)
      };
    case BULK_ORDER_UNDO_REMOVE:
      const newDropOffs = [...state.dropOffs, state.deletedDropOff].sort(
        (a, b) => a.order - b.order
      );
      return {
        ...state,
        dropOffs: newDropOffs,
        deletedDropOff: null
      };
    case BULK_REORDER_DROPOFF_TOP:
      const dropOffToTop = state.dropOffs?.find(
        dropOff => dropOff.order === action.order
      );
      const updatedDropOffs = state.dropOffs.filter(
        dropOff => dropOff.order !== action.order
      );
      const reOrderedDropOffs = [dropOffToTop, ...updatedDropOffs].map(
        (dropOff, index) => {
          return {
            ...dropOff,
            order: index + 1
          };
        }
      );
      return {
        ...state,
        dropOffs: reOrderedDropOffs
      };
    case BULK_ORDER_CREATION_START:
      return {
        ...state,
        creatingBulkOrder: true
      };
    case BULK_ORDER_CREATION_END:
      return {
        ...state,
        creatingBulkOrder: false
      };
    default:
      return state;
  }
};
