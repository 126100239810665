import { createSlice } from '@reduxjs/toolkit';
import { fetchProfile } from '../api/profile';

export const initialState = {
  reviews: [],
  profile: null,
  loading: false,
  loaded: false,
  error: null
};

const profileReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startFetchProfile: state => {
      state.loading = true;
    },
    endFetchProfile: state => {
      state.loading = false;
      state.loaded = true;
    },
    rejectFetchProfile: state => {
      state.loading = false;
      state.loaded = false;
    },
    fetchReviewsFulfilled: (state, action) => {
      state.loading = false;
      state.loaded = true;
      state.reviews = action.payload;
    },
    updateProfileDetails: (state, action) => {
      const updatedProfile = { ...state.profile, ...action.payload };
      state.profile = updatedProfile;
    }
  }
});

export const {
  startFetchProfile,
  endFetchProfile,
  rejectFetchProfile,
  fetchReviewsFulfilled,
  updateProfileDetails
} = profileReducer.actions;

export default profileReducer.reducer;

export function getProfile() {
  return async dispatch => {
    dispatch(startFetchProfile());
    const response = await fetchProfile();

    if (response?.isSuccess) {
      dispatch(updateProfileDetails(response?.profile));
      dispatch(endFetchProfile());
    } else {
      dispatch(rejectFetchProfile());
    }
  };
}
