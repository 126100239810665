import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuid4 } from 'uuid';
import { enqueueSnackbar } from 'notistack';
import { connect } from 'react-redux';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Button from '@mui/material/Button';

import FacebookIcon from '@mui/icons-material/Facebook';

import { socialLogin } from '../../actions/auth';
import { getCookie } from '../../libraries/authentication';
import colors from '../../style/colors';

const FbButton = styled(Button)`
  background-color: ${colors.facebookBlue};
  color: ${colors.white};
  font-family: 'Open Sans', sans-serif !important;
  width: 100%;
  font-weight: 100 !important;
  text-transform: none !important;
`;
const FbWrapper = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
`;

class Facebook extends Component {
  responseFacebook = async response => {
    const { socialLogin } = this.props;
    const { id, userID, email, name, picture } = response;
    const avatar = picture?.data?.url;
    const currentReferralCode = getCookie('posterReferralCode');
    const user = {
      uid: id ?? userID,
      user: {
        name,
        email,
        avatar: avatar ?? null,
        temp_poster_referral_code: currentReferralCode
      },
      type: 'facebook'
    };
    await socialLogin(user);
  };

  limitedResponseFacebook = async () => {
    window.facebookConnectPlugin.getCurrentProfile(
      this.responseFacebook,
      this.onErrorResponse
    );
  };

  onFBCordovaLogin = () => {
    const { onUpdateLoading } = this.props;
    onUpdateLoading(true);
    if (window.trackingPermitted) {
      window.facebookConnectPlugin.login(
        ['email', 'public_profile'],
        this.fbLoginSuccess,
        this.onErrorResponse
      );
    } else {
      window.facebookConnectPlugin.loginWithLimitedTracking(
        ['email', 'public_profile'],
        uuid4(),
        this.limitedResponseFacebook,
        this.onErrorResponse
      );
    }
  };

  fbLoginSuccess = userData => {
    window.facebookConnectPlugin.api(
      `${userData.authResponse.userID}/?fields=id,email,name,picture`,
      ['email', 'public_profile'],
      this.responseFacebook,
      this.onErrorResponse
    );
  };

  onErrorResponse = error => {
    const { onUpdateLoading } = this.props;
    onUpdateLoading(false);
    enqueueSnackbar(error, {
      variant: 'error'
    });
  };

  render() {
    const origin = window && window.location && window.location.origin;
    const redirectUri =
      typeof window !== 'undefined'
        ? origin + window.location.pathname
        : origin;

    const isSafariBrowser =
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0;
    if (window.cordova) {
      return (
        <FbWrapper hide={this.props.hide}>
          <FbButton
            size="large"
            variant="contained"
            onClick={this.onFBCordovaLogin}
            color="info"
            startIcon={<FacebookIcon />}
            disableElevation
          >
            CONTINUE WITH FACEBOOK
          </FbButton>
        </FbWrapper>
      );
    } else {
      return (
        <FbWrapper hide={this.props.hide}>
          <FacebookLogin
            appId={import.meta.env.VITE_FACEBOOK_ID}
            autoLoad={false}
            redirectUri={redirectUri}
            disableMobileRedirect={!!isSafariBrowser}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={renderProps => (
              <FbButton
                size="large"
                variant="contained"
                onClick={renderProps.onClick}
                color="info"
                startIcon={<FacebookIcon />}
                disableElevation
              >
                CONTINUE WITH FACEBOOK
              </FbButton>
            )}
          />
        </FbWrapper>
      );
    }
  }
}

export default connect(null, { socialLogin })(Facebook);
