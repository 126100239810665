import React, { useState, forwardRef } from 'react';
import styled from 'styled-components/macro';

import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import {
  MINIMUM_LENGTH,
  isLowercaseInPassword,
  isMinLengthPassword,
  isNumberInPassword,
  isSpecialCharacterInPassword,
  isUppercaseInPassword
} from '../../services/authentication';

const RequirementsList = styled.div`
  padding: 1rem 0.5rem;
`;
const RequirementRow = styled.div`
  display: flex;
  align-items: center;
  :not(:first-child) {
    margin-top: 0.25rem;
  }
  color: ${({ isValid, isInvalid }) =>
    isValid ? colors.green : isInvalid ? colors.red : null};
`;
const RequirementText = styled.div`
  font-size: ${fontSize.medium};
  margin-left: 0.5rem;
`;
const StyledTextField = styled(TextField)`
  > div {
    padding-right: unset;
  }
`;

export const TEXT_FIELD_TEST_ID = 'strong-password-field';
export const CHECK_CIRCLE_TEST_ID = 'checkmark-icon';
export const CROSS_CIRCLE_TEST_ID = 'cross-icon';

const StrongPasswordField = forwardRef((props, ref) => {
  const { password, name, onChange, onKeyPress, fullWidth } = props;

  const [isShowingRequirements, setIsShowingRequirements] = useState(false);
  const [isShowingPassword, setIsShowingPassword] = useState(false);

  const isMinimumLength = isMinLengthPassword(password);
  const isNumberPresent = isNumberInPassword(password);
  const isUppercaseLetterPresent = isUppercaseInPassword(password);
  const isLowercaseLetterPresent = isLowercaseInPassword(password);
  const isSpecialCharacterPresent = isSpecialCharacterInPassword(password);

  return (
    <>
      <StyledTextField
        inputRef={ref}
        variant="outlined"
        label="Password"
        name={name}
        value={password}
        type={isShowingPassword ? 'text' : 'password'}
        onChange={onChange}
        onFocus={() => setIsShowingRequirements(true)}
        onBlur={() => setIsShowingRequirements(false)}
        onKeyPress={e => (onKeyPress ? onKeyPress(e) : () => {})}
        placeholder="Password"
        fullWidth={fullWidth}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setIsShowingPassword(!isShowingPassword)}
              >
                {isShowingPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
        data-testid={TEXT_FIELD_TEST_ID}
      />
      <Collapse in={password || isShowingRequirements}>
        <RequirementsList>
          <RequirementRow
            isValid={isMinimumLength}
            isInvalid={!isShowingRequirements && !isMinimumLength}
          >
            {!isShowingRequirements && !isMinimumLength ? (
              <Cancel fontSize="small" data-testid={CROSS_CIRCLE_TEST_ID} />
            ) : (
              <CheckCircle
                fontSize="small"
                data-testid={CHECK_CIRCLE_TEST_ID}
              />
            )}
            <RequirementText>
              Must contain at least {MINIMUM_LENGTH} characters
            </RequirementText>
          </RequirementRow>
          <RequirementRow
            isValid={isUppercaseLetterPresent}
            isInvalid={!isShowingRequirements && !isUppercaseLetterPresent}
          >
            {!isShowingRequirements && !isUppercaseLetterPresent ? (
              <Cancel fontSize="small" />
            ) : (
              <CheckCircle fontSize="small" />
            )}
            <RequirementText>
              Must contain at least one uppercase letter
            </RequirementText>
          </RequirementRow>
          <RequirementRow
            isValid={isLowercaseLetterPresent}
            isInvalid={!isShowingRequirements && !isLowercaseLetterPresent}
          >
            {!isShowingRequirements && !isLowercaseLetterPresent ? (
              <Cancel fontSize="small" />
            ) : (
              <CheckCircle fontSize="small" />
            )}
            <RequirementText>
              Must contain at least one lowercase letter
            </RequirementText>
          </RequirementRow>
          <RequirementRow
            isValid={isNumberPresent}
            isInvalid={!isShowingRequirements && !isNumberPresent}
          >
            {!isShowingRequirements && !isNumberPresent ? (
              <Cancel fontSize="small" />
            ) : (
              <CheckCircle fontSize="small" />
            )}
            <RequirementText>Must contain at least one number</RequirementText>
          </RequirementRow>
          <RequirementRow
            isValid={isSpecialCharacterPresent}
            isInvalid={!isShowingRequirements && !isSpecialCharacterPresent}
          >
            {!isShowingRequirements && !isSpecialCharacterPresent ? (
              <Cancel fontSize="small" />
            ) : (
              <CheckCircle fontSize="small" />
            )}
            <RequirementText>
              Must contain at least one special character (!, @, $, %, ^, &, *,
              #)
            </RequirementText>
          </RequirementRow>
        </RequirementsList>
      </Collapse>
    </>
  );
});

export default StrongPasswordField;
