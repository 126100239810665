/* eslint-disable */
import moment from 'moment';
import generateCreateDefaultState from './generateCreateDefaultState';
import { GOGETTER_POOL_ALL, GOGETTER_POOL_RELIABLE } from '../constants';
import { GOGETTER_SELECTION_ANYONE } from '../services/job/constants';

const createJobDefaultReducer = (reducerType, action, state) => {
  switch (action.type) {
    case `${reducerType}_SET_START_STATE`:
      return action.start_state;
    case `${reducerType}_CHANGE_PAGE`:
      return {
        ...state,
        page: action.page
      };
    case `${reducerType}_DONE_EDIT_TASK`:
      return {
        ...state,
        originalTaskToEdit: undefined,
        modifyTask: undefined,
        modifyTaskPage: undefined
      };
    case `${reducerType}_START_EDIT_TASK`:
      return {
        ...state,
        originalTaskToEdit: state.job.tasks[action.taskOrder],
        modifyTask: action.taskOrder,
        modifyTaskPage: action.modifyPage
      };
    case `${reducerType}_LOCATION_DETAILS`:
      return {
        ...state,
        modifyTaskPage: 'details'
      };
    case `${reducerType}_LOCATION_SEARCH`:
      return {
        ...state,
        modifyTaskPage: 'search'
      };
    case `${reducerType}_EDIT_BREAK_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          break_time: {
            ...state.job.break_time,
            ...action.updates
          }
        }
      };
    case `${reducerType}_EDIT_BRIEFING_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          briefing_time: {
            ...state.job.briefing_time,
            ...action.updates
          }
        }
      };
    case `${reducerType}_SELECT_BRIEFING_TIME`:
      const startAt =
        state.job &&
        state.job.briefing_time &&
        state.job.briefing_time.start_at;
      const endAt =
        state.job && state.job.briefing_time && state.job.briefing_time.end_at;
      if (startAt) {
        return {
          ...state,
          job: {
            ...state.job,
            is_briefing_time_selected: action.isSelected,
            briefing_time: {
              start_at:
                action.isSelected &&
                moment(
                  `${moment(state.job.tasks[0].start_at).format(
                    'YYYY-MM-DD'
                  )} ${moment(startAt).format('HH:mm:ss')}`
                ),
              end_at:
                action.isSelected &&
                moment(
                  `${moment(state.job.tasks[0].start_at).format(
                    'YYYY-MM-DD'
                  )} ${moment(endAt).format('HH:mm:ss')}`
                )
            }
          }
        };
      } else {
        return {
          ...state,
          job: {
            ...state.job,
            is_briefing_time_selected: action.isSelected,
            briefing_time: {
              start_at:
                action.isSelected && moment(state.job.tasks[0].start_at),
              end_at:
                action.isSelected &&
                moment(state.job.tasks[0].start_at)
                  .add(60, 'minute')
                  .startOf('minute')
            }
          }
        };
      }
    case `${reducerType}_SELECT_BREAK_TIME`:
      const startAtBreakTime =
        state.job && state.job.break_time && state.job.break_time.start_at;
      const endAtBreakTime =
        state.job && state.job.break_time && state.job.break_time.end_at;

      if (startAtBreakTime) {
        return {
          ...state,
          job: {
            ...state.job,
            break_time: {
              start_at:
                action.isSelected &&
                moment(
                  `${moment(state.job.tasks[0].start_at).format(
                    'YYYY-MM-DD'
                  )} ${moment(startAtBreakTime).format('HH:mm:ss')}`
                ),
              end_at:
                action.isSelected &&
                moment(
                  `${moment(state.job.tasks[0].start_at).format(
                    'YYYY-MM-DD'
                  )} ${moment(endAtBreakTime).format('HH:mm:ss')}`
                )
            }
          }
        };
      } else {
        return {
          ...state,
          job: {
            ...state.job,
            break_time: {
              start_at:
                action.isSelected && moment(state.job.tasks[0].start_at),
              end_at:
                action.isSelected &&
                moment(state.job.tasks[0].start_at)
                  .add(60, 'minute')
                  .startOf('minute')
            }
          }
        };
      }
    case `${reducerType}_EDIT_TASK`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map(task => {
            if (task.order === action.order) {
              return {
                ...task,
                ...action.updates
              };
            }
            return task;
          })
        }
      };
    case `${reducerType}_EDIT_ALL_LOCATIONS`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map(task => {
            return {
              ...task,
              location: action.updates.location,
              location_lat: action.updates.location_lat,
              location_long: action.updates.location_long
            };
          })
        }
      };
    case `${reducerType}_EDIT_IMAGE`:
      return {
        ...state,
        job: {
          ...state.job,
          images: state.job.images.map(image => {
            if (image.id === action.id) {
              return {
                ...image,
                ...action.updates
              };
            }
            return image;
          })
        }
      };
    case `${reducerType}_UPDATE_ROUTE`:
      return {
        ...state,
        route: action.route
      };
    case `${reducerType}_UPDATE_ORIGINAL_TASK_AND_ROUTE`:
      return {
        ...state,
        original_tasks: action.tasks,
        original_route: action.route
      };
    case `${reducerType}_REMOVE_ORIGINAL_TASK_AND_ROUTE`:
      return {
        ...state,
        original_tasks: undefined,
        original_route: undefined
      };
    case `${reducerType}_ADD_DROPOFF`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: [
            ...state.job.tasks,
            {
              location: undefined,
              lat: undefined,
              'long': undefined,
              order: state.job.tasks.length,
              type: 'Drop off',
              parking: false
            }
          ]
        }
      };
    case `${reducerType}_ADD_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: [
            ...state.job.tasks,
            {
              name: 'Additional shift',
              location: state.job.tasks[0].location,
              location_lat: state.job.tasks[0].location_lat,
              location_long: state.job.tasks[0].location_long,
              start_at: moment(
                state.job.tasks[state.job.tasks.length - 1].start_at
              ).add(24, 'hours'),
              end_at: moment(
                state.job.tasks[state.job.tasks.length - 1].end_at
              ).add(24, 'hours'),
              order: state.job.tasks.length,
              type: undefined,
              parking: true
            }
          ]
        }
      };
    case `${reducerType}_UPDATE_BANK`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map(task => {
            if (task.order !== 0) {
              return {
                ...task,
                ...action.updates
              };
            }
            return task;
          })
        }
      };
    case `${reducerType}_ADD_BANK`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: [
            ...state.job.tasks,
            {
              name: action.name,
              bank_id: action.bank_id,
              location: 'Let GoGetter decide',
              order: state.job.tasks.length,
              type: undefined,
              parking: true,
              start_at: moment(state.job.tasks[0].start_at).add(15, 'minute'),
              end_at: moment(state.job.tasks[0].end_at).add(90, 'minute')
            }
          ]
        }
      };
    case `${reducerType}_EDIT_JOINED_START_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map(task => {
            if (task.order === action.order) {
              return {
                ...task,
                start_at: action.startAt,
                end_at: moment(
                  `${moment(action.startAt).format('YYYY-MM-DD')} ${moment(
                    task.end_at
                  ).format('HH:mm:ss')}`
                )
              };
            }
            return task;
          })
        }
      };
    case `${reducerType}_EDIT_JOINED_END_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map(task => {
            if (task.order === action.order) {
              return {
                ...task,
                start_at: moment(
                  `${moment(action.endAt).format('YYYY-MM-DD')} ${moment(
                    task.start_at
                  ).format('HH:mm:ss')}`
                ),
                end_at: action.endAt
              };
            }
            return task;
          })
        }
      };
    case `${reducerType}_REMOVE_TASK`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.filter(
            ({ order }) => order !== parseInt(action.order)
          )
        }
      };
    case `${reducerType}_RESET_TASK_ORDER_COUNT`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map((task, index) => {
            return {
              ...task,
              order: index
            };
          })
        }
      };
    case `${reducerType}_EDIT_ITEM`:
      return {
        ...state,
        job: {
          ...state.job,
          item: {
            ...state.job.item,
            ...action.item
          }
        }
      };
    case `${reducerType}_EDIT_VEHICLE`:
      return {
        ...state,
        job: {
          ...state.job,
          ride_id: action.ride_id
        }
      };
    case `${reducerType}_EDIT_PAYMENT`:
      return {
        ...state,
        isUserSelectedPaymentMethod: action.isUserSelectedPaymentMethod,
        job: {
          ...state.job,
          payment_method: action.payment_method
        }
      };
    case `${reducerType}_EDIT_NOTES`:
      return {
        ...state,
        job: {
          ...state.job,
          notes: action.notes
        }
      };
    case `${reducerType}_ADD_TEMPORARY_TASK`:
      return {
        ...state,
        job: {
          ...state.job,
          temporary_task: action.temporaryTask
        }
      };
    case `${reducerType}_JOIN_TEMPORARY_TASK`:
      return {
        ...state,
        job: {
          ...state.job,
          temporary_task: undefined,
          tasks: [...state.job.tasks, action.update]
        }
      };
    case `${reducerType}_EDIT_RECEIPT_RETURN_METHOD`:
      return {
        ...state,
        job: {
          ...state.job,
          receipt_return_method: action.receipt_return_method
        }
      };
    case `${reducerType}_EDIT_HELPER_TYPE`:
      return {
        ...state,
        job: {
          ...state.job,
          helper_type: action.helper_type
        }
      };
    case `${reducerType}_EDIT_PERKS`:
      return {
        ...state,
        job: {
          ...state.job,
          perks: action.perks
        }
      };
    case `${reducerType}_EDIT_GOGETTER_REQUIREMENTS`:
      return {
        ...state,
        job: {
          ...state.job,
          gogetter_requirements: {
            ...state.job.gogetter_requirements,
            ...action.gogetter_requirements
          }
        }
      };
    case `${reducerType}_EDIT_LANGUAGE_PROFICIENCY`:
      return {
        ...state,
        job: {
          ...state.job,
          gogetter_requirements: {
            ...state.job.gogetter_requirements,
            ...action.updates
          }
        }
      };
    case `${reducerType}_FINAL_FEE`:
      return {
        ...state,
        job: {
          ...state.job,
          fee: action.fee
        }
      };
    case `${reducerType}_FEE_CALC`:
      return {
        ...state,
        job: {
          ...state.job,
          fee_calc: action.feeCalc
        }
      };
    case `${reducerType}_SET_FEE_BREAKDOWN`:
      const responseFeeBreakdown = { ...state.feeBreakdown };
      responseFeeBreakdown[action.serviceType] = action.feeBreakdown;
      return {
        ...state,
        feeBreakdown: responseFeeBreakdown
      };
    case `${reducerType}_SET_HELPER_FEE_BREAKDOWN`:
      return {
        ...state,
        job: {
          ...state.job,
          total_fee: action.totalFee.suggested,
          no_of_jobs: action.noOfJobs,
          hourly_fee: action.hourlyFee,
          schedulesNew: action.schedules,
          fees: {
            minimumFee: action.hourlyFee?.minimum,
            suggestedFee: action.hourlyFee?.suggested,
            adjustedFee:
              action.hourlyFee?.adjusted_fee || action.hourlyFee?.adjusted // check with Kings why sometimes adjust and adjusted, kings said it is always adjusted
          }
        },
        fees: {
          suggestedFee: action.totalFee.suggested,
          finalFee: action.totalFee.suggested,
          min_fee: action.totalFee.minimum,
          surge_fee: action.totalFee.surge_fee
        },
        feeBreakdown: {
          helper: {
            final:
              action.hourlyFee.adjusted_fee ||
              action.hourlyFee?.adjusted ||
              action.hourlyFee.suggested,
            total: action.hourlyFee.suggested,
            surge_fee: action.totalFee.surge_fee,
            min_fee: action.hourlyFee.minimum,
            adjustedFee:
              action.hourlyFee?.adjusted_fee || action.hourlyFee?.adjusted // check with Kings why sometimes adjust and adjusted
          }
        }
      };
    case `${reducerType}_ADJUST_FEE`:
      const currentFeeBreakdown = { ...state.feeBreakdown };
      currentFeeBreakdown[state.job.service_type] = {
        ...state.feeBreakdown[state.job.service_type],
        'final': action.fee
      };
      return {
        ...state,
        feeBreakdown: currentFeeBreakdown
      };
    case `${reducerType}_SET_ADDITIONAL_FEE_AND_UPDATE_TOTAL_FEE`:
      return {
        ...state,
        job: {
          ...state.job,
          additional_fee: action.additional_fee
        }
      };
    case `${reducerType}_SET_SERVICE_TYPE`:
      return {
        ...state,
        job: {
          ...state.job,
          service_type: action.service_type
        }
      };
    case `${reducerType}_SET_GOGETTER_SELECTION`:
      return {
        ...state,
        job: {
          ...state.job,
          blast_pool: action.blast_pool,
          gogetter_pool:
            action.blast_pool === GOGETTER_SELECTION_ANYONE
              ? GOGETTER_POOL_RELIABLE
              : GOGETTER_POOL_ALL,
          selected_gogetter_id: action.selected_gogetter_id,
          gogetter_training_module_id: action.gogetter_training_module_id,
          // RESET FEES ON GOGETTER SELECTION
          fees:
            reducerType === 'PART_TIMER' || reducerType === 'PERSONAL_HELPER'
              ? {}
              : state.job.fees
        },
        // RESET FEES ON GOGETTER SELECTION
        fees:
          reducerType === 'PART_TIMER' || reducerType === 'PERSONAL_HELPER'
            ? {}
            : state.fees,
        feeBreakdown:
          reducerType === 'PART_TIMER' || reducerType === 'PERSONAL_HELPER'
            ? {}
            : state.feeBreakdown
      };
    case `${reducerType}_SET_AUTO_APPROVE`:
      return {
        ...state,
        job: {
          ...state.job,
          auto_approve: action.auto_approve
        }
      };
    case `${reducerType}_SET_EGG_VALIDATION`:
      return {
        ...state,
        eggValidation: {
          ...state.eggValidation,
          ...action.eggValidation
        }
      };
    case `${reducerType}_SET_FORM_ERRORS`:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.formErrors
        }
      };
    case `${reducerType}_SET_FORM_LOADING`:
      return {
        ...state,
        formLoading: action.isLoading
      };
    case `${reducerType}_SET_ROUTING`:
      return {
        ...state,
        routing: action.routing
      };
    case `${reducerType}_SET_SHORTEST_ROUTING`:
      return {
        ...state,
        shortestRouting: action.routing
      };
    case `${reducerType}_START_JOB_CREATE`:
      return {
        ...state,
        creatingJob: true
      };
    case `${reducerType}_END_JOB_CREATE`:
      return {
        ...state,
        creatingJob: false
      };
    case `${reducerType}_START_FETCH_FEE`:
      return {
        ...state,
        fetchingFee: true
      };
    case `${reducerType}_END_FETCH_FEE`:
      return {
        ...state,
        fetchingFee: false
      };
    case `${reducerType}_SET_FIRST_ENTRY`:
      return {
        ...state,
        firstEntry: true
      };
    case `${reducerType}_DISMISS_FIRST_ENTRY`:
      return {
        ...state,
        firstEntry: false
      };
    case `${reducerType}_UPDATE_DISTANCE`:
      return {
        ...state,
        job: {
          ...state.job,
          distance_covered_m: action.distance_covered_m
        }
      };
    case `${reducerType}_RESET_STATE`:
      return generateCreateDefaultState(reducerType);
    case `${reducerType}_ADD_PROMO`:
      return {
        ...state,
        job: {
          ...state.job,
          promo_code: action.promo_code
        }
      };
    case `${reducerType}_REMOVE_PROMO`:
      return {
        ...state,
        job: {
          ...state.job,
          promo_code: undefined
        }
      };
    case `${reducerType}_START_CHECK_PROMO`:
      return {
        ...state,
        promoLoading: true
      };
    case `${reducerType}_END_CHECK_PROMO`:
      return {
        ...state,
        promoLoading: false
      };
    case `${reducerType}_ENTER_SUMMARY`:
      return {
        ...state,
        reachedSummary: true
      };
    case `${reducerType}_RESET_SUMMARY`:
      return {
        ...state,
        reachedSummary: false
      };
    case `${reducerType}_SET_USER_SELECTED_VEHICLE`:
      return {
        ...state,
        userSelectedVehicle: true
      };
    case `${reducerType}_RESET_USER_SELECTED_VEHICLE`:
      return {
        ...state,
        userSelectedVehicle: false
      };
    case `${reducerType}_SET_DELIVER_NOW`:
      return {
        ...state,
        deliverNow: action.deliverNow
      };
    case `${reducerType}_SET_NON_FLEXI_TASKS`:
      return {
        ...state,
        job: {
          ...state.job,
          nonFlexiTasks: state.job.tasks
        }
      };
    case `${reducerType}_UPDATE_SHOP`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: state.job.tasks.map(task => {
            if (task.order === 0) {
              return {
                ...task,
                location: action.updates.store_details.location,
                location_notes: action.updates.store_details.location_notes,
                area: action.updates.store_details.area,
                mall: action.updates.store_details.mall,
                location_lat: action.updates.store_details.location_lat,
                location_long: action.updates.store_details.location_long
              };
            }
            return task;
          })
        },
        shop: action.updates
      };
    case `${reducerType}_UPDATE_DELIVERY_TYPE`:
      return {
        ...state,
        job: {
          ...state.job,
          category_type: action.deliveryType,
          promo_code: undefined,
          tasks: state.job.tasks.map(task => {
            if (task.order === 1 && state.shop.collection_point.location) {
              if (action.deliveryType === 'store_drivethru') {
                return {
                  ...task,
                  location: state.shop.collection_point.location,
                  location_notes: state.shop.collection_point.location_notes,
                  location_lat: state.shop.collection_point.location_lat,
                  location_long: state.shop.collection_point.location_long,
                  start_at: undefined,
                  end_at: undefined
                };
              } else {
                return {
                  ...task,
                  location: undefined,
                  location_notes: undefined,
                  location_lat: undefined,
                  location_long: undefined,
                  start_at: undefined,
                  end_at: undefined
                };
              }
            } else {
              return {
                ...task,
                start_at: undefined,
                end_at: undefined
              };
            }
          })
        }
      };
    case `${reducerType}_SET_DELIVERY_TYPE`:
      return {
        ...state,
        deliveryType: action.deliveryType
      };
    case `${reducerType}_SET_FIRST_RENDER`:
      return {
        ...state,
        isFirstRender: action.isFirstRender
      };
    case `${reducerType}_ADD_SCHEDULE`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: [
            ...state.job.schedules,
            {
              fee: undefined,
              order:
                state.job.schedules[state.job.schedules.length - 1].order + 1,
              no_of_gogetters: 1,
              fee_calcs: [{}],
              days: [],
              start_at: undefined,
              end_at: undefined
            }
          ]
        }
      };
    case `${reducerType}_REMOVE_SCHEDULE`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: state.job.schedules.filter(
            ({ order }) => order !== parseInt(action.order)
          )
        }
      };
    case `${reducerType}_EDIT_SCHEDULE`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: state.job?.schedules?.map(schedule => {
            if (schedule.order === action.order) {
              return {
                ...schedule,
                ...action.updates
              };
            }
            return schedule;
          })
        }
      };
    case `${reducerType}_UPDATE_JOB_FEES`:
      return {
        ...state,
        job: {
          ...state.job,
          fees: {
            ...action.updates
          }
        }
      };
    case `${reducerType}_UPDATE_TASKS`:
      return {
        ...state,
        job: {
          ...state.job,
          tasks: [...action.tasks]
        }
      };
    case `${reducerType}_UPDATE_SMS_PREFERENCE`:
      return {
        ...state,
        job: {
          ...state.job,
          sms: action.value
        }
      };
    case `${reducerType}_ADD_BREAK_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: state.job?.schedules?.map(schedule => {
            if (schedule.order === action.order) {
              return {
                ...schedule,
                break_times: [
                  ...schedule.break_times,
                  { start_at: null, end_at: null }
                ]
              };
            }
            return schedule;
          })
        }
      };
    case `${reducerType}_REMOVE_BREAK_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: state.job?.schedules?.map(schedule => {
            if (schedule.order === action.order) {
              return {
                ...schedule,
                break_times: [schedule.break_times[0]]
              };
            }
            return schedule;
          })
        }
      };
    case `${reducerType}_RESET_BREAK_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: state.job?.schedules?.map(schedule => {
            if (schedule.order === action.order) {
              return {
                ...schedule,
                break_times: []
              };
            }
            return schedule;
          })
        }
      };
    case `${reducerType}_SET_BREAK_TIME`:
      return {
        ...state,
        job: {
          ...state.job,
          schedules: state.job?.schedules?.map(schedule => {
            if (schedule.order === action.order) {
              return {
                ...schedule,
                break_times: schedule.break_times?.map((breakTime, index) => {
                  if (index === action.index) {
                    return { ...breakTime, [action.breakTime]: action.time };
                  }
                  return breakTime;
                })
              };
            }
            return schedule;
          })
        }
      };
    case `${reducerType}_SET_GOGETTER_POOL`:
      return {
        ...state,
        job: {
          ...state.job,
          gogetter_pool: action.preference
        }
      };
    default:
      return state;
  }
};

export default createJobDefaultReducer;
