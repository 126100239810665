import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Avatar from '@mui/material/Avatar';
import styled from 'styled-components/macro';
import Badge from '@mui/material/Badge';

import { getCookie } from '../libraries/authentication';

// icons
import HomeIcon from '@mui/icons-material/Home';
import MyJobsIcon from '@mui/icons-material/Work';
import NotificationIcon from '@mui/icons-material/Notifications';
import AccountIcon from '@mui/icons-material/AccountCircle';

import colors from '../style/colors';
import fontSize from '../style/fontSize';

const StyledBadge = styled(Badge)`
  span:last-child {
    background-color: ${colors.red} !important;
    height: 8px !important;
    width: 8px !important;
    left: 13px;
    top: 0px;
  }
`;
const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 100 !important;
  border-top: 1px solid ${colors.greyLight} !important;
  height: 61px !important;
`;
const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  min-width: 0px !important;
  .MuiBottomNavigationAction-label {
    font-size: ${fontSize.regular} !important;
    margin-top: 4px;
  }
`;
const StyledAvatar = styled(Avatar)`
  width: 26px !important;
  height: 26px !important;
`;

const Tab = ({ history, mobile, userAvatar, unreadCount }) => {
  const getJobsUrl = () => {
    if (history.location.pathname === '/jobs') {
      return '/jobs';
    } else if (history.location.pathname === '/hiring_posts') {
      return '/hiring_posts';
    }
  };

  // TODO: Cordova is being Cordova and doesn't push to '/' so need to use replace.
  const handlePushHome = () => {
    history.replace('/');
  };

  return (
    <>
      {mobile && (
        <StyledBottomNavigation value={history.location.pathname} showLabels>
          <StyledBottomNavigationAction
            label="Create Job"
            value="/"
            icon={<HomeIcon />}
            onClick={handlePushHome}
            // component={Link}
            // to="/"
          />
          <StyledBottomNavigationAction
            label="My Jobs"
            value={getJobsUrl()}
            icon={<MyJobsIcon />}
            component={Link}
            to={
              getCookie('isShowingGoGetRecruit') === 'true'
                ? '/hiring_posts'
                : '/jobs'
            }
          />
          <StyledBottomNavigationAction
            label="Notification"
            value="/notifications"
            icon={
              unreadCount !== 0 ? (
                <StyledBadge variant="dot" color="primary">
                  <NotificationIcon />
                </StyledBadge>
              ) : (
                <NotificationIcon />
              )
            }
            component={Link}
            to="/notifications"
          />
          <StyledBottomNavigationAction
            id="my-account-mb"
            label="Profile"
            value="/profile"
            icon={
              userAvatar ? (
                <StyledAvatar alt="image" src={userAvatar} />
              ) : (
                <AccountIcon />
              )
            }
            component={Link}
            to="/profile"
          />
        </StyledBottomNavigation>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    unreadCount: state.notification && state.notification.unreadCount,
    userAvatar: state.user && state.user.profile && state.user.profile.avatar
  };
};

export default connect(mapStateToProps)(Tab);
