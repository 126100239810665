import { enqueueSnackbar } from 'notistack';
import { routeToLocation } from '../../api/routing';
import { ROUTING_SETTING_CONFIG } from '../../components/maps/constants';
import { generateShapeFromLegs } from './generateShapeFromLegs';
import { isValidRoute } from './isValidRoute';

const NO_TRIP_LEGS = 0;
const KM_TO_M_MULTIPLIER = 1000;

class NewRoutingService {
  constructor({
    dispatch,
    locations,
    updateRoute,
    setRouting,
    updateDistance,
    rideId,
    serviceType,
    getFee,
    calculateTimeRange,
    categoryType
  }) {
    this.locations = locations;
    this.dispatch = dispatch;
    this.updateRoute = updateRoute;
    this.setRouting = setRouting;
    this.updateDistance = updateDistance;
    this.rideId = rideId;
    this.serviceType = serviceType;
    this.getFee = getFee;
    this.calculateTimeRange = calculateTimeRange;
    this.categoryType = categoryType;
  }

  route = async () => {
    const requestObject = {
      locations: this.locations?.map(location => ({
        lat: location?.location_lat,
        lon: location?.location_long
      })),
      ...ROUTING_SETTING_CONFIG
    };

    const isRoutable = isValidRoute(requestObject.locations);

    if (!isRoutable) return;

    const json = encodeURI(JSON.stringify(requestObject));

    const response = await routeToLocation(json);

    if (response?.isSuccess && response?.data) {
      this.onRouteResult(response?.data);
    }
  };

  onRouteResult = result => {
    if (result?.trip?.legs && result?.trip?.legs?.length > NO_TRIP_LEGS) {
      const route = result?.trip;
      route.shape = generateShapeFromLegs(result?.trip?.legs);
      this.dispatch(this.updateRoute(route));
      this.dispatch(
        this.updateDistance(route?.summary?.length * KM_TO_M_MULTIPLIER)
      );
      this.dispatch(
        this.calculateTimeRange({
          locations: this.locations,
          route,
          serviceType: this.serviceType,
          categoryType: this.categoryType
        })
      );
      this.dispatch(this.setRouting(false));
      this.dispatch(this.getFee());
    } else {
      enqueueSnackbar(
        'Unable to route to this location. Please select a different location',
        {
          variant: 'error'
        }
      );
    }
  };
}
export default NewRoutingService;
