import moment from 'moment';
import _ from 'lodash';
import { updateTracker } from '../../actions/gogetterTracking';
import { getGogetterTracking } from '../../api/tracking';
import { ROUTING_SETTING_CONFIG } from '../../components/maps/constants';
import { routeToLocation } from '../../api/routing';
import { decodePolyline } from '../../libraries/polylineDecoder';
import { getMinMaxTime } from './getMinMaxTime';
import { isValidRoute } from './isValidRoute';

class GogetterTrackingService {
  constructor(dispatch, getState, job_id) {
    this.dispatch = dispatch;
    this.getState = getState;
    this.job_id = job_id;
  }

  shouldBeTracked() {
    const hasTracking = !!this.getState().gogetterTracking.jobs[this.job_id];
    const isInProgress =
      !!this.getState().jobs.items[this.job_id] &&
      this.getState().jobs.items[this.job_id].status === 'in_progress';
    const isAccepted =
      !!this.getState().jobs.items[this.job_id] &&
      this.getState().jobs.items[this.job_id].status === 'approved';
    const hasTasks =
      isAccepted &&
      this.getState().jobs.items[this.job_id].tasks &&
      this.getState().jobs.items[this.job_id].tasks.length > 0;
    let startsWithinTwentyMins = false;

    if (isAccepted && hasTasks) {
      const now = moment();
      const startAt = this.getState().jobs.items[this.job_id].tasks[0].start_at;
      startsWithinTwentyMins = now.isSameOrAfter(
        moment(startAt).subtract(20, 'minutes')
      );
    }

    if (hasTracking || isInProgress || startsWithinTwentyMins) {
      return true;
    } else {
      return false;
    }
  }

  route = async () => {
    if (this.shouldBeTracked()) {
      const response = await getGogetterTracking(this.job_id);

      if (response?.isSuccess && response?.data) {
        this.gogetterLocation = response?.data?.gogetter_location;
        this.nextTaskOrder = response?.data?.next_task;

        if (
          !response?.data?.trackable &&
          !!this.getState().gogetterTracking.jobs[this.job_id]
        ) {
          this.dispatch(updateTracker(this.job_id, null));
        } else if (
          !this.getState().gogetterTracking.jobs[this.job_id] ||
          !_.isEqual(
            this.gogetterLocation,
            this.getState().gogetterTracking.jobs[this.job_id].gogetterLocation
          ) ||
          this.nextTaskOrder !==
            this.getState().gogetterTracking.jobs[this.job_id].nextTaskOrder
        ) {
          const nextTask =
            this.getState().jobs?.items &&
            this.getState().jobs?.items[this.job_id] &&
            this.getState().jobs?.items[this.job_id].tasks?.find(
              task => task.order === this.nextTaskOrder
            );

          const requestObject = {
            locations: [
              {
                lat: this.gogetterLocation?.lat,
                lon: this.gogetterLocation?.long
              },
              {
                lat: nextTask?.location_lat,
                lon: nextTask?.location_long
              }
            ],
            ...ROUTING_SETTING_CONFIG
          };

          const isRoutable = isValidRoute(requestObject.locations);

          if (!isRoutable) return;

          const json = encodeURI(JSON.stringify(requestObject));

          const response = await routeToLocation(json);

          if (response?.isSuccess && response?.data) {
            this.onRouteResult(response?.data);
          }
        }
      }
    }
  };

  onRouteResult = result => {
    if (result?.trip?.legs && result?.trip?.legs?.length > 0) {
      // Pick the first route from the response:
      const route = result?.trip?.legs?.[0];
      const routeShape = decodePolyline(route?.shape, false);

      const eta = getMinMaxTime(route?.summary?.time);

      const tracker = {
        gogetterLocation: this.gogetterLocation,
        nextTaskOrder: this.nextTaskOrder,
        routeShape,
        eta
      };
      this.dispatch(updateTracker(this.job_id, tracker));
    }
  };
}
export default GogetterTrackingService;
