import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loaded: false,
  teams: [],
  activeId: null,
  currentPage: 'teams',
  showHeaderTrained: true
};

const trainedReducer = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    updateTeams: (state, action) => {
      state.loaded = action.payload.loaded;
      state.teams = action.payload.teams;
    },
    setLoadingTeams: state => {
      state.loaded = false;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setTrainedPage: (state, action) => {
      state.showHeaderTrained = action.payload.showHeaderTrained;
      state.currentPage = action.payload.currentPage;
    }
  }
});

export const { updateTeams, setLoadingTeams, setActiveId, setTrainedPage } =
  trainedReducer.actions;

export default trainedReducer.reducer;
