import React, { useEffect, useState } from 'react';
import compareVersions from 'compare-versions';
import styled from 'styled-components/macro';

import Button from '@mui/material/Button';

import Heart from '@mui/icons-material/Favorite';
import TouchApp from '@mui/icons-material/TouchApp';

import colors from '../style/colors';
import fontSize from '../style/fontSize';

import BarChart from '../assets/icons/barChart.svg';
import GoGetIcon from './assets/gogetIconBlack.svg';

const FullWindowOverlay = styled.div`
  box-sizing: border-box;
  height: calc(100vh - 20px);
  width: 100vw;
  background-color: ${colors.white};
  display: flex;
  z-index: 99999;
`;

const ContentWrap = styled.div`
  box-sizing: border-box;
  padding: 2.25rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.img`
  margin: 0;
`;

const Content = styled.div`
  box-sizing: border-box;
  flex: 1;
`;

const Title = styled.div`
  box-sizing: border-box;
  font-size: ${fontSize.oneHalfRem};
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const PerkWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-top: 2rem;
`;

const PerkText = styled.div`
  box-sizing: border-box;
  flex: 1;
  margin-left: 1rem;
  font-size: ${fontSize.xxLarge};
  font-weight: 600;
`;

const Reminder = styled.div`
  box-sizing: border-box;
  font-size: ${fontSize.xLarge};
  font-weight: 400;
  margin-top: 2rem;
`;

const IconWrap = styled.div`
  box-sizing: border-box;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  background-color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${colors.white};
  }
`;

const CtaWrap = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  height: 52px !important;
  background-color: ${colors.primary}!important;
  font-size: ${fontSize.large}!important;
  font-weight: 500 !important;
`;

const AppTrackingHandler = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);

  const setupTrackingPermissionIOS = async () => {
    const { idfa } = window.cordova.plugins;

    await idfa.getInfo().then(info => {
      if (info.trackingPermission === idfa.TRACKING_PERMISSION_NOT_DETERMINED) {
        setShowPopup(true);
      } else if (
        info.trackingPermission === idfa.TRACKING_PERMISSION_AUTHORIZED
      ) {
        window.trackingPermitted = true;
      } else {
        window.analytics.reset();
        window.trackingPermitted = false;
      }
    });
  };

  const handleContinue = () => {
    const { idfa } = window.cordova.plugins;

    idfa.requestPermission().then(result => {
      if (result === idfa.TRACKING_PERMISSION_AUTHORIZED) {
        setShowPopup(false);
        window.trackingPermitted = true;
      } else {
        setShowPopup(false);
        window.analytics.reset();
        window.trackingPermitted = false;
      }
    });
  };

  useEffect(() => {
    if (window.cordova) {
      if (
        window.device.version &&
        compareVersions.compare(window.device.version, '14.5', '>=')
      ) {
        setupTrackingPermissionIOS();
      } else {
        window.trackingPermitted = true;
      }
    } else {
      window.trackingPermitted = true;
    }
  }, []);

  return (
    <>
      {showPopup ? (
        <FullWindowOverlay>
          <ContentWrap>
            <Header>
              <StyledLogo src={GoGetIcon} alt="GoGet Logo" />
            </Header>
            <Content>
              <Title>Allow analytics tracking on the next screen for:</Title>
              <PerkWrap>
                <IconWrap>
                  <Heart />
                </IconWrap>
                <PerkText>Special offers and promotions just for you</PerkText>
              </PerkWrap>
              <PerkWrap>
                <IconWrap>
                  <TouchApp />
                </IconWrap>
                <PerkText>Advertisements that match your interests</PerkText>
              </PerkWrap>
              <PerkWrap>
                <IconWrap>
                  <img src={BarChart} alt="Bar Chart" />
                </IconWrap>
                <PerkText>
                  An improved personalized experience over time
                </PerkText>
              </PerkWrap>
              <Reminder>
                You can change this later in the Settings app.
              </Reminder>
            </Content>
            <CtaWrap>
              <StyledButton
                color="primary"
                size="large"
                variant="contained"
                onClick={handleContinue}
                fullWidth
              >
                NEXT
              </StyledButton>
            </CtaWrap>
          </ContentWrap>
        </FullWindowOverlay>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default AppTrackingHandler;
