import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Help from '@mui/icons-material/Help';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { getAuthToken } from '../../libraries/authentication';

import HelpSupport from './HelpSupport';

import colors from '../../style/colors';

const StyledButton = styled(Button)`
  && {
    color: ${({ desktop, isRecruitJob }) =>
      desktop
        ? colors.white
        : isRecruitJob
        ? colors.secondary
        : colors.primary};
    background-color: ${({ desktop }) => !desktop && colors.white};
    text-transform: capitalize;
    border-radius: 21px;
    font-weight: 700;
    width: 5rem;
    position: ${props => props.desktop && 'fixed '};
    bottom: ${props => props.desktop && '1rem '};
    right: ${props => props.desktop && '1rem '};
    transition: all 0.3s ease-in-out;

    svg {
      color: ${({ desktop, isRecruitJob }) =>
        !desktop
          ? isRecruitJob
            ? colors.secondary
            : colors.primary
          : null}!important;
    }

    :hover {
      background-color: ${({ desktop }) => !desktop && colors.hoverGrey};
    }
  }
`;
const HelpIcon = styled(Help)`
  color: ${colors.white} !important;
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px !important;
  font-weight: 100 !important;
`;
const ContentWrapper = styled.div`
  padding: 1rem;
`;

const HelpButton = props => {
  const {
    isBusinessAccount,
    desktopLayout,
    isHelperJob,
    isRecruitJob,
    showPopup
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    let userType;
    if (getAuthToken()) {
      if (isBusinessAccount) {
        userType = 'business_account';
      } else {
        userType = 'free_account';
      }
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'help',
      platform: 'pwa'
    });
    setAnchorEl(event.currentTarget);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleClick}
        desktop={desktopLayout}
        disableElevation={!desktopLayout}
        isHelperJob={isHelperJob}
        isRecruitJob={isRecruitJob}
      >
        <HelpIcon />
        Help
      </StyledButton>
      {desktopLayout || showPopup ? (
        <Popover
          onClose={handlePopoverClose}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <ContentWrapper>
            <HelpSupport
              isHelperJob={isHelperJob}
              isRecruitJob={isRecruitJob}
            />
          </ContentWrapper>
        </Popover>
      ) : (
        <SwipeableDrawer
          open={openPopover}
          onClose={handlePopoverClose}
          anchor="bottom"
        >
          <ContentWrapper>
            <HelpSupport
              isHelperJob={isHelperJob}
              isRecruitJob={isRecruitJob}
            />
          </ContentWrapper>
        </SwipeableDrawer>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isBusinessAccount:
      state.user && state.user.profile && state.user.profile.is_business_account
  };
};

export default connect(mapStateToProps)(HelpButton);
