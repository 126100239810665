import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import styled from 'styled-components/macro';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Div100vh from 'react-div-100vh';
import { push } from 'connected-react-router';
import { logIn } from '../../actions/auth';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import CtaButton from '../ui/CtaButton';
import colors from '../../style/colors';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const TextFieldWrapper = styled.div`
  margin-top: 24px;
`;
const StyledTextField = styled(TextField)`
  > div {
    padding-right: unset;
  }
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;
const ForgotPassLink = styled.div`
  color: ${colors.primary};
  cursor: pointer;
`;
const LinkWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

class Login extends Component {
  state = {
    email: '',
    password: '',
    showPassword: false
  };

  componentDidMount() {
    const { location } = this.props;

    if (location && location.state && location.state.email) {
      this.setState({
        email: location.state.email
      });
    }

    window.analyticsPage('login', { platform: 'pwa' });
    this.email.focus();
  }

  handleEnterPressed = e => {
    if (e.key === 'Enter') {
      if (e.target.name === 'email') {
        this.password.focus();
      }
      this.handleLogIn();
    }
  };

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleLogIn = () => {
    const { password, email } = this.state;
    if (password && email) {
      this.props.logIn(this.state);
    }
  };

  handleForgotPassword = () => {
    const { history } = this.props;
    const { email } = this.state;
    history.push('/forgot_password', {
      email
    });
  };

  onBack = () => {
    const { history } = this.props;
    history.push('/home');
  };

  render() {
    const { email, password, showPassword } = this.state;
    const { desktopLayout } = this.props;
    const emailValid = !email || isEmail(email);

    return (
      <>
        <FixedHeaderAppBar
          isMobile={!desktopLayout}
          title="Log in"
          onBackClick={this.onBack}
        />
        <Container
          desktopLayout={desktopLayout}
          style={{ height: 'calc(100rvh - 64px)' }}
        >
          <RenderLoginView
            handleOnChange={e => this.handleOnChange(e)}
            email={email}
            emailValid={emailValid}
            handleForgotPassword={this.handleForgotPassword}
            handleEnterPressed={e => this.handleEnterPressed(e)}
            // eslint-disable-next-line no-return-assign
            refPassword={el => (this.password = el)}
            // eslint-disable-next-line no-return-assign
            refEmail={el => (this.email = el)}
            showPassword={showPassword}
            password={password}
            changeShowPasswordState={() =>
              this.setState(state => ({ showPassword: !state.showPassword }))
            }
            handleLogIn={this.handleLogIn}
            desktopLayout={desktopLayout}
          />
        </Container>
      </>
    );
  }
}

function RenderLoginView(props) {
  const {
    handleLogIn,
    handleOnChange,
    handleForgotPassword,
    email,
    emailValid,
    handleEnterPressed,
    refPassword,
    refEmail,
    showPassword,
    password,
    changeShowPasswordState,
    desktopLayout
  } = props;
  return (
    <Card desktopLayout={desktopLayout}>
      <div>
        <TextFieldWrapper>
          <StyledTextField
            name="email"
            onChange={handleOnChange}
            label="Email"
            margin="normal"
            value={email}
            variant="outlined"
            fullWidth
            helperText={
              !emailValid ? 'Please enter a valid email address.' : ''
            }
            error={!emailValid}
            onKeyPress={handleEnterPressed}
            inputRef={refEmail}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <StyledTextField
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={password}
            name="password"
            onKeyPress={handleEnterPressed}
            inputRef={refPassword}
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={changeShowPasswordState}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </TextFieldWrapper>
        <LinkWrapper>
          <ForgotPassLink onClick={handleForgotPassword}>
            Forgot Password?
          </ForgotPassLink>
        </LinkWrapper>
      </div>
      <BtnWrapper onClick={handleLogIn}>
        <CtaButton disabled={!password}>LOG IN</CtaButton>
      </BtnWrapper>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    state
  };
};

const mapDispatchToProps = dispatch => ({
  logIn: obj => dispatch(logIn(obj)),
  push: () => dispatch(push())
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
