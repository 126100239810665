// REF: https://gogetmy.atlassian.net/wiki/spaces/DevDocs/pages/1107394573/Calculating+Time+Range+from+Valhalla+to+buffer+traffic
export const getMinMaxTime = timeInSeconds => {
  let minMultiplier;
  let maxMultiplier;

  const minTimeMultiplier = 1.67 + -0.13 * Math.log(timeInSeconds / 60);
  const maxTimeMultiplier = 2.39 + -0.25 * Math.log(timeInSeconds / 60);

  if (minTimeMultiplier < 1) {
    minMultiplier = 1;
  } else {
    minMultiplier = minTimeMultiplier;
  }

  if (maxTimeMultiplier < 1) {
    maxMultiplier = 1;
  } else {
    maxMultiplier = maxTimeMultiplier;
  }

  const minTime = Math.ceil(timeInSeconds * minMultiplier);
  const maxTime = Math.ceil(timeInSeconds * maxMultiplier);

  return { minTime, maxTime };
};
