import React from 'react';
import styled from 'styled-components/macro';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import GogetterAppScreenshot from '../assets/GogetterAppScreenshot.png';
import AppStoreDownload from '../assets/AppStoreDownload.svg';
import PlayStoreDownload from '../assets/PlayStoreDownload.svg';

import fontSize from '../../style/fontSize';
import colors from '../../style/colors';

const StyledDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 1rem;
  }
`;
const Container = styled.div`
  overflow-y: scroll;
  background-color: ${colors.primaryBackground};
  color: ${colors.black};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 32px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
  color: ${colors.black};
`;
const Body = styled.div`
  padding: 0 1rem;
  padding-top: 0.5rem;
`;
const Title = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 700;
  margin-bottom: 1rem;
`;
const SubTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 700;
`;
const Text = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 1rem;
`;
const ScreenshotWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;
const ScreenshotImg = styled.img`
  max-height: 350px;
  height: 100%;
`;
const DownloadButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 2rem;
  padding-right: 2rem;
`;
const DownloadButtonImg = styled.img`
  width: 160px;
`;

export const CLOSE_BUTTON_TEST_ID = 'gogetter-dialog-close';
export const APPSTORE_LINK_TEST_ID = 'appstore-link';
export const PLAYSTORE_LINK_TEST_ID = 'playstore-link';

const DialogGogetterApp = props => {
  const { open, onClose } = props;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Container>
        <Header>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            data-testid={CLOSE_BUTTON_TEST_ID}
          >
            <CloseIcon />
          </IconButton>
        </Header>
        <Body>
          <Title>GoGetter App</Title>
          <SubTitle>The app to earn in your free time.</SubTitle>
          <ScreenshotWrapper>
            <ScreenshotImg alt="" src={GogetterAppScreenshot} />
          </ScreenshotWrapper>
          <Text>Download now:</Text>
        </Body>
        <DownloadButtonsWrapper>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/my/app/gogetter-part-timer-community/id1483160371"
            data-testid={APPSTORE_LINK_TEST_ID}
          >
            <DownloadButtonImg alt="" src={AppStoreDownload} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=my.goget.gogetter&hl=en"
            data-testid={PLAYSTORE_LINK_TEST_ID}
          >
            <DownloadButtonImg alt="" src={PlayStoreDownload} />
          </a>
        </DownloadButtonsWrapper>
      </Container>
    </StyledDialog>
  );
};
export default DialogGogetterApp;
