import moment from 'moment';
import { DEFAULT_GOGETTER_REQUIREMENTS } from './constants/createJob';
import { DEFAULT_GOGETTER_POOL } from '../constants';

import { pickupWindow } from '../libraries/constants';
import toNearestFifteen from '../services/dateTimeFormat/toNearestFifteen';

const generateCreateDefaultState = reducerType => {
  const paymentMethod = 'credits';

  const currentTime = moment();
  const defaultState = {
    modifyTask: undefined,
    modifyTaskPage: undefined,
    job: {
      images: [
        {
          id: 0
        },
        {
          id: 1
        },
        {
          id: 2
        }
      ],
      promo_code: undefined,
      ride_id: undefined,
      payment_method: paymentMethod,
      notes: '',
      service_type: undefined,
      blast_pool: 'anyone',
      gogetter_pool: DEFAULT_GOGETTER_POOL,
      selected_gogetter_id: null,
      gogetter_training_module_id: null,
      auto_approve: true,
      distance_covered_m: undefined
    },
    errors: {
      gogetterSelection: []
    },
    formLoading: false,
    routing: false,
    fetchingFee: false,
    creatingJob: false,
    firstEntry: false,
    promoLoading: false,
    deliveryType: 'immediate'
  };
  // eslint-disable-next-line
  switch (reducerType) {
    case 'DISPATCH':
      defaultState.page = 'locations';
      defaultState.job.tasks = [
        {
          name: undefined,
          location: undefined,
          location_lat: undefined,
          location_long: undefined,
          order: 0,
          type: 'Pick up',
          start_at: moment(currentTime)
            .add(pickupWindow.intervalStart + 1, 'minute')
            .startOf('minute'),
          end_at: moment(currentTime)
            .add(pickupWindow.intervalEnd + 1, 'minute')
            .startOf('minute'),
          parking: false,
          location_notes: '',
          sender_name: undefined,
          sender_email: undefined,
          recipient_name: undefined,
          recipient_phone_num: undefined
        },
        {
          location: undefined,
          location_lat: undefined,
          location_long: undefined,
          order: 1,
          type: 'Drop off',
          parking: false,
          location_notes: '',
          sender_name: undefined,
          sender_email: undefined,
          recipient_name: undefined,
          recipient_phone_num: undefined
        }
      ];
      defaultState.job.ride_id = 1;
      defaultState.feeBreakdown = {
        dispatch: {
          distance: undefined,
          total: undefined,
          'final': undefined
        },
        egg: {
          distance: undefined,
          total: undefined,
          'final': undefined
        },
        dispatch_flexi: {
          distance: undefined,
          total: undefined,
          'final': undefined
        }
      };
      defaultState.job.item = {
        size: 'small',
        bulky: '1_10kg',
        non_halal: false,
        dispatch_item: 'parcel-document',
        dispatch_item_others: undefined
      };
      defaultState.eggValidation = {
        coverage: true,
        timeRange: true,
        noFavourite: true,
        withinStopLimit: true,
        noCash: true,
        noApproval: true,
        noSUV: true,
        isNotRaining: true
      };
      defaultState.reachedSummary = false;
      defaultState.original_tasks = undefined;
      defaultState.original_route = undefined;
      defaultState.shortestRouting = false;
      defaultState.userSelectedVehicle = false;
      defaultState.job.service_type = 'dispatch';
      defaultState.isFirstRender = true;
      defaultState.job.sms = false;
      break;
    case 'CHEQUE_DEPOSIT':
      defaultState.reachedSummary = false;
      defaultState.page = 'jobDetails';
      defaultState.modifyTaskPage = undefined;
      defaultState.job.service_type = 'helper';
      defaultState.job.helper_type = 'cheque_deposit';
      defaultState.job.receipt_return_method = 'send_a_photo';
      defaultState.feeBreakdown = {
        helper: {
          distance: undefined,
          total: undefined,
          'final': undefined
        }
      };
      defaultState.job.temporary_task = undefined;
      defaultState.job.tasks = [
        {
          name: 'Pick up location',
          id: '',
          location: '',
          location_lat: undefined,
          location_long: undefined,
          order: 0,
          type: 'Search address',
          start_at: toNearestFifteen(
            moment(currentTime).add(5, 'minute').startOf('minute')
          ),
          end_at: toNearestFifteen(
            moment(currentTime).add(90, 'minute').startOf('minute')
          ),
          location_notes: '',
          task_type: undefined,
          parking: true
        }
      ];
      break;
    case 'FOOD_SHOPPING':
      defaultState.eggValidation = { isNotRaining: true };
      defaultState.page = 'description';
      defaultState.feeBreakdown = {
        shopping: {
          distance: undefined,
          total: undefined,
          'final': undefined
        }
      };
      defaultState.job.item = {
        size: 'small',
        bulky: '1_10kg',
        non_halal: false,
        item_price_range: undefined,
        item_price_estimate: undefined
      };
      defaultState.job.tasks = [
        {
          name: '',
          location: undefined,
          location_lat: undefined,
          location_long: undefined,
          order: 0,
          type: 'Pick up',
          start_at: moment(currentTime)
            .add(pickupWindow.intervalStart + 1, 'minute')
            .startOf('minute'),
          end_at: moment(currentTime)
            .add(pickupWindow.intervalEnd + 1, 'minute')
            .startOf('minute'),
          parking: true,
          location_notes: '',
          sender_name: undefined,
          sender_email: undefined,
          recipient_name: undefined,
          recipient_phone_num: undefined,
          let_gogetter_decide: 'buyFromPosterShop',
          is_scheduled_delivery: undefined
        },
        {
          location: undefined,
          location_lat: undefined,
          location_long: undefined,
          order: 1,
          type: 'Drop off',
          parking: false,
          location_notes: '',
          sender_name: undefined,
          sender_email: undefined,
          recipient_name: undefined,
          recipient_phone_num: undefined
        }
      ];
      defaultState.reachedSummary = false;
      defaultState.job.service_type = 'shopping';
      defaultState.userSelectedVehicle = false;
      break;
    case 'PART_TIMER':
      defaultState.reachedSummary = false;
      defaultState.page = 'jobDetails';
      defaultState.modifyTaskPage = undefined;
      defaultState.job.service_type = 'helper';
      defaultState.job.helper_view = 'partTimer';
      defaultState.job.payment_method = 'credits';
      defaultState.job.helper_type = undefined;
      defaultState.job.helper_type_others = undefined;
      defaultState.job.perks = '';
      defaultState.job.gogetter_requirements = {
        ...defaultState.job.gogetter_requirements,
        ...DEFAULT_GOGETTER_REQUIREMENTS,
        typhoid_vaccination: false
      };
      defaultState.feeBreakdown = {
        helper: {
          distance: undefined,
          total: undefined,
          'final': undefined,
          min_fee: undefined,
          surge_fee: undefined,
          adjustedFee: undefined
        }
      };
      defaultState.job.hourly_fee = {
        suggested: undefined,
        adjusted: undefined,
        surge_fee: undefined,
        min_fee: undefined
      };
      defaultState.job.tasks = [
        {
          name: '',
          location: '',
          location_lat: undefined,
          location_long: undefined,
          order: 0,
          type: 'Search address',
          location_notes: '',
          sender_name: undefined,
          sender_email: undefined,
          recipient_name: '',
          recipient_phone_num: '',
          work_environment: '',
          tools_requirement: '',
          transport_notes: '',
          parking: true
        }
      ];
      defaultState.job.fees = {
        suggestedFee: undefined,
        minimumFee: undefined,
        finalFee: undefined
      };
      defaultState.job.schedules = [
        {
          order: 0,
          no_of_gogetters: 1,
          days: [],
          start_at: undefined,
          end_at: undefined,
          break_times: []
        }
      ];
      defaultState.job.item = {
        non_halal: false
      };
      break;
    case 'PERSONAL_HELPER':
      defaultState.reachedSummary = false;
      defaultState.page = 'jobDetails';
      defaultState.job.payment_method = 'credits';
      defaultState.modifyTaskPage = undefined;
      defaultState.job.service_type = 'helper';
      defaultState.job.helper_view = 'personalHelper';
      defaultState.job.helper_type = undefined;
      defaultState.job.helper_type_others = undefined;
      defaultState.job.perks = '';
      defaultState.job.non_halal = false;
      defaultState.job.gogetter_requirements = {
        ...defaultState.job.gogetter_requirements,
        ...DEFAULT_GOGETTER_REQUIREMENTS
      };
      defaultState.job.tasks = [
        {
          name: '',
          location: '',
          location_lat: undefined,
          location_long: undefined,
          order: 0,
          type: 'Search address',
          location_notes: '',
          sender_name: undefined,
          sender_email: undefined,
          recipient_name: '',
          recipient_phone_num: '',
          work_environment: '',
          tools_requirement: '',
          transport_notes: '',
          parking: true
        }
      ];
      defaultState.feeBreakdown = {
        helper: {
          distance: undefined,
          total: undefined,
          'final': undefined,
          min_fee: undefined,
          surge_fee: undefined,
          adjustedFee: undefined
        }
      };
      defaultState.job.hourly_fee = {
        suggested: undefined,
        adjusted: undefined,
        min_fee: undefined,
        surge_fee: undefined
      };
      defaultState.job.fees = {
        suggestedFee: undefined,
        minimumFee: undefined,
        finalFee: undefined
      };
      defaultState.job.schedules = [
        {
          order: 0,
          no_of_gogetters: 1,
          days: [],
          start_at: undefined,
          end_at: undefined
        }
      ];
      break;
    case 'BULK_ORDER':
      defaultState.page = 'introduction';
      break;
  }
  return defaultState;
};
export default generateCreateDefaultState;
