import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  desktopLayout: false,
  isTablet: false
};

const layoutReducer = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    updateDesktopLayout: (state, action) => {
      state.desktopLayout = action.payload;
    },
    updateTabletLayout: (state, action) => {
      state.isTablet = action.payload;
    }
  }
});

export const { updateDesktopLayout, updateTabletLayout } =
  layoutReducer.actions;

export default layoutReducer.reducer;
