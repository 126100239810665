import axios from 'axios';
import { failedResponse, HTTP_STATUS_OK, successResponse } from './apiHelpers';

// Use a seperate instance of axios
const apiClient = axios.create({
  baseURL: import.meta.env.VITE_GOGET_ROUTING_URL,
  headers: {
    'Authorization': `Bearer ${import.meta.env.VITE_GOGET_ROUTING_TOKEN}`
  }
});

export const routeToLocation = async json => {
  if (!json) return failedResponse();
  try {
    const url = `/route?json=${json}`;
    const response = await apiClient.get(url);

    if (response?.status === HTTP_STATUS_OK) {
      return successResponse({
        data: response?.data
      });
    } else {
      return failedResponse();
    }
  } catch (error) {
    return failedResponse();
  }
};
