import React from 'react';
import styled from 'styled-components/macro';

import Divider from '@mui/material/Divider';

import ScrollableTileContainer from './ScrollableTileContainer';
import Tile from './Tile';

import AssembleFurniture from '../../assets/assembleFurniture.svg';
import Dispatch from '../../assets/dispatch.svg';
import Food from '../../assets/food.svg';
import HouseholdChores from '../../assets/householdChores.svg';
import LaundryHelper from '../../assets/laundryHelper.svg';
import PersonalAssistant from '../../assets/dataEntry.svg';
import PetCare from '../../assets/petCare.svg';
import PickAndMove from '../../assets/pickAndMove.svg';
import Plan from '../../assets/plan.svg';

import colors from '../../../style/colors';
import fontSize from '../../../style/fontSize';

const SectionWrapper = styled.div`
  padding-top: 0.25rem;
`;
const SectionTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
  padding: 0 1rem;
  margin-top: 0.5rem;
`;
const StyledDivider = styled(Divider)`
  && {
    height: 4px;
    background-color: ${colors.greyLight};
    border-bottom-width: 0;
  }
`;

const PersonalTiles = props => {
  const {
    isMobile,
    onClickPersonalHelper,
    onClickDispatch,
    onClickFoodShopping
  } = props;

  const homeTiles = [
    {
      onClick: () => onClickPersonalHelper('assemble_furniture'),
      image: AssembleFurniture,
      title: 'Assemble Furniture',
      jobType: 'assemble_furniture'
    },
    {
      onClick: () => onClickPersonalHelper('laundry_helper'),
      image: LaundryHelper,
      title: 'Laundry Helper',
      jobType: 'laundry_helper'
    },
    {
      onClick: () => onClickPersonalHelper('pet_care'),
      image: PetCare,
      title: 'Pet Care',
      jobType: 'pet_care'
    },
    {
      onClick: () => onClickPersonalHelper('pick_and_move'),
      image: PickAndMove,
      title: 'Pick & Move',
      jobType: 'pick_and_move'
    },
    {
      onClick: () => onClickPersonalHelper('household_chores'),
      image: HouseholdChores,
      title: 'Household Chores',
      jobType: 'household_chores'
    }
  ];

  const lifeTiles = [
    {
      onClick: onClickDispatch,
      image: Dispatch,
      title: 'Dispatch',
      jobType: 'dispatch'
    },
    {
      onClick: onClickFoodShopping,
      image: Food,
      title: 'Food / Shopping',
      jobType: 'food_shopping'
    },
    {
      onClick: () => onClickPersonalHelper('personal_assistant'),
      image: PersonalAssistant,
      title: 'Personal Assistant',
      jobType: 'personal_assistant'
    },
    {
      onClick: () => onClickPersonalHelper('plan'),
      image: Plan,
      title: 'Plan',
      jobType: 'plan'
    }
  ];

  return (
    <>
      <SectionWrapper>
        <SectionTitle>Home</SectionTitle>
        <ScrollableTileContainer isMobile={isMobile}>
          {homeTiles.map(tile => (
            <Tile
              onClick={tile.onClick}
              mobileLayout={isMobile}
              image={tile.image}
              title={tile.title}
              jobType={tile.jobType}
            />
          ))}
        </ScrollableTileContainer>
      </SectionWrapper>
      <StyledDivider />
      <SectionWrapper>
        <SectionTitle>Life</SectionTitle>
        <ScrollableTileContainer isMobile={isMobile}>
          {lifeTiles.map(tile => (
            <Tile
              onClick={tile.onClick}
              mobileLayout={isMobile}
              image={tile.image}
              title={tile.title}
              jobType={tile.jobType}
            />
          ))}
        </ScrollableTileContainer>
      </SectionWrapper>
    </>
  );
};

export default React.memo(PersonalTiles);
