import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Div100vh from 'react-div-100vh';

import CtaButton from '../ui/CtaButton';
import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import GGPopover from '../ui/GGPopover';
import NatureOfUsageToggle from '../ui/NatureOfUsageToggle';
import StrongPasswordField from './StrongPasswordField';

import { isEmail, isMobilePhone } from 'validator';
import { register } from '../../actions/auth';
import { industries } from '../kyc/constants/kycServices';
import { getCookie } from '../../libraries/authentication';
import { isValidStrongPassword } from '../../services/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

const Container = styled(Div100vh)`
  width: ${props => (props.desktopLayout ? 'calc(100% - 50rem)' : '')};
  min-width: ${props => (props.desktopLayout ? '500px' : '')};
  margin: ${props => (props.desktopLayout ? '0 auto' : '')};
  padding-top: ${props => (props.desktopLayout ? '64px' : '')};
  padding-bottom: 24px;
  justify-content: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Card = styled.div`
  width: calc(100% - 32px);
  max-width: 600px;
  padding-bottom: 24px;
  padding-left: ${props => (props.desktopLayout ? '24px' : '')};
  padding-right: ${props => (props.desktopLayout ? '24px' : '')};
  border: ${props => (props.desktopLayout ? `1px solid ${colors.grey}` : '')};
  border-radius: ${props => (props.desktopLayout ? '8px' : '')};
`;
const TextFieldWrapper = styled.div`
  margin-top: 24px;
`;
const PhoneTextField = styled(TextField)`
  margin-top: 0 !important;
`;
const TaxDescription = styled.div`
  font-size: ${fontSize.large};
  text-align: center;
  line-height: 20px;
  margin-bottom: 2rem;
  margin-top: 3rem;
`;
const TermsWrapper = styled.a`
  color: ${colors.primary};
`;
const StyledTextField = styled(TextField)`
  > div {
    padding-right: unset;
  }
`;
const BtnWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 24px;
`;
const EmailTextFieldWrapper = styled.div``;

const FormTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
`;

const RETURN_KEY = 13;

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('60');
  const [referralCode, setReferralCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [natureOfUsage, setNatureOfUsage] = useState('personal');
  const [isPopoverOpen, setIsPopoverOpen] = useState(true);

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const phoneNumberRef = useRef();
  const companyNameRef = useRef();
  const referralCodeRef = useRef();

  const desktopLayout = useSelector(state => state.layout?.desktopLayout);
  const loading = useSelector(state => state.auth?.loading);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickSignUp = () => {
    dispatch(
      register({
        email,
        name,
        password,
        phone_num: phoneNumber,
        referral_code: referralCode,
        company_name: companyName,
        industry,
        nature_of_usage: 'personal'
      })
    );
  };

  const handleEnterPressed = e => {
    const code = e.keyCode || e.which;
    if (code === RETURN_KEY) {
      if (e.target.name === 'name') {
        emailRef.current.focus();
      }
      if (e.target.name === 'email') {
        phoneNumberRef.current.focus();
      }
      if (e.target.name === 'phone_num') {
        passwordRef.current.focus();
      }
      if (e.target.name === 'password') {
        confirmPasswordRef.current.focus();
      }
      if (
        e.target.name === 'confirm_password' &&
        natureOfUsage === 'business'
      ) {
        companyNameRef.current.focus();
      } else {
        referralCodeRef.current.focus();
      }
    }
  };

  useEffect(() => {
    window.analyticsPage('sign_up', { platform: 'pwa' });

    const currentReferralCode = getCookie('posterReferralCode');
    if (currentReferralCode) {
      setReferralCode(currentReferralCode);
      setIsPopoverOpen(false);
    }
  }, []);

  const emailValid = !email || isEmail(email);
  const businessDetailsValid = companyName && industry;
  const matchingPasswords = password === confirmPassword;
  const isStrongPassword = isValidStrongPassword(password);
  const phoneValid =
    phoneNumber === '60' || isMobilePhone(phoneNumber, ['ms-MY']);

  const isSignUpDisabled =
    !name ||
    !emailValid ||
    !password ||
    !isMobilePhone(phoneNumber, ['ms-MY']) ||
    (natureOfUsage === 'business' && !businessDetailsValid) ||
    !matchingPasswords ||
    !isStrongPassword;

  return (
    <>
      <FixedHeaderAppBar
        isMobile={!desktopLayout}
        title="Sign up"
        onBackClick={() => history.push('/home')}
      />
      <Container
        desktopLayout={desktopLayout}
        style={{ height: 'calc(100rvh - 64px)' }}
      >
        <Card desktopLayout={desktopLayout}>
          <div>
            <TextFieldWrapper>
              <FormTitle>Select your nature of usage:</FormTitle>
            </TextFieldWrapper>
            <TextFieldWrapper>
              <NatureOfUsageToggle
                desktopLayout={desktopLayout}
                natureOfUsage={natureOfUsage}
                handleButton={value => setNatureOfUsage(value)}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <FormTitle>Please fill in the following:</FormTitle>
              <TextField
                name="name"
                label="Name"
                value={name}
                margin="normal"
                variant="outlined"
                inputRef={nameRef}
                onChange={e => setName(e.target.value)}
                onKeyPress={e => handleEnterPressed(e)}
                fullWidth
              />
            </TextFieldWrapper>
            <EmailTextFieldWrapper>
              <TextField
                name="email"
                inputRef={emailRef}
                onKeyPress={e => handleEnterPressed(e)}
                onChange={e => setEmail(e.target.value)}
                label="Email"
                value={email}
                margin="normal"
                variant="outlined"
                fullWidth
                helperText={
                  !emailValid && 'Please enter a valid email address.'
                }
                error={!emailValid}
              />
            </EmailTextFieldWrapper>
            <TextFieldWrapper>
              <PhoneTextField
                name="phone_num"
                type="tel"
                inputRef={phoneNumberRef}
                onKeyPress={e => handleEnterPressed(e)}
                onChange={e => setPhoneNumber(e.target.value)}
                label="Phone No."
                value={phoneNumber}
                margin="normal"
                variant="outlined"
                fullWidth
                helperText={!phoneValid && 'Please enter a valid phone number.'}
                error={!phoneValid}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StrongPasswordField
                name="password"
                ref={passwordRef}
                password={password}
                onChange={e => setPassword(e.target.value)}
                onKeyPress={e => handleEnterPressed(e)}
                fullWidth
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                variant="outlined"
                inputRef={confirmPasswordRef}
                onKeyPress={e => handleEnterPressed(e)}
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                value={confirmPassword}
                name="confirm_password"
                helperText={
                  password && confirmPassword && !matchingPasswords
                    ? 'Passwords do not match'
                    : ''
                }
                error={password && confirmPassword && !matchingPasswords}
                onChange={e => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              {natureOfUsage === 'business' && (
                <>
                  <FormTitle>Business details:</FormTitle>
                  <TextField
                    name="company_name"
                    onChange={e => setCompanyName(e.target.value)}
                    label="Company Name"
                    value={companyName}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onKeyPress={e => handleEnterPressed(e)}
                    inputRef={companyNameRef}
                  />
                  <TextField
                    select
                    name="industry"
                    label="Industry"
                    margin="normal"
                    onChange={e => setIndustry(e.target.value)}
                    value={industry}
                    variant="outlined"
                    fullWidth
                  >
                    {industries.map(industry => {
                      return (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </>
              )}
              <TextField
                name="referral_code"
                inputRef={referralCodeRef}
                onKeyPress={e => handleEnterPressed(e)}
                onChange={e => setReferralCode(e.target.value)}
                onFocus={() => setIsPopoverOpen(false)}
                label="Referral Code"
                value={referralCode}
                margin="normal"
                variant="outlined"
                fullWidth
              />
              <GGPopover
                open={isPopoverOpen}
                render={() => {
                  return (
                    <Box onClick={() => setIsPopoverOpen(false)}>
                      <Typography>
                        Got a referral code? Enter it here to get rewarded!
                      </Typography>
                      <Box display="flex" justifyContent="flex-end">
                        <Button style={{ color: 'white' }}>GOT IT</Button>
                      </Box>
                    </Box>
                  );
                }}
              />
            </TextFieldWrapper>
            <TaxDescription>
              By creating an account, you accept our{' '}
              <TermsWrapper
                href={`${import.meta.env.VITE_STATIC_PATH}/terms_conditions/`}
                target="_blank"
              >
                Terms of Service
              </TermsWrapper>
            </TaxDescription>
          </div>
          <BtnWrapper>
            <CtaButton disabled={isSignUpDisabled} onClick={handleClickSignUp}>
              {loading ? (
                <CircularProgress style={{ color: '#fff' }} />
              ) : (
                'SIGN UP'
              )}
            </CtaButton>
          </BtnWrapper>
        </Card>
      </Container>
    </>
  );
};

export default SignUp;
